import GenericCard from "./generic-card";
import CardContent from "./generic-card/content";

import { abbreviateMoney } from "services/format";
import { getCategoryById } from "config/services";
import { HelpCardContent } from "./generic-card/help-content";

const publicURL = process.env.PUBLIC_URL;

const EventCard = ({ left, right, gamestate, height }) => {
  const {
    eventType,
    actionHints,
    infrastructure: serviceType,
    impact: { price: cost, pricePerCitizen: maintenance },
    cardText,
  } = gamestate.tweet;

  const serviceDetails = getCategoryById(serviceType);

  const helpContent = (
    <HelpCardContent
      title="Event"
      color={serviceDetails.color}
      accent={serviceDetails.accent}
    >
      <p>Something is happening in the city!</p>
      <p>Let it happen? Act? It's your choice to make.</p>
    </HelpCardContent>
  );

  return (
    <GenericCard
      left={left}
      right={right}
      gamestate={gamestate}
      actionHints={actionHints}
      height={height}
      helpContent={helpContent}
    >
      <CardContent
        title={cardText}
        color={serviceDetails.color}
        accent={serviceDetails.accent}
        illustrationPath={`${publicURL}/events/${eventType}.png`}
      >
        <span className="font-thin">Cost:</span>{" "}
        <span className="font-normal">{abbreviateMoney(cost)}</span>
        <br />
        <span className="font-thin">Maintenance:</span>{" "}
        <span className="font-normal normal-case">
          {abbreviateMoney(maintenance)} per citizen
        </span>
        <span className="block font-normal capitalize w-full text-center m-0 p-0">
          {serviceDetails.label}
        </span>
      </CardContent>
    </GenericCard>
  );
};

export default EventCard;
