import packageJSON from "../../package.json";

let globalParams = {};
/**
 * @param {"app"|"web"|"pwa"} context kind of context for the game execution
 * @returns {AmplitudeClient}
 */
export const initAmplitude = async (context) => {
  const version = packageJSON.version;
  globalParams = {
    context,
    version,
  };

  const amplitude = await import("amplitude-js");
  const instance = amplitude.getInstance();

  instance.init("d837a25d668f6853aeb178c00c73ef75", null, {
    includeUtm: true,
    includeReferrer: true,
    includeGclid: true,
  });

  instance.setUserProperties({
    context,
    version,
  });

  instance.setVersionName(version);

  return instance;
};

export const logEvent = async (action, data) => {
  const amplitude = await import("amplitude-js");
  return amplitude.getInstance().logEvent(action, {
    ...data,
    ...globalParams,
  });
};
