import classnames from "classnames";
import CardContent from "components/cards/generic-card/content";
import { abbreviateMoney, formatInteger } from "services/format";
import Link from "components/generic/link";
import { getCategoryById, getServiceById } from "config/services";

const publicURL = process.env.PUBLIC_URL;

export const ExpandCard = ({ level = 1, className }) => {
  return (
    <Link to="/title" className="no-underline">
      <div className={classnames("w-36 h-56 mt-4 mx-auto ", className)}>
        <CardContent
          title={`Expand the city?`}
          illustrationPath={`${publicURL}/other-cards/Expand_0${level}.png`}
          accent="#CDE1BC"
          color="#ECF4E5"
        >
          New plots: {formatInteger(Math.pow(5, level))}
          <br />
          Price: {abbreviateMoney(100 * Math.pow(10, level))}
        </CardContent>
      </div>
    </Link>
  );
};

export const InfraCard = ({ serviceId = "LadderTruck", className }) => {
  const service = getServiceById(serviceId);
  const category = getCategoryById(service.category);

  return (
    <Link to="/title" className="no-underline">
      <div className={classnames("w-36 h-56 mt-4 mx-auto", className)}>
        <CardContent
          title={`Upgrade ${category.label}?`}
          illustrationPath={service.illustrationPath}
          color={category.color}
          accent={category.accent}
        >
          Service: {service.label}
          <br />
          Cost: {abbreviateMoney(10000)}
        </CardContent>
      </div>
    </Link>
  );
};
