import { useState } from "react";
import Arrow from "components/generic/tinyIcons/arrow";
import classnames from "classnames";
import { animated, useTransition } from "react-spring";

const FoldableLine = ({
  label,
  level,
  backgroundColor,
  foldColor = "#fff",
  children,
}) => {
  const [folded, setFolded] = useState(true);
  const transitions = useTransition(!folded, null, {
    from: {
      height: "0rem",
      background: foldColor,
      opacity: 0,
    },
    enter: [
      {
        height: "10rem",
        opacity: 1,
      },
    ],
    leave: {
      height: "0rem",
      opacity: 0,
    },
  });

  return (
    <>
      <div
        className="flex flex-row pt-1 text-sm"
        onClick={() => {
          setFolded(!folded);
        }}
      >
        <div
          className="flex flex-row items-center flex-grow px-2 py-1 text-sm font-normal capitalize"
          style={{ backgroundColor }}
        >
          <Arrow
            className={classnames(
              "w-3 transition-transform transform duration-200 ease-in-out mr-2",
              {
                "rotate-90": !folded,
              }
            )}
          />
          {label}
        </div>
        <div
          className={classnames("block w-5 h-full text-center py-1")}
          style={{ backgroundColor, marginLeft: "2px" }}
        >
          {level}
        </div>
      </div>

      {transitions.map(
        ({ item: displayFold, key, props: foldProps }) =>
          displayFold && (
            <animated.div
              className={classnames("w-full overflow-auto")}
              key={key}
              style={{
                height: foldProps.height,
                background: foldProps.background,
              }}
            >
              <animated.div
                className="flex flex-row items-center h-full"
                style={{
                  opacity: foldProps.opacity,
                }}
              >
                {typeof children === "function" && children(displayFold)}
                {typeof children !== "function" && children}
              </animated.div>
            </animated.div>
          )
      )}
    </>
  );
};

export { FoldableLine };
