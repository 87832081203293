import { useRef } from "react";

const SAVEGAME_KEY = "citerate_autosave";

let lastGame = null;

const hasWindow = typeof window !== "undefined";

export const useAutosave = () => {
  const lastSaveTick = useRef(undefined);

  const saveGame = (state, uiState) => {
    if (
      hasWindow &&
      window.localStorage &&
      lastSaveTick.current !== state.tick
    ) {
      window.localStorage.setItem(
        SAVEGAME_KEY,
        JSON.stringify([state, uiState])
      );
      lastSaveTick.current = state.tick;
      lastGame = { state, uiState };
    }
  };

  if (!lastGame) {
    if (hasWindow && window.localStorage) {
      const data = window.localStorage.getItem(SAVEGAME_KEY);
      if (data && data.length > 0) {
        const [state, uiState] = JSON.parse(data);
        lastGame = { state, uiState };
      }
    }
  }

  return { lastGame, saveGame, discardSavedGame };
};

export const discardSavedGame = () => {
  if (hasWindow && window.localStorage) {
    window.localStorage.removeItem(SAVEGAME_KEY);
    lastGame = null;
  }
};
