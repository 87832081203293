import { getAllCategories } from "config/services";
import stateConfig from "config/state";
import { getTweet } from "services/tweetGenerator";

const initialServices = getAllCategories()
  .map((c) => c.id)
  .reduce((acc, id) => {
    acc[id] = 0;
    return acc;
  }, {});

export const initializer = ({ config, initialState }) => {
  if (initialState) return initialState;

  const stateButTweet = {
    cityName: undefined,
    startTime: Date.now(),
    tick: 0, // Count of actions
    turn: 0, // quarter or something else if we decide that's it's not the right time frame

    // CONFIGURABLE VALUES: see config/state.js
    population: 20, // formerly citizens
    bankBalance: 0,
    happiness: 0, // set in config
    plotPrice: 1, // Current price of each plot
    plotNumber: 10,
    stocks: 10000,
    // END OF CONFIGURABLE VALUES
    // plotOwned is population / 2

    contractLevel: 0,

    unitStockPrice: 0.3,

    // Revenu
    plotSales: 0,
    taxIncome: 0,
    soldStock: 0,

    // cost
    maintenance: 0,
    investment: 0,

    // space
    serviceSpace: 0,

    vesting: 0,
    netWorth: 0,

    decisionsStreaks: {
      left: 0,
      right: 0,
    },

    // Legacy service organization
    services: {
      ...initialServices,
    },

    // For service tree implementation
    availableServices: [],

    gameOverStatus: {
      money: false,
      time: false,
      happiness: false,
      noPlot: false,
    },
    isGameOver: false,
    isWin: false,

    events: [],

    ...stateConfig.initialValues, // Configurable state
  };

  const tweet = getTweet(stateButTweet, config);
  return { ...stateButTweet, tweet, tweets: [tweet] };
};
