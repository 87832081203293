import classnames from "classnames";

const Button = ({
  className,
  children,
  primary = false,
  danger = false,
  ...otherProps
}) => (
  <button
    className={classnames(
      "rounded focus:outline-none focus:shadow-outline mx-auto text-center px-4 py-2 w-full",
      {
        "bg-orange-200 text-gray-900": !primary && !danger,
        "bg-orange-300 text-gray-900": primary && !danger,
        "bg-red-600 text-white": danger,
      },
      className
    )}
    {...otherProps}
  >
    {children}
  </button>
);

export default Button;
