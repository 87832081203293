import { animated, useSpring } from "react-spring";

export const Label = (props) => (
  <div
    className="text-xs font-bold tracking-wide leading-3"
    style={{
      width: "100%",
      textAlign: "center",
    }}
    {...props}
  />
);

const LabelWithAnimation = animated(Label);
export const AnimatedLabel = ({ value, format }) => {
  const props = useSpring({ value });
  return (
    <LabelWithAnimation>{props.value.interpolate(format)}</LabelWithAnimation>
  );
};
