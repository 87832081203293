const { useState, useLayoutEffect } = require("react");

const useBoundingRect = (containerRef) => {
  const [height, setHeight] = useState(undefined);
  const currentRef = containerRef.current;

  useLayoutEffect(() => {
    if (!currentRef) return;

    const rect = currentRef.getBoundingClientRect();
    setHeight(rect.height);
  }, [currentRef]);

  return height;
};

export default useBoundingRect;
