import { PrimaryButton } from "components/generic/buttons";

import GenericCard from "./generic-card";
import CardContent from "./generic-card/content";

const Card = ({ left, right, gamestate, height }) => {
  const tweet = gamestate.tweet;
  const actionHints = {
    right: "Start over!",
    left: "Start again!",
  };

  return (
    <>
      <div className="absolute -mt-32 top-0 left-0 h-20 w-full z-50 flex items-center justify-center px-8">
        <PrimaryButton onClick={left}>Play again</PrimaryButton>
      </div>
      <GenericCard
        left={left}
        right={right}
        gamestate={gamestate}
        actionHints={actionHints}
        height={height}
      >
        <div className="w-full h-full bg-citerate-sand-yellow">
          <CardContent
            title="You're fired"
            illustrationPath={tweet.illustrationPath}
            color="#F8F5EE"
            accent="#F8F5EE"
          ></CardContent>
        </div>
      </GenericCard>
    </>
  );
};

export default Card;
