import { getCityServiceLevel } from "services/state";
import events from "./data/event-definitions.json";

const minLevelForEvents = Math.min(...events.map((e) => e.level));

export const isEventAvailable = (gamestate) => {
  const currentCityLevel = getCityServiceLevel(gamestate);
  return currentCityLevel >= minLevelForEvents;
};

export const getAvailableEvents = (gamestate) => {
  const currentCityLevel = getCityServiceLevel(gamestate);
  return events.filter((e) => currentCityLevel >= e.level);
};

// we only keep the type, turn and duration in state
export const getEventDefinition = (eventFromState) => {
  const def = events.find((e) => e.eventType === eventFromState.eventType);
  if (!def) console.log("error: could not find definition", { eventFromState });
  return def;
};
