import { PrimaryButton } from "components/generic/buttons";
import { useSpring, animated } from "react-spring";

const AnimatedPrimaryButton = animated(PrimaryButton);

export const DesktopButtons = ({ actionHints, left, right, currentAction }) => {
  const leftStyleProps = useSpring({
    from: {
      right: "103%",
      width: "120px",
      transform: `translate3D(100%, 0, 0)`,
      opacity: 0,
    },
    to: {
      transform: `translate3D(${currentAction === undefined ? 0 : 100}%, 0, 0)`,
      opacity: currentAction === undefined ? 1 : 0,
    },
    delay: 300,
  });

  const rightStyleProps = useSpring({
    from: {
      left: "103%",
      width: "120px",
      transform: `translate3D(-100%, 0, 0)`,
      opacity: 0,
    },
    to: {
      transform: `translate3D(${
        currentAction === undefined ? 0 : -100
      }%, 0, 0)`,
      opacity: currentAction === undefined ? 1 : 0,
    },
    delay: 300,
  });

  return (
    <div className="absolute top-0 left-0 hidden w-full h-full mt-16 md:block">
      <AnimatedPrimaryButton
        className="absolute shadow-lg"
        style={leftStyleProps}
        onClick={(e) => {
          e.stopPropagation();
          left();
        }}
      >
        {actionHints["left"]}
      </AnimatedPrimaryButton>
      <AnimatedPrimaryButton
        className="absolute shadow-lg"
        style={rightStyleProps}
        onClick={(e) => {
          e.stopPropagation();
          right();
        }}
      >
        {actionHints["right"]}
      </AnimatedPrimaryButton>
    </div>
  );
};
