import { Link } from "@reach/router";

const Button = (props) => <button {...props} />;
const AnchorLinkButton = ({ children, ...otherProps }) => (
  <a rel="noopener noreferrer" target="_blank" {...otherProps}>
    {children}
  </a>
);
const NavLinkButton = (props) => <Link {...props} />;

const BaseButton = (props) => {
  const { to, href } = props;
  if (to) return <NavLinkButton {...props} />;
  else if (href) return <AnchorLinkButton {...props} />;
  else return <Button {...props} />;
};

export default BaseButton;
