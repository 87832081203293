import GenericCard from "./generic-card";
import CardContent from "./generic-card/content";

import { abbreviateMoney } from "services/format";
import { getCategoryById } from "config/services";
import { HelpCardContent } from "./generic-card/help-content";

const Card = ({ left, right, gamestate, height }) => {
  const tweet = gamestate.tweet;
  const { service, category: serviceType } = tweet;
  const serviceDetails = getCategoryById(serviceType);

  const helpContent = (
    <HelpCardContent
      title="Investment"
      color={serviceDetails.color}
      accent={serviceDetails.accent}
    >
      <p>This card allows you to improve the city.</p>
      <p>Is it something that you your citizens want?</p>
      <p>
        Do you have the money to buy this new infrastructure? Will you be able
        to pay for the maintenance?
      </p>
    </HelpCardContent>
  );

  const actionHints = {
    left: "Do nothing",
    right: "Buy",
  };

  return (
    <GenericCard
      left={left}
      right={right}
      gamestate={gamestate}
      actionHints={actionHints}
      height={height}
      helpContent={helpContent}
    >
      <CardContent
        title={service.label}
        illustrationPath={service.illustrationPath}
        color={serviceDetails.color}
        accent={serviceDetails.accent}
      >
        <span className="font-thin">Cost:</span>{" "}
        <span className="font-normal">{abbreviateMoney(service.price)}</span>
        <br />
        <span className="font-thin">Maintenance:</span>{" "}
        <span className="font-normal normal-case">
          {abbreviateMoney(service.upkeep)} per citizen
        </span>
        <span className="block font-normal capitalize w-full text-center m-0 p-0">
          {serviceDetails.label}
        </span>
      </CardContent>
    </GenericCard>
  );
};

export default Card;
