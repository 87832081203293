import { makeAnswerTweet } from "./factory";
import { getGameOverTweet, isGameOverTweetNext } from "./gameover";
import { getInfraAnswer, getInfraTweet } from "./infra";
import { getIntroTweet, getIntroTweetAnswer } from "./intro";
import { getPlotAnswer, getPlotSaleTweet, isPlotTweetNext } from "./plot";
import { getNextEventTweet, isEventTweetNext } from "./events";
import { getHint } from "./hints";

const getNextTweet = (gamestate, config) => {
  if (isGameOverTweetNext(gamestate)) {
    return getGameOverTweet(gamestate);
  }

  const intro = getIntroTweet(gamestate);
  if (intro) return intro;

  if (isPlotTweetNext(gamestate)) {
    return getPlotSaleTweet(gamestate);
  }

  if (config.useEvents && isEventTweetNext(gamestate)) {
    return getNextEventTweet(gamestate);
  }

  if (gamestate.population > 0) return getInfraTweet(gamestate);
};

let lastTick = -1;
let memorizedTweet = undefined;
export const getTweet = (gamestate, config) => {
  if (gamestate.tick === lastTick) {
    return memorizedTweet;
  }

  const nextTweet = getNextTweet(gamestate, config);
  memorizedTweet = nextTweet;
  lastTick = gamestate.tick;
  return nextTweet;
};

const answers = {
  infra: {
    left: "Thanks, but no thanks #notapriority",
    right: "Coming soon! #yougotit #thanksforthesuggestion",
  },
  stocks: {
    left: "Not sure it's the right time. Let's keep in touch.",
    right: "Let's chat! #termsheet",
  },
};

/**
 * @param {object} originalTweet a tweet object
 * @param {string} action left|right
 * @param {object} gamestate the current gamestate
 * @returns {array<object>|undefined} the dialogue (could be just one tweet)
 */
export const getAnswer = (originalTweet, action, gamestate) => {
  const tweetType = originalTweet.type;

  if (tweetType === "gameover") return;
  if (tweetType === "plot") return [getPlotAnswer(action, originalTweet)];
  if (tweetType === "intro")
    return getIntroTweetAnswer(action, originalTweet, gamestate);
  if (tweetType === "infra")
    return getInfraAnswer(action, originalTweet, gamestate);

  const message =
    answers[originalTweet.type] && answers[originalTweet.type][action];

  if (!message) return;

  return [makeAnswerTweet(message, originalTweet)];
};

export const getGeneralHint = (gamestate, config) => getHint(gamestate, config);
