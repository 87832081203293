import { useLayoutEffect, useState } from "react";
import Layout from "components/layout";

import titleScreenLogo from "assets/art/CiterateHeader.jpg";

import PrimaryButton from "components/generic/buttons/primary";
import SecondaryButton from "components/generic/buttons/secondary";
import Logo from "components/logo";

import { useSpring, useTrail, animated, config } from "react-spring";
import { UpdatePopup } from "components/popups/update";
import { useAutosave } from "hooks/autosave";

const AnimatedPrimaryButton = animated(PrimaryButton);
const AnimatedSecondaryButton = animated(SecondaryButton);

const TitleScreen = ({ workerState }) => {
  const { lastGame } = useAutosave();
  const [isLoading, setLoading] = useState(true);

  useLayoutEffect(() => {
    const image = new Image();
    image.onload = () => {
      setTimeout(() => setLoading(false), 100);
    };
    image.src = titleScreenLogo;
  });

  const foldStyle = useSpring({
    transform: `translateY(${isLoading ? "0%" : "100%"})`,
    // opacity: isLoading ? 1 : 0,
    config: config.default,
  });

  const fadeInStyle = useTrail(5, {
    transform: `translateY(${isLoading ? "100%" : "0"})`,
    opacity: isLoading ? 0 : 1,
    config: config.default,
  });

  return (
    <Layout className="bg-citerate-deep-green">
      <div className="flex flex-col h-full justify-evenly">
        <div className="relative overflow-hidden">
          <animated.div
            className="absolute left-0 w-full h-full bg-citerate-deep-green"
            style={foldStyle}
          />
          <img
            src={titleScreenLogo}
            alt="A new city is being sketched out"
            className="w-full h-full"
          />
        </div>
        <div className="flex items-center flex-grow">
          <div
            className="flex flex-col justify-between flex-grow h-full px-16 mx-4"
            style={{ maxHeight: "300px" }}
          >
            <animated.div style={fadeInStyle[0]}>
              <Logo />
            </animated.div>
            <AnimatedPrimaryButton
              to="../game"
              isTitleScreen
              className="mt-4"
              style={fadeInStyle[1]}
            >
              {lastGame ? "Continue" : "New game"}
            </AnimatedPrimaryButton>
            <div className="flex flex-col mb-4">
              <AnimatedSecondaryButton
                isTitleScreen
                to="../score"
                style={fadeInStyle[2]}
              >
                High scores
              </AnimatedSecondaryButton>
              <AnimatedSecondaryButton
                isTitleScreen
                to="../about"
                style={fadeInStyle[3]}
              >
                About this game
              </AnimatedSecondaryButton>
              <AnimatedSecondaryButton
                isTitleScreen
                to="../credits"
                style={fadeInStyle[4]}
              >
                Credits
              </AnimatedSecondaryButton>
            </div>
          </div>
        </div>
      </div>
      <UpdatePopup {...workerState} />
    </Layout>
  );
};

export default TitleScreen;
