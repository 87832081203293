import useBoundingRect from "hooks/useBoundingRect";
import { createRef } from "react";
import classnames from "classnames";

const CardContent = ({
  title,
  illustrationPath,
  color = "#FFF8DF",
  accent = "#FBE491",
  children,
  className,
}) => {
  const imageContainerRef = createRef();
  const height = useBoundingRect(imageContainerRef);
  return (
    <div
      className={classnames(
        "flex flex-col items-start w-full h-full",
        className
      )}
      style={{ background: color }}
    >
      <p className="w-full text-center text-base mt-1 px-1">{title}</p>
      <div
        className="flex-grow flex justify-center items-center"
        ref={imageContainerRef}
      >
        {illustrationPath && (
          <img
            src={illustrationPath}
            alt={title}
            className="m-auto w-auto"
            style={{
              height: `${height}px`,
            }}
          />
        )}
      </div>
      <p
        className="w-full p-1 text-xs font-light tracking-tight text-left"
        style={{ background: accent }}
      >
        {children}
      </p>
    </div>
  );
};

export default CardContent;
