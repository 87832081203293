import { getCityServiceLevel } from "services/state";

/**
 * @param history {Array} all gamestates
 */
export const computeStats = (gamestate, history) => {
  const averageHappiness =
    (gamestate.happiness + history.reduce((m, gs) => m + gs.happiness, 0)) /
    (history.length + 1);
  const totalIncome = history.reduce(
    (m, gs) => m + gs.taxIncome + gs.plotSales,
    0
  );
  const duration = Math.floor(gamestate.turn / 4);
  const cityLevel = getCityServiceLevel(gamestate);

  return {
    population: gamestate.population,
    totalIncome,
    duration,
    cityLevel,
    averageHappiness,
  };
};
