const randomPick = (n, list) => {
  let ls = [...list];
  let res = [];
  while (n-- > 0) {
    const e = ls.splice(Math.random() * ls.length, 1);
    res.push(e);
  }
  return res;
};

export const getHashtags = (category) => {
  return randomPick(Math.ceil(Math.random() * 3), category.hashtags)
    .map((h) => `#${h}`)
    .join(" ");
};
