import { useConfig } from "hooks/config";
import { useRef } from "react";
import ReactJoyride, { ACTIONS, EVENTS } from "react-joyride";
import { logEvent } from "services/amplitude";

const styleOptions = {
  arrowColor: "rgba(255,255,255,0.9)",
  backgroundColor: "rgba(0,0,0,0.3)",
  beaconSize: 36,
  overlayColor: "rgba(0, 0, 0, 0.5)",
  spotlightShadow: "0 0 15px rgba(0, 0, 0, 0.2)",
  width: undefined,
  zIndex: 100,
};

const TooltipButton = (props) => (
  <button
    {...props}
    className="px-2 py-1 ml-2 text-white border-2 border-white focus:outline-none focus:shadow-outline"
  />
);

const BareTooltipButton = (props) => (
  <button
    {...props}
    className="py-1 font-light text-white underline focus:outline-none focus:shadow-outline"
  />
);

const Tooltip = ({
  // continuous,
  index,
  step,
  backProps,
  primaryProps,
  tooltipProps,
  skipProps,
  isLastStep,
}) => {
  const stepStyle = step.style;
  return (
    <div
      {...tooltipProps}
      className="left-auto px-5 py-2 border-4 border-white rounded-lg sm:max-w-sm bg-gray-800 bg-opacity-50"
      style={{
        transform: "translate(5px, 0)",
        ...stepStyle,
      }}
    >
      {step.title && (
        <div className="font-semibold tracking-wide text-center text-white uppercase">
          {step.title}
        </div>
      )}
      <div className="mt-2 text-white">{step.content}</div>
      <div className="flex flex-row justify-end mt-2">
        {skipProps && !isLastStep && (
          <div className="flex-grow">
            <BareTooltipButton {...skipProps}>Skip tutorial</BareTooltipButton>
          </div>
        )}
        {index > 0 && <TooltipButton {...backProps}>Back</TooltipButton>}
        <TooltipButton {...primaryProps}>
          {isLastStep ? "Let's build" : "Next"}
        </TooltipButton>
      </div>
    </div>
  );
};

const TourTutorial = ({ steps, displayNewTurn }) => {
  const { config, setConfig } = useConfig();
  const tutorialSkipped = useRef(false);
  const tourCallback = (data) => {
    const { type, action } = data;

    if (type === EVENTS.TOUR_START) {
      logEvent("TUTORIAL:START");
    } else if (type === EVENTS.TOUR_END) {
      if (action === ACTIONS.SKIP) {
        tutorialSkipped.current = true;
        logEvent("TUTORIAL:SKIP");
      }
      logEvent("TUTORIAL:END");
      setConfig({
        ...config,
        showTutorial: false,
      });
    }
  };

  const show =
    config.showTutorial && !displayNewTurn && !tutorialSkipped.current;

  return (
    show && (
      <ReactJoyride
        callback={tourCallback}
        steps={steps}
        continuous
        disableScrolling
        showProgress
        spotlightPadding={0}
        showSkipButton
        disableCloseOnEsc
        disableOverlayClose
        styles={{
          options: styleOptions,
        }}
        tooltipComponent={Tooltip}
      />
    )
  );
};

export { TourTutorial };
