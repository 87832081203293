function HeartEyes(props) {
  return (
    <svg
      width={154}
      height={154}
      viewBox="0 0 154 154"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M77 0C34.477 0 0 34.477 0 77s34.47 77 77 77 77-34.477 77-77S119.523 0 77 0z"
        fill="#EDC86E"
      />
      <path
        d="M77 131.008c-27.898 0-50.511-22.613-50.511-50.51 0-7.667 101.022-6.938 101.022 0 0 27.89-22.613 50.51-50.511 50.51z"
        fill="#462E53"
      />
      <path
        d="M103.053 67.424c.672.486 1.53.63 2.324.4 5.614-1.63 28.206-8.967 30.458-22.04 1.574-9.111-3.289-16.456-9.497-17.521-5.936-1.023-11.342.765-15.855 5.821a.567.567 0 01-.944-.165c-2.56-6.271-7.051-9.768-12.994-10.791-6.207-1.073-13.245 4.22-14.81 13.33-2.26 13.08 16.576 27.555 21.318 30.966zM50.947 67.424c-.672.486-1.53.63-2.324.4-5.614-1.63-28.206-8.967-30.458-22.04-1.574-9.111 3.29-16.456 9.497-17.521 5.936-1.023 11.342.765 15.855 5.821a.567.567 0 00.944-.165c2.56-6.271 7.051-9.768 12.994-10.791 6.208-1.073 13.245 4.22 14.81 13.33 2.26 13.08-16.576 27.555-21.318 30.966z"
        fill="#AA3E38"
      />
      <path
        d="M125.995 35.321c6.801 0 7.617 12.236 2.446 14.146-7.738 2.846-9.926-14.146-2.446-14.146zM58.742 35.321c6.801 0 7.617 12.236 2.446 14.146-7.738 2.846-9.926-14.146-2.446-14.146z"
        fill="#F9B7AF"
      />
    </svg>
  );
}

export default HeartEyes;
