const uiConfig = {
  displayManyLevels: false,
  idleCardBounce: false,
  useEvents: true,
  useLevelTree: true,
  showTutorial: true,
  fewInfra: false,
  version: 12, // should be incremented if there is a new property or if we want to update the config
};

export default uiConfig;
