import Line from "./Line";
import Title from "./Title";

import { formatMoney, formatPercent } from "services/format";

const findLastQuarter = (current, [last, ...rest]) => {
  if (!last) return { current, previous: undefined };
  if (current.turn !== last.turn || current.isGameOver) {
    return { current, previous: last };
  }
  return findLastQuarter(last, rest);
};

const MoneyView = ({ gamestate, history }) => {
  const inversedHistory = [...history].reverse();
  const {
    current: lastQuarterState,
    previous: previousLQState,
  } = findLastQuarter(gamestate, inversedHistory);

  const { taxIncome, plotSales, maintenance, investment } = lastQuarterState;

  return (
    <>
      <Title>Finances</Title>
      <Line label="Bank balance">{formatMoney(gamestate.bankBalance)}</Line>
      <Line label="Your share of the company">
        {formatPercent(gamestate.stocks / 10000)}
      </Line>

      <h2 className="pt-2 mt-2 text-xl">Revenue</h2>
      <Line label="Land rent">{formatMoney(taxIncome)}</Line>
      <Line label="Sold plot">{formatMoney(plotSales)}</Line>

      <h2 className="pt-2 text-xl">Cost</h2>
      <Line label="Last investment">{formatMoney(investment)}</Line>
      <Line label="Services upkeep">{formatMoney(maintenance)}</Line>
      <hr className="my-2" />
      {Boolean(previousLQState) && (
        <Line label="Previous balance">
          {formatMoney(previousLQState.bankBalance)}
        </Line>
      )}
      <Line label="Profit">
        {formatMoney(taxIncome + plotSales - maintenance - investment)}
      </Line>
    </>
  );
};

export default MoneyView;
