import illustrations from "config/data/infra-card-illustrations.json";
import categories from "config/data/infra-categories.json";

import levelsProd from "config/data/level-infra-card-definitions.json";
import levelsDebug from "config/data/level-infra-card-definitions-debug.json";
import { getCurrentConfig } from "hooks/config";

const levels = getCurrentConfig().fewInfra ? levelsDebug : levelsProd;

const publicURL = process.env.PUBLIC_URL;

const levelsWithLevels = levels.map((level, i) =>
  level.map((s) => ({ ...s, level: i }))
);
export const allServices = levelsWithLevels.flat();

export const servicesPerLevel = levels.map((ls) => ls.length);

const getCategoryById = (categoryId) =>
  categories.find((c) => c.id === categoryId);

export const withIllustration = (service) => {
  const illustration = illustrations.find((i) => i.building === service.id);

  return {
    ...service,
    illustrationPath: `${publicURL}/cards/${illustration.file}`,
  };
};

const getServiceById = (serviceId) => {
  const service = allServices.find((s) => s.id === serviceId);
  return withIllustration(service);
};

const sortedCategories = [...categories].sort((a, b) =>
  a.label.localeCompare(b.label)
);
const getAllCategories = () => {
  return sortedCategories;
};

const pickNextService = (gamestate) => {
  // current max level of services
  // if current max level full?
  //   return one from next level
  // else
  //   return one from current level
  const currentServices = gamestate.availableServices;
  const levelValues = currentServices.map((s) => getServiceById(s).level);
  const maxLevel = currentServices.length === 0 ? 0 : Math.max(...levelValues);
  const maxLevelServices = levelsWithLevels[maxLevel];
  const maxLevelNotImplementedServices = maxLevelServices.filter(
    (s) => !currentServices.includes(s.id)
  );
  const nextServices =
    maxLevelNotImplementedServices.length === 0
      ? levelsWithLevels[maxLevel + 1]
      : maxLevelNotImplementedServices;

  const service = nextServices[Math.floor(Math.random() * nextServices.length)];

  return {
    category: getCategoryById(service.category),
    service: withIllustration(service),
  };
};

const allServiceMaxLevel = levels.length;
const getMaxLevel = () => allServiceMaxLevel;

export const areAllServicesImplemented = (serviceIds = []) =>
  serviceIds.length === allServices.length;

export {
  getAllCategories,
  getCategoryById,
  getServiceById,
  pickNextService,
  getMaxLevel,
};
