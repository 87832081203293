import { useEffect, useRef } from "react";

function useMultiTapHandler({ ref, action, tapNumber }) {
  const tapCount = useRef(0);
  const resetTimeout = useRef(undefined);
  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener(
        "click",
        () => {
          const currentTapCount = tapCount.current;
          tapCount.current = currentTapCount + 1;

          clearTimeout(resetTimeout.current);

          if (currentTapCount === tapNumber) {
            tapCount.current = 0;
            action();
          } else {
            resetTimeout.current = setTimeout(() => {
              tapCount.current = 0;
            }, 300);
          }
        },
        { passive: true }
      );
    }
    // eslint-disable-next-line
  }, [ref]);
}

export { useMultiTapHandler };
