import { getAvatar } from "config/avatars";
import classnames from "classnames";

const Avatar = ({ author, className }) => {
  const avatarDef = getAvatar(author);
  return (
    <div
      className={classnames(
        "relative flex items-center justify-center rounded-full m-auto sm:mr-4",
        className
      )}
    >
      <img
        className="rounded-full transform sm:scale-150"
        src={avatarDef.path}
        alt={avatarDef.description}
      />
    </div>
  );
};

export const Comment = ({
  children,
  className,
  author,
  avatarPosition = "right",
}) => {
  return (
    <div className={classnames("flex flex-col sm:flex-row sm:mb-8", className)}>
      {avatarPosition === "left" && (
        <Avatar
          author={author}
          className="flex-initial w-20 mb-4 -mb-12 sm:m-0 sm:w-24 smd:w-1/6"
        />
      )}
      <p
        className={classnames(
          "flex-grow w-full text-center sm:text-left sm:w-5/6 px-4 py-2 sm:py-4 text-sm sm:text-lg my-auto bg-citerate-sand-yellow font-bold",
          {
            "pt-12 sm:-ml-20 sm:pl-24": avatarPosition === "left",
            "pb-12 sm:-mr-16 sm:pr-24": avatarPosition === "right",
          }
        )}
      >
        {children}
      </p>
      {avatarPosition === "right" && (
        <Avatar
          author={author}
          className="flex-initial w-20 mb-4 -mt-12 sm:m-0 sm:w-24 smd:w-1/6"
        />
      )}
    </div>
  );
};
