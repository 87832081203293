import { useEffect, useMemo, useRef, useState } from "react";
import { PrimaryButton, SecondaryButton } from "components/generic/buttons";
import { useSpring, animated, config } from "react-spring";
import { getVersion } from "config/version";
import { discardSavedGame } from "hooks/autosave";

const makeMessage = (error, isAppContext) => {
  return `
---------------------------------------
Thanks for helping! Please describe what happened above.
If you don't have anything to add, just hit "send"!

 - Version: ${getVersion()}
 - Context: ${isAppContext ? "App" : "Web"}
 - Time: ${new Date()}
 - Error:
\`\`\`
${error && error.stack ? error.stack.split("\n").slice(0, 3).join("\n") : error}
\`\`\`
  `;
};

export const ErrorPopup = ({ errorEvent, isAppContext }) => {
  const firstLoad = useRef(true);
  const [showPopup, setShowPopup] = useState(false);
  const popupProps = useSpring({
    from: { position: showPopup ? 0 : 1 },
    to: { position: showPopup ? 1 : 0 },
    immediate: firstLoad.current,
    config: config.stiff,
  });

  const message = useMemo(() => {
    return errorEvent ? makeMessage(errorEvent.error, isAppContext) : "";
  }, [errorEvent, isAppContext]);

  useEffect(() => {
    setTimeout(() => setShowPopup(Boolean(errorEvent)), 100);
  }, [setShowPopup, errorEvent]);

  firstLoad.current = false;

  const reload = (isAppContext) => {
    if (isAppContext) window.location.reload();
    else window.location = "/title";
  };

  const closePopup = () => {
    setShowPopup(false);
    discardSavedGame();
    setTimeout(() => reload(isAppContext), 100);
  };

  return (
    <animated.div className="absolute bottom-0 left-0 block w-full h-full bg-black bg-opacity-25 z-100">
      <animated.div
        className="absolute bottom-0 left-0 flex items-center justify-center w-full h-full"
        style={{
          transform: popupProps.position.interpolate(
            (p) => `translateY(${(1 - p) * 100}%)`
          ),
        }}
      >
        <div className="flex flex-col max-w-xs p-4 mx-auto bg-citerate-sand-yellow">
          <div className="flex flex-row mb-4 ">
            <div className="flex-grow text-xl">
              (⩾﹏⩽) the game just crashed. Sorry about that.
            </div>
          </div>
          <PrimaryButton
            href={encodeURI(
              `mailto:incoming+karen-terrence-19244005-issue-@incoming.gitlab.com?subject=App crash&body=${message}`
            )}
            onClick={closePopup}
          >
            Report the bug
          </PrimaryButton>
          <SecondaryButton onClick={closePopup}>Discard</SecondaryButton>
        </div>
      </animated.div>
    </animated.div>
  );
};
