import { makeAnswerTweet, makeTweet } from "./factory";
import { getHashtags } from "./services";
import { pickNextService } from "config/services";

export const getInfraTweet = (gamestate) => {
  const nextService = pickNextService(gamestate);
  const { category, service } = nextService;
  const { category: messageCategory } =
    Math.random() > 0.5 ? pickNextService(gamestate) : nextService;
  const hashtags = getHashtags(messageCategory);

  const isFirstInfraTweet =
    gamestate.tweets.findIndex((t) => t.type === "infra") === -1;
  const hint = isFirstInfraTweet
    ? {
        author: "@city-concierge",
        message: "Darn we don't have money yet to fulfill this request",
      }
    : null;

  return makeTweet(
    {
      type: "infra",
      author: `@citizen${Math.ceil(Math.random() * gamestate.population)}`,
      message: `We need better ${messageCategory.label} ${hashtags}`,
      messageCategory: messageCategory.id,
      category: category.id,
      service,
    },
    hint
  );
};

const ceoAnswers = {
  left: "Thanks, but no thanks #notapriority",
  right: "Coming soon! #yougotit",
};

const peopleReaction = {
  left: {
    correct: "That's a missed opportunity.",
    incorrect: "At least, we don't get something we don't need.",
  },
  right: {
    correct: "That will be a great improvement for the city!",
    incorrect: "I don't have the impression we're being heard correctly",
  },
};

export const getInfraAnswer = (action, originalTweet, gamestate) => {
  if (originalTweet.type !== "infra") return undefined;

  const isItWhatPeopleWant =
    originalTweet.category === originalTweet.messageCategory;
  const reactionLabel = isItWhatPeopleWant ? "correct" : "incorrect";

  return [
    makeAnswerTweet(ceoAnswers[action], originalTweet),
    makeAnswerTweet(
      peopleReaction[action][reactionLabel],
      originalTweet,
      originalTweet.author
    ),
  ];
};

/*
    left: 
    right: 
    */
