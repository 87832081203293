const ModalContent = ({ children, ...otherProps }) => (
  <div
    className="overflow-auto text-sm flex-grow flex flex-col"
    {...otherProps}
  >
    {children}
  </div>
);

export default ModalContent;
