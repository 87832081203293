import { memo, useLayoutEffect, useRef, useState } from "react";
import TweetCard from "./tweet-card";
import { useSpring, useTransition } from "react-spring";
import { disableBodyScroll } from "body-scroll-lock";
import SwipeTutorial from "./swipe-tutorial";

const Timeline = ({ id, tweets, onRest, isFirstCard = false, population }) => {
  const timelineRef = useRef();
  const [refMap] = useState(() => new WeakMap());
  const transitions = useTransition(tweets.slice(0, 10), (t) => t.id, {
    from: { height: 0, opacity: 0 },
    enter: (tweet) => async (next) => {
      await next({
        height: refMap.has(tweet) ? refMap.get(tweet).offsetHeight : 90,
        opacity: 100,
      });
    },
    leave: { opacity: 0, height: 0 },
    onRest,
    config: { mass: 1, tension: 400, friction: 30 },
  });

  const tutorialOpacity = useSpring({
    delay: tweets.length === 0 ? 800 : 0,
    from: { opacity: 0 },
    to: {
      opacity: isFirstCard ? 0 : 1,
    },
  });

  useLayoutEffect(() => {
    disableBodyScroll(timelineRef.current);
  });

  return (
    <div className="w-full" style={{ height: "40%" }} id={id}>
      <div className="mx-1 mt-2 rounded-lg h-full relative">
        <div
          className="absolute z-10 flex flex-col overflow-y-auto overflow-x-hidden rounded-lg h-full"
          ref={timelineRef}
        >
          {transitions.map(({ item: tweet, props: { ...style }, key }) => (
            <TweetCard
              tweet={tweet}
              key={key}
              style={style}
              ref={(ref) => ref && refMap.set(tweet, ref)}
              population={population}
            />
          ))}
        </div>
        <SwipeTutorial
          className="transform origin-bottom scale-90 absolute left-0"
          style={{
            opacity: tutorialOpacity.opacity,
            zIndex: isFirstCard ? 0 : 30,
            top: "35%",
          }}
        />
      </div>
    </div>
  );
};

export default memo(
  Timeline,
  (previousProps, nextProps) =>
    previousProps.tweets.length === nextProps.tweets.length &&
    previousProps.isFirstCard === nextProps.isFirstCard
);
