import Modal from "components/generic/modal";
import classnames from "classnames";
import { abbreviate } from "services/format";

import { PrimaryButton, SecondaryButton } from "components/generic/buttons";

import ModalHeader from "components/generic/modal-header";

import { computeStats } from "./computeStats";
import { Social } from "./social";
import { Stats } from "./stats";
import { useInstallPrompt } from "hooks/pwaInstall";

const sharedURL = "https://citerate.com";

const P = ({ className, ...props }) => (
  <p {...props} className={classnames("text-base mx-auto", className)} />
);

const Win = ({ show, reset, gamestate, history }) => {
  const { isInstallable, promptInstall } = useInstallPrompt();
  const statistics = computeStats(gamestate, history);
  const message = `This time ${
    gamestate.cityName
  } reached a population of ${abbreviate(gamestate.population, 4)} people. 
Can you beat that?`;

  return (
    <Modal show={show} fullHeight>
      <div className="flex flex-col justify-items-center">
        <ModalHeader className="mx-auto text-2xl">Woohoo!</ModalHeader>
        <P>{gamestate.cityName} results</P>
      </div>

      <Stats {...statistics} />
      <Social sharedURL={sharedURL} message={message} />
      <PrimaryButton onClick={reset} className="mt-4">
        Start a new city
      </PrimaryButton>
      {isInstallable && (
        <SecondaryButton onClick={promptInstall}>
          Add to your phone
        </SecondaryButton>
      )}
    </Modal>
  );
};

export default Win;
