import config from "config/state";
import { getCurrentEvent } from "services/state";
import { getEventDefinition } from "config/events";

import {
  computeMaintenance,
  computeTaxIncome,
  computeSoldPlot,
  computePlotPrice,
  computeHapinessImpactFromExpansion,
  computeGivenStock,
} from "./formulas";

const makeImpact = (values = {}, state) => {
  const { services, decisionsStreaks, contractLevel } = state;

  return {
    contractLevel,
    services,
    decisionsStreaks, // this is a failsafe / documentation but it is not required
    deltaHappiness: 0,
    investment: 0,
    soldStock: 0,
    price: 0,
    turnDelta: 0, // how many turn should we move
    newPlots: 0,
    soldPlot: 0, // revenu from plot selling
    taxIncome: 0,
    maintenance: 0,
    isEndOfQuarter: false,
    plotPrice: computePlotPrice(state),
    newService: null,
    ...values,
  };
};

const translateEventImpact = (impactFromEvent, state) => {
  const impact = {
    maintenance:
      impactFromEvent.price +
      impactFromEvent.pricePerCitizen * state.population,
    deltaHappiness: impactFromEvent.happiness,
  };
  return impact;
};

const endOfQuarterImpact = (state, impact = {}) => {
  // plots are sold based on interest of the visitors
  // tax income
  // maintenance cost
  const soldPlot = computeSoldPlot(state, impact);
  const currentEvent = getCurrentEvent(state);
  const eventImpact =
    !currentEvent || currentEvent.duration === 0
      ? {
          maintenance: 0,
          deltaHappiness: 0,
        }
      : translateEventImpact(
          getEventDefinition(currentEvent).disasterImpact,
          state
        );

  return {
    ...eventImpact,
    soldPlot,
    soldStock: computeGivenStock(state, soldPlot),
    price: 0,
    taxIncome: computeTaxIncome(state),
    maintenance: computeMaintenance(state) + eventImpact.maintenance,
    isEndOfQuarter: true,
    turnDelta: 1,
  };
};

const getPlotSellingLeft = (state) => {
  return state.population === 0
    ? makeImpact(
        {
          newPlots: 0,
          deltaHappiness: 0,
          ...endOfQuarterImpact(state),
        },
        state
      )
    : makeImpact(
        {
          newPlots: 0,
          deltaHappiness: 0,
        },
        state
      );
};
const getPlotSellingRight = (state, tweet) => {
  return state.population === 0
    ? makeImpact(
        {
          newPlots: tweet.impact.newPlots, // Double the total number of plots
          deltaHappiness: computeHapinessImpactFromExpansion(state),
          ...endOfQuarterImpact(state, tweet.impact),
        },
        state
      )
    : makeImpact(
        {
          newPlots: tweet.impact.newPlots, // Double the total number of plots
          deltaHappiness: computeHapinessImpactFromExpansion(state),
        },
        state
      );
};

const getInfraImpactLeft = (state, tweet) => {
  const isItWhatPeopleWant = tweet.category === tweet.messageCategory;

  const decisionsStreaks = isItWhatPeopleWant
    ? {
        left: state.decisionsStreaks.left + 1,
        right: 0,
      }
    : state.decisionsStreaks;

  const eoqImpact = endOfQuarterImpact(state);

  return makeImpact(
    {
      ...eoqImpact,
      deltaHappiness:
        eoqImpact.deltaHappiness -
        (isItWhatPeopleWant ? decisionsStreaks.left : 0),
      decisionsStreaks,
    },
    state
  );
};

const getInfraImpactRight = (state, tweet) => {
  const investmentPrice = tweet.service.price;
  const isItWhatPeopleWant = tweet.category === tweet.messageCategory;

  // If the decision doesn't reflect what people want then it cancels the streak
  const decisionsStreaks = isItWhatPeopleWant
    ? {
        left: 0,
        right: state.decisionsStreaks.right + 1,
      }
    : state.decisionsStreaks;

  const nextServiceLevel = state.services[tweet.category] + 1;

  // Buying something that is too expensive will end the game now.
  // Therefore there is no end of quarter (which could generate cash)
  const eoqImpact =
    investmentPrice > state.bankBalance
      ? {
          deltaHappiness: 0,
        }
      : endOfQuarterImpact(state);

  const happinessBonusFromStreak = Math.min(
    // if it's not what people want, then this is a bad decision
    isItWhatPeopleWant ? decisionsStreaks.right : -decisionsStreaks.left,
    // Streak is less effective as the city grows
    10
  );

  return makeImpact(
    {
      ...eoqImpact,
      services: {
        ...state.services,
        [tweet.category]: nextServiceLevel,
      },
      newService: tweet.service.id,
      investment: investmentPrice,
      deltaHappiness: eoqImpact.deltaHappiness + happinessBonusFromStreak,
      decisionsStreaks,
    },
    state
  );
};

const getStockSellingImpactRight = (state, tweet) => {
  const soldStock = Math.floor(config.initialValues.stocks * tweet.percentage);
  const eoqImpact = endOfQuarterImpact(state);
  return makeImpact(
    {
      ...eoqImpact,
      soldStock: eoqImpact.soldPlot + soldStock,
      price: eoqImpact.price + tweet.unitStockPrice * soldStock,
    },
    state
  );
};

const getStockSellingImpactLeft = (state, tweet) => {
  return makeImpact(endOfQuarterImpact(state), state);
};

const getIntroLeft = (state, tweet) => {
  return makeImpact(
    {
      isLastIntroTweet: tweet.isLastIntroTweet,
      land: false,
    },
    state
  );
};

const getIntroRight = (state, tweet) => {
  return makeImpact(
    {
      isLastIntroTweet: tweet.isLastIntroTweet,
      soldStock: tweet.cost.stocks,
      price: 0, // actually we do not want the player to end here
      land: true,
    },
    state
  );
};

const getContractUpdatImpact = (state, tweet) => {
  return state.population === 0
    ? makeImpact(
        {
          ...tweet.impact,
          ...endOfQuarterImpact(state, tweet.impact),
        },
        state
      )
    : makeImpact(tweet.impact, state);
};

const getEventFixImpact = (state, tweet) => {
  const eoqImpact = endOfQuarterImpact(state);
  const impact = translateEventImpact(tweet.impact, state);

  return makeImpact(
    {
      ...eoqImpact,
      ...impact,
      maintenance: eoqImpact.maintenance + impact.maintenance,
      deltaHappiness: eoqImpact.deltaHappiness + impact.deltaHappiness,
      event: {
        eventType: tweet.eventType,
        turn: state.turn,
        duration: 0,
      },
    },
    state
  );
};

const getEventUnfixedImpact = (state, tweet) => {
  const eoqImpact = endOfQuarterImpact(state);
  const impact = translateEventImpact(tweet.disasterImpact, state);
  return makeImpact(
    {
      ...eoqImpact,
      ...impact,
      maintenance: eoqImpact.maintenance + impact.maintenance,
      deltaHappiness: eoqImpact.deltaHappiness + impact.deltaHappiness,
      event: {
        eventType: tweet.eventType,
        turn: state.turn,
        duration: tweet.duration,
      },
    },
    state
  );
};

const impactFunctionMapping = {
  stocks: {
    left: getStockSellingImpactLeft,
    right: getStockSellingImpactRight,
  },
  infra: {
    left: getInfraImpactLeft,
    right: getInfraImpactRight,
  },
  plot: {
    left: getPlotSellingLeft,
    right: getPlotSellingRight,
  },
  intro: {
    left: getIntroLeft,
    right: getIntroRight,
  },
  contract: {
    // You cannot refuse to update
    left: getContractUpdatImpact,
    right: getContractUpdatImpact,
  },
  event: {
    left: getEventUnfixedImpact,
    right: getEventFixImpact,
  },
};

const getImpact = ({ move, tweet, state }) => {
  if (impactFunctionMapping[tweet.type]) {
    if (impactFunctionMapping[tweet.type][move]) {
      return impactFunctionMapping[tweet.type][move](state, tweet);
    }
  }

  return makeImpact({}, state);
};

export default getImpact;
