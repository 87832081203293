import { SecondaryButton } from "components/generic/buttons";
import BaseButton from "components/generic/buttons/base";
import Modal from "components/generic/modal";
import ModalContent from "components/generic/modal-content";
import ModalHeader from "components/generic/modal-header";
import { useInstallPrompt } from "hooks/pwaInstall";

const publicURL = process.env.PUBLIC_URL;

const Wrapper = (props) => (
  <div {...props} className="flex flex-col flex-grow" />
);

const Content = ({ gameOverStatus, cityName }) => {
  if (gameOverStatus.money) {
    return (
      <Wrapper>
        <img
          className="flex-grow object-contain"
          alt="cartoon of piggy bank being crushed"
          src={`${publicURL}/other-cards/LilSadPiggy.png`}
        />
        <p className="mt-2 text-sm">
          {cityName} is bankrupted, you are not able to run the city anymore.
          <br />
          Hint: you have a limited budget, do not buy what you can't afford.
        </p>
      </Wrapper>
    );
  }
  if (gameOverStatus.happiness) {
    return (
      <Wrapper>
        <img
          className="flex-grow object-contain"
          alt="Death as a skull"
          src={`${publicURL}/other-cards/Skullie.png`}
        />
        <p className="mt-2 text-sm">
          You are so unpopular that you are forced to quit your position.
          <br />
          Hint: buying services will make you more popular.
        </p>
      </Wrapper>
    );
  }
  if (gameOverStatus.time) {
    return (
      <Wrapper>
        <img
          className="flex-grow object-contain"
          alt="Death as a skull"
          src={`${publicURL}/other-cards/Skullie.png`}
        />
        <p className="mt-2 text-sm">
          Your time is over! You were not able to have 1,000,000 citizens in
          {cityName} within 25 years.
        </p>
      </Wrapper>
    );
  }
  if (gameOverStatus.noPlot) {
    return (
      <Wrapper>
        <img
          className="flex-grow object-contain"
          alt="Death as a skull"
          src={`${publicURL}/other-cards/Skullie.png`}
        />
        <p className="mt-2 text-sm">
          You can't build on thin air. You need to negociate and make compromise
          for building a city.
        </p>
      </Wrapper>
    );
  }
  if (gameOverStatus.notOwner) {
    return (
      <Wrapper>
        <img
          className="flex-grow object-contain"
          alt="Death as a skull"
          src={`${publicURL}/other-cards/Skullie.png`}
        />
        <p className="mt-2 text-sm">
          {cityName} will have its own destiny now. Without you.
          <br />
          Hint: switch citizen agreement before giving away the whole city.
        </p>
      </Wrapper>
    );
  }
  if (gameOverStatus.noGrowth) {
    return (
      <Wrapper>
        <img
          className="flex-grow object-contain"
          alt="Death as a skull"
          src={`${publicURL}/other-cards/Skullie.png`}
        />
        <p className="mt-2 text-sm">
          {cityName} has failed to attract people lately. It will never rise to
          the most important cities of the world.
          <br />
          Hint: don't forget to expand your city with new plots.
        </p>
      </Wrapper>
    );
  }
  if (gameOverStatus.quit) {
    return (
      <Wrapper>
        <img
          className="flex-grow object-contain"
          alt="Death as a skull"
          src={`${publicURL}/other-cards/Skullie.png`}
        />
        <p className="mt-2 text-sm">
          You have escaped successfully from {cityName}!
        </p>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <img
        className="flex-grow object-contain"
        alt="Death as a skull"
        src={`${publicURL}/other-cards/Skullie.png`}
      />
      <p className="mt-2 text-sm">Game over! Better luck next time!</p>
    </Wrapper>
  );
};

const GameOver = ({ show, reset, gamestate }) => {
  const { isInstallable, promptInstall } = useInstallPrompt();
  const { gameOverStatus } = gamestate;
  return (
    <Modal show={show} className="text-citerate-deep-purple" fullHeight>
      <ModalHeader>Game over</ModalHeader>
      <ModalContent>
        <Content
          gameOverStatus={gameOverStatus}
          cityName={gamestate.cityName}
        />
      </ModalContent>
      <div className="flex flex-col justify-around">
        <BaseButton
          href="https://forms.gle/aswLKSvFt5BtAoLx8"
          className="w-full px-4 py-2 mx-auto mt-4 text-xl font-semibold text-left rounded focus:outline-none focus:shadow-outline font-citerate bg-citerate-grass-green text-citerate-deep-purple"
        >
          Leave feedback
        </BaseButton>
        <SecondaryButton onClick={reset} className="mt-2">
          Play again!
        </SecondaryButton>
        {!isInstallable ? (
          <SecondaryButton to="../title" className="mt-2" onClick={reset}>
            Go to title screen
          </SecondaryButton>
        ) : (
          <SecondaryButton onClick={promptInstall}>
            Add to your phone
          </SecondaryButton>
        )}
      </div>
    </Modal>
  );
};

export default GameOver;
