import { SecondaryButton } from "components/generic/buttons";
import { Modal } from "components/generic/modal";
import ModalHeader from "components/generic/modal-header";
import { useState } from "react";
import classnames from "classnames";

import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import goals from "config/goals";
import { abbreviate } from "services/format";

const publicURL = process.env.PUBLIC_URL;

const sharedURL = "https://citerate.com";

const GoalReached = ({ gamestate = {}, show, hideScreen }) => {
  const [showContinue, setShowContinue] = useState(false);
  const displayContinueButton = () =>
    void setTimeout(() => void setShowContinue(true), 300);
  const message = `I reach more than ${abbreviate(
    goals.population,
    0
  )} citizen in ${gamestate.cityName}. You can also play it on ${sharedURL}.`;
  return (
    <Modal show={show} className="text-citerate-deep-purple" fullHeight>
      <ModalHeader>Celebration day!</ModalHeader>

      <div
        className="flex-grow min-h-0 overflow-y-auto"
        style={{ WebkitOverflowScrolling: "touch" }}
      >
        <img
          alt="Fireworks over the city"
          className="-mt-6"
          src={`${publicURL}/other-cards/GoalReached.png`}
        />
        <p className="-mt-2 text-sm text-left">
          You've reached {abbreviate(goals.population, 0)} citizens and you have
          so many years before you!
        </p>
      </div>
      <div
        className={classnames("rounded bg-citerate-grass-green pb-2", {
          "hidden opacity-0": showContinue,
          "flex flex-col flex-col justify-around ": !showContinue,
        })}
      >
        <div className="w-full text-base font-normal text-center md:text-xl">
          Share your progress to continue.
        </div>
        <div className={"flex justify-center w-full h-8"}>
          <TwitterShareButton
            url={sharedURL}
            title={`${message}

@citerateGame #citerate #buildingcities #citysim`}
            beforeOnClick={displayContinueButton}
          >
            <TwitterIcon />
          </TwitterShareButton>
          <FacebookShareButton
            url={sharedURL}
            quote={`${message} citerategame`}
            hashtag="#citerate"
            className="mx-4"
            beforeOnClick={displayContinueButton}
          >
            <FacebookIcon />
          </FacebookShareButton>
          <LinkedinShareButton
            url={sharedURL}
            title="A new city is born!"
            summary={message}
            source="Citerate"
            beforeOnClick={displayContinueButton}
          >
            <LinkedInIcon />
          </LinkedinShareButton>
        </div>
      </div>
      <SecondaryButton
        onClick={() => {
          setShowContinue(false);
          hideScreen();
        }}
        className={classnames({
          hidden: !showContinue,
        })}
      >
        Let's make it big!
      </SecondaryButton>
    </Modal>
  );
};

export default GoalReached;
