import { useEffect } from "react";
import { animated, config, useSprings } from "react-spring";
import classnames from "classnames";
import { Dollar1, Dollar2 } from "components/generic/sparkles/dollars";

const toPercentString = (v) => `${v * 100}%`;

const BillsShower = ({ show }) => {
  const [springs, set] = useSprings(20, () => ({
    from: {
      x: -0.3 + Math.random() * 0.4,
      left: toPercentString(0.3 + Math.random() * 0.4),
    },
    config: {
      ...config.molasses,
      friction: 500,
      mass: 5,
    },
    duration: 600,
  }));

  useEffect(() => {
    if (show)
      set(() => ({
        to: {
          x: 1 + Math.random() * 0.4,
          left: toPercentString(0.1 + Math.random() * 0.8),
        },
        reset: true,
      }));
  }, [set, show]);

  return (
    <div
      className={classnames(
        "  absolute top-0 left-0 w-full h-full pointer-events-none z-40 transition-opacity duration-100",
        {
          "opacity-100": show,
          "opacity-0": !show,
        }
      )}
    >
      {springs.map((props, i) => {
        const Dollar = i % 2 === 0 ? Dollar1 : Dollar2;
        return (
          <animated.div
            key={`${i}-bills`}
            className={"absolute w-10 h-10"}
            style={{
              left: props.left,
              top: props.x.interpolate(toPercentString),
              transformOrigin: "0 -50px",
              transform: props.x.interpolate((x) => {
                const p = Math.cos((x + i) * 10);
                return `rotate(${p}rad)`;
              }),
            }}
          >
            <Dollar className="w-full h-full" />
          </animated.div>
        );
      })}
    </div>
  );
};

export default BillsShower;
