import { useEffect, useRef, useState } from "react";
import classnames from "classnames";

import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import { ReactComponent as DiscordLogo } from "assets/Discord-Logo-Black.svg";
import { ReactComponent as DiscordLogoWhite } from "assets/Discord-Logo-White.svg";
import { ReactComponent as GitlabLogo } from "assets/gitlab-icon-1-color-black-rgb.svg";

import { ReactComponent as SplashLogo } from "assets/art/splashscreen-logo.svg";

import Link from "components/generic/link";

import { Banner } from "./banner";
import { Badge } from "./badge";
import { ExpandCard, InfraCard } from "./cards";
import { Comment } from "./comment";
import { CTA } from "./cta";

const H2 = ({ className, children, ...props }) => (
  <h2 {...props} className={classnames("font-bold text-xl mt-8", className)}>
    {children}
  </h2>
);

const P = ({ className, ...props }) => (
  <p {...props} className={classnames("mt-2 text-sm", className)} />
);

const Home = () => {
  const headRef = useRef(undefined);
  const [isHeadVisible, setHeadVisibility] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const headEntry = entries[0];
      setHeadVisibility(headEntry.isIntersecting);
    });
    observer.observe(headRef.current);
    return () => {
      observer.disconnect();
    };
  }, [headRef]);

  return (
    <div className="relative flex flex-col w-full">
      <Banner show={!isHeadVisible} />
      <div className="flex flex-col py-6 bg-citerate-green " ref={headRef}>
        <div className="w-full max-w-3xl mx-auto mb-4 smb:mb-1">
          <SplashLogo className="w-full transform" />
          <div className="flex w-full text-white">
            <div className="flex flex-row h-6 mx-auto text-white">
              <Link href="https://discord.gg/23PaubMHXk" className="h-full">
                <DiscordLogoWhite className="h-full" />
              </Link>
              <Link href="https://twitter.com/CiterateGame" className="pl-4">
                <TwitterIcon />
              </Link>
              <Link
                href="https://www.facebook.com/CiterateGame"
                className="px-4"
              >
                <FacebookIcon />
              </Link>
              <Link href="https://www.instagram.com/citerategame/">
                <InstagramIcon />
              </Link>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full max-w-2xl px-6 mx-auto sm:px-12 md:px-0 md:py-4 lg:py-6 xl:py-8">
          <CTA />

          <div className="flex flex-wrap justify-around lg:mt-6">
            <Badge className="">Free</Badge>
            <Badge>No ads</Badge>
            <Badge className="hidden md:flex">No in-app payments</Badge>
            <Badge>
              <Link href="https://gitlab.com/karen/terrence">Open source</Link>
            </Badge>
          </div>
        </div>
      </div>
      <div className="flex-grow px-6 sm:px-12 bg-citerate-sky-blue">
        <div className="flex flex-col max-w-2xl mx-auto ">
          <H2>Build services</H2>
          <div className="justify-around pt-4 mb-4 -mt-4 grid grid-flow-col gap-x-2 grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 smd:grid-cols-4 sm:mb-8 smb:mb-12 md:mb-16">
            <InfraCard serviceId="LadderTruck" className="hidden xs:block" />
            <InfraCard serviceId="ArtMuseum" className="hidden smd:block" />
            <InfraCard serviceId="Hospital" className="hidden sm:block" />
            <InfraCard serviceId="CampusUniversity" />
          </div>

          <Comment
            author={`@citizen${Math.ceil(Math.random() * 35)}`}
            avatarPosition="left"
          >
            Respond to citizens’ requests for upgraded facilities - if you have
            the budget!
          </Comment>

          <H2>Grow your city</H2>

          <div className="pt-4 mb-6 -mt-4 grid gap-x-2 grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 smd:grid-cols-4 sm:mb-8 smb:mb-12 md:mb-16">
            <ExpandCard level={1} className="" />
            <ExpandCard level={3} className="hidden xs:block" />
            <ExpandCard level={4} className="hidden sm:block" />
            <ExpandCard level={5} className="hidden smd:block" />
          </div>

          <Comment author={`@citizen${Math.ceil(Math.random() * 35)}`}>
            Expand the city at an appropriate pace, carefully managing growth
          </Comment>

          <H2 className="-mt-2">About the game</H2>

          <div className="px-3 pt-2 pb-6 mt-4 bg-citerate-sand-yellow">
            <P>This game is about building cities in the 21st century.</P>

            <P>
              Some economists think that if we were to allow anyone to move
              freely in the world we could double the GDP of the planet. With
              the latest trends, it's not going to happen soon.
            </P>

            <P>
              If the big cities are not accessible, we need to create new big
              cities. This is the goal of the charter cities.
            </P>

            <P>
              In citerate, you are an entrepreneur building one of those cities
              using direct feedback from the citizens and iterating on their
              needs, rather than with a masterplan.
            </P>

            <P>
              To learn more about those new cities follow{" "}
              <Link href="https://twitter.com/2xgdp">DoubleGDP</Link> and the{" "}
              <Link href="https://twitter.com/CCIdotCity">
                Charter City Institute
              </Link>
              .
            </P>
          </div>

          <H2 className="mt-4">Join the community</H2>

          <div className="px-3 py-4 pb-6 mt-4 bg-citerate-sand-yellow ">
            <div className="flex flex-col w-full text-white">
              <P>
                Citerate is also a community. Join us if you'd like to chat,
                share or contribute to the game.
              </P>
              <div className="flex-wrap mx-auto mt-2">
                <Link href="https://discord.gg/23PaubMHXk" className="text-sm">
                  <span className="underline">Chat with us on Discord</span>{" "}
                  <DiscordLogo className="inline-block h-8" />
                </Link>
                <Link
                  href="https://gitlab.com/karen/terrence"
                  className="px-4 text-sm"
                >
                  <span className="underline">Contribute on GitLab</span>{" "}
                  <GitlabLogo className="inline-block h-8" />
                </Link>
              </div>
              <div className="flex-wrap mx-auto mt-2">
                <Link
                  href="https://twitter.com/CiterateGame"
                  className="text-sm"
                >
                  <span className="underline">Twitter</span> <TwitterIcon />
                </Link>
                <Link
                  href="https://www.facebook.com/CiterateGame"
                  className="px-4 text-sm"
                >
                  <span className="underline">Facebook</span> <FacebookIcon />
                </Link>
                <Link
                  href="https://www.instagram.com/citerategame/"
                  className="text-sm "
                >
                  <span className="underline">Instagram</span> <InstagramIcon />
                </Link>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-center w-full mt-8 mb-24">
            <Link to="/privacy-policy" className="m-auto">
              Privacy policy
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
