import { createContext, useContext, useState } from "react";

import defaultUiConfig from "../config/ui";

const CONFIG_KEY = "citerate_custom_config";

const readConfigFromLocalStorage = () => {
  if (typeof window !== "undefined" && window.localStorage) {
    const configFromStorage = JSON.parse(
      window.localStorage.getItem(CONFIG_KEY) || "{}"
    );
    if (
      !configFromStorage.version ||
      configFromStorage.version < defaultUiConfig.version
    ) {
      console.log(
        "A new version of the configuration has been detected: overwriting user preferences."
      );
      saveConfigToLocalStorage(defaultUiConfig);
      return defaultUiConfig;
    } else {
      return configFromStorage;
    }
  }
  return defaultUiConfig;
};

const saveConfigToLocalStorage = (config) => {
  if (typeof window !== "undefined" && window.localStorage) {
    console.log("Configuration saved!");
    window.localStorage.setItem(CONFIG_KEY, JSON.stringify(config));
  }
};

// Do not use unless you're out of react context
export const getCurrentConfig = () => readConfigFromLocalStorage();

const ConfigContext = createContext();

export const useConfig = () => {
  const configApi = useContext(ConfigContext);

  if (configApi) {
    const [config, updateConfig] = configApi;

    const setConfig = (newConfig) => {
      saveConfigToLocalStorage(newConfig);
      updateConfig(newConfig);
    };

    return {
      config,
      setConfig,
    };
  } else {
    return {
      config: defaultUiConfig,
      setConfig: () => {},
    };
  }
};

export const UIConfigProvider = ({ children }) => {
  const uiStateAPI = useState({
    ...defaultUiConfig,
    ...readConfigFromLocalStorage(),
  });

  return (
    <ConfigContext.Provider value={uiStateAPI}>
      {children}
    </ConfigContext.Provider>
  );
};
