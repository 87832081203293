import classnames from "classnames";

function StarIcon({ className, ...props }) {
  return (
    <svg
      viewBox="0 0 45 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classnames("h-full p-1", className)}
      {...props}
    >
      <path
        d="M27.195 37.611l-9.284 8.836c-1.206 1.149-3.191.401-3.36-1.265l-1.295-12.82a2.02 2.02 0 00-1.03-1.566L1.04 24.609c-1.453-.805-1.363-2.944.153-3.62l11.667-5.207a2.016 2.016 0 001.157-1.473l2.369-12.661c.308-1.646 2.35-2.22 3.454-.972l8.507 9.601c.439.496 1.09.742 1.743.657l12.653-1.636c1.644-.213 2.815 1.572 1.983 3.019l-6.41 11.14a2.038 2.038 0 00-.08 1.878l5.45 11.65c.708 1.514-.61 3.191-2.23 2.838l-12.467-2.716a1.992 1.992 0 00-1.793.505z"
        fill="#462E53"
      />
    </svg>
  );
}

export default StarIcon;
