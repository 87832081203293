// array of 10 best scores
let highscores = undefined;
const highscoresKey = "Highscore-citerate";

export const readHighScores = () =>
  highscores === undefined
    ? JSON.parse(window.localStorage.getItem(highscoresKey)) || []
    : highscores;

export const saveScore = (state) => {
  const previousScores = readHighScores();
  const score = computeScore(state);

  previousScores.push(score);

  window.localStorage.setItem(highscoresKey, JSON.stringify(previousScores));
};

const computeScore = ({
  turn,
  population,
  bankBalance,
  happiness,
  gameOverStatus,
  services,
}) => ({
  turn,
  population,
  bankBalance,
  happiness,
  gameOverStatus,
  services,
});
