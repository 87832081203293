import { makeTweet } from "./factory";

let lastTick = -1;
let memorizedTweet = undefined;

export const getHint = (gamestate, config) => {
  if (gamestate.tick === lastTick) {
    return memorizedTweet;
  }

  const nextTweet = getHintTweet(gamestate, config);
  memorizedTweet = nextTweet;
  lastTick = gamestate.tick;
  return nextTweet;
};

let lastGamestate = null;
function getHintTweet(gamestate) {
  const previousGamestate = lastGamestate;
  lastGamestate = gamestate;

  if (previousGamestate) {
    // First land rent income
    if (previousGamestate.bankBalance === 0 && gamestate.bankBalance > 0) {
      return makeHint("Woohoo! We've got some cash flow from land rent!");
    }

    // First plot sale
    if (previousGamestate.plotSales === 0 && gamestate.plotSales > 0) {
      return makeHint("Awesome! We've got some money from selling plots");
    }

    // Happinness crossed threshold
    if (previousGamestate.happiness > 20 && gamestate.happiness <= 20) {
      return makeHint("Oh no, people are really unhappy with us");
    }
  }
}

function makeHint(message) {
  return makeTweet({
    message,
    type: "hint",
    author: "@city-concierge",
    actionable: false,
  });
}
