import { useState, useEffect } from "react";

import Modal from "components/generic/modal";

import Layout from "components/layout";

import { PrimaryButton } from "components/generic/buttons";
import ModalHeader from "components/generic/modal-header";
import Link from "components/generic/link";
import ModalContent from "components/generic/modal-content";

const CreditsScreen = () => {
  const [display, setDisplay] = useState(false);
  useEffect(() => {
    setTimeout(() => setDisplay(true), 0);
  });
  return (
    <Layout>
      <Modal show={display} fullHeight>
        <ModalHeader>Credits</ModalHeader>

        <ModalContent>
          <p className="mt-2">
            Citerate is a game brought to you by{" "}
            <Link href="https://www.doublegdp.com/"> Double GDP</Link> and this
            team:
          </p>

          <ul className="list-disc list-inside leading-6 mt-2">
            <li>Original idea and game design: Sid Sijbrandij</li>
            <li>Code and UI: Alexandre Valsamou-Stanislawski</li>
            <li>Art: Lauren Beltramo</li>
            <li>
              Testers: FX, Minza, Nolan, Nicolas...{" "}
              <Link href="https://forms.gle/aswLKSvFt5BtAoLx8">you</Link>
            </li>
          </ul>

          <p className="mt-2">
            We'd love to hear about your experience:{" "}
            <Link href="">join our Discord</Link>.
          </p>

          <p className="mt-2">The code is available on GitLab</p>

          <p className="mt-2">
            To learn more about new cities,{" "}
            <Link href="https://www.chartercitiesinstitute.org/">
              go to the Charter Cities Institute website
            </Link>
            .
          </p>
        </ModalContent>

        <div className="flex flex-col justify-around">
          <PrimaryButton to="../title" className="mt-8">
            Go to title screen
          </PrimaryButton>
        </div>
      </Modal>
    </Layout>
  );
};

export default CreditsScreen;
