import { memo, useMemo } from "react";

import Modal from "components/generic/modal";

import { PrimaryButton } from "components/generic/buttons";
import ModalHeader from "components/generic/modal-header";
import { getNewCityName } from "config/state";

const PickACityName = ({ show, selectCityName, gamestate }) => {
  const cityNames = useMemo(() => {
    const names = [];
    for (let i = 0; i < 4; i++) {
      let name = undefined;
      while (!name && !names.includes(name)) {
        name = getNewCityName();
      }
      names.push(name);
    }
    return names;
    // The dependency is on the startTime of the game
    // This way we only generate a new set of cities once per game
    // eslint-disable-next-line
  }, [gamestate.startTime]);

  return (
    <Modal show={show} className="text-citerate-deep-purple">
      <ModalHeader>Choose a name for the city!</ModalHeader>
      <p className="text-sm">
        Great cities have great names. Pick the one that you like the most.
      </p>
      {cityNames.map((name, i) => (
        <PrimaryButton
          key={name + i}
          onClick={() => selectCityName(name)}
          className="mt-8"
        >
          {name}
        </PrimaryButton>
      ))}
    </Modal>
  );
};

export default memo(
  PickACityName,
  (prevProps, nextProps) => prevProps.show === nextProps.show
);
