import { SecondaryButton } from "components/generic/buttons";
import { useEffect, useRef, useState } from "react";
import { scheduleCityReminder } from "services/notifications";
import { JSONPretty } from "./jsonPretty";

export const ActionsPanel = () => {
  const [notificationsData, setNotificationData] = useState(null);
  const intervalRef = useRef(null);

  useEffect(() => {
    intervalRef.current =
      window.cordova &&
      window.cordova.plugins.notification &&
      window.setInterval(
        () =>
          window.cordova.plugins.notification.local.getAll((nots) => {
            setNotificationData(nots);
          }),
        500
      );

    return () => {
      if (intervalRef) clearInterval(intervalRef);
    };
  }, []);

  return (
    <div className="p-4 h-full">
      <SecondaryButton
        onClick={() => {
          window.crashMePlease();
        }}
        className="mt-4"
      >
        Crash the app
      </SecondaryButton>
      <SecondaryButton
        onClick={() => {
          scheduleCityReminder("Debug city", true);
        }}
      >
        Display a notification
      </SecondaryButton>

      <div className="max-h-screen h-full overflow-auto">
        <JSONPretty data={notificationsData} />
      </div>
    </div>
  );
};
