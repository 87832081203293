import { discardSavedGame } from "hooks/autosave";
import { useConfig } from "hooks/config";
import { useReducer, useRef } from "react";
import { scheduleCityReminder } from "services/notifications";
import { getTweet } from "services/tweetGenerator";
import getImpact from "./impact";
import { initializer } from "./initState";
import { computeNextState } from "./nextState";

const reducer = (state, action) => {
  const { type: actionType, config } = action;
  switch (actionType) {
    case "advance":
      const { tweet, move } = action;

      // A action has been chosen (move) for the tweet --> retrieve the impact of this action
      const actionImpact = getImpact({ move, tweet, state });

      const nextState = computeNextState(state, actionImpact);
      const nextStateWithTick = {
        ...nextState,
        tick: state.tick + 1,
      };
      const nextTweet = getTweet(nextStateWithTick, config);

      return {
        ...nextStateWithTick,
        tweet: nextTweet,
        tweets: [...state.tweets, nextTweet],
      };
    case "reset":
      discardSavedGame();
      return initializer({ config });
    case "quit":
      discardSavedGame();
      return {
        ...state,
        gameOverStatus: {
          quit: true,
        },
        isGameOver: true,
      };
    case "setCityName":
      scheduleCityReminder(action.cityName);
      return {
        ...state,
        cityName: action.cityName,
      };
    default:
      console.log("Undefined action behavior");
      console.log({ action });
      return state;
  }
};

const useGameState = (initialState) => {
  const { config } = useConfig();
  const [state, dispatch] = useReducer(
    reducer,
    { config, initialState },
    initializer
  );
  const history = useRef([]);

  const left = () => {
    history.current.push(state);
    dispatch({
      type: "advance",
      move: "left",
      tweet: state.tweet,
      config,
    });
  };

  const right = () => {
    history.current.push(state);
    dispatch({
      type: "advance",
      move: "right",
      tweet: state.tweet,
      config,
    });
  };

  const reset = () => {
    history.current = [];
    dispatch({
      type: "reset",
      config,
    });
  };

  const quit = () => {
    dispatch({
      type: "quit",
      config,
    });
  };

  const setCityName = (cityName) => {
    dispatch({
      type: "setCityName",
      cityName,
      config,
    });
  };

  const { isGameOver, isWin } = state;

  return {
    // Actions
    left,
    right,
    reset,
    quit,

    // State and previous states
    state,
    history: history.current,

    // Winning conditions met?
    isGameOver,
    isWin,

    setCityName,
  };
};

export default useGameState;
