import { memo } from "react";
import { turnToQuarterFull } from "../services/turns";
import { abbreviate } from "services/format";
import classnames from "classnames";
import { getPreviousState } from "services/state";
import { animated, useTransition } from "react-spring";
import BillsShower from "./effects/billshower";

import { scaleLog } from "d3-scale";

import goalsConfig from "config/goals";

const seasonColors = [
  /* winter:*/ {
    100: "#CEDAF9", // winter
    500: "#97B0F1",
  },
  /* spring: */ {
    100: "#BDECE1", // spring
    500: "#64D4BA",
  },
  /*summer: */ {
    100: "#F4F2B9", // summer
    500: "#DCD86E",
  },
  /*autumn: */ {
    100: "#F3E0CA", // autumn
    500: "#ECAD63",
  },
];

const _scale = scaleLog([1, goalsConfig.population], [0, 100]);
export const populationScale = (v) => {
  if (v < 1) return 0;
  return _scale(v);
};

const TickValue = ({ animatedValue }) => (
  <>
    <div className="absolute top-0 w-full h-full overflow-hidden rounded-full">
      <animated.div
        className="absolute top-0 bg-gray-400"
        style={{
          width: "1px",
          height: "100%",
          left: animatedValue.interpolate((v) => `${populationScale(v)}%`),
        }}
      ></animated.div>
    </div>
    <animated.div
      className={classnames("absolute text-xs font-normal", {})}
      style={{
        bottom: "100%",
        left: animatedValue.interpolate((v) => `${populationScale(v)}%`),
      }}
    >
      {animatedValue.interpolate((v) => abbreviate(v, 0))}
    </animated.div>
  </>
);

const Tick = ({ v, labelOnly }) => (
  <div
    className={classnames("absolute top-0", {
      "bg-transparent": labelOnly,
      "bg-gray-500": !labelOnly,
    })}
    style={{
      width: "1px",
      height: "150%",
      left: `${populationScale(v)}%`,
      transform: "translateY(-12.5%)",
    }}
  >
    <div
      className={classnames("absolute text-xs", {
        "right-0": v >= goalsConfig.population * 0.01,
      })}
      style={{ top: "100%" }}
    >
      {abbreviate(v, 0)}
    </div>
  </div>
);

const NewTurn = ({
  show = false,
  gamestate,
  history,
  onMessageDisplayed = () => {},
}) => {
  const previousState = getPreviousState(gamestate, history);

  const isBalancePositive = previousState.bankBalance < gamestate.bankBalance;

  const previousSeason = (gamestate.turn + 3) % 4;
  const currentSeason = gamestate.turn % 4;

  const transitions = useTransition(show, null, {
    from: {
      bgOpacity: 0,
      bannerY: 0,
      seasonColor: seasonColors[previousSeason][100],
      seasonColorAccent: seasonColors[previousSeason][500],
      quarterLabel: 0,
      population: previousState.population,
    },
    enter: [
      { bgOpacity: 1, bannerY: 1 },
      {
        seasonColor: seasonColors[currentSeason][100],
        seasonColorAccent: seasonColors[currentSeason][500],
        quarterLabel: 1,
      },
      {
        population: gamestate.population,
      },
    ],
    leave: [{ bgOpacity: 0, bannerY: 0 }],
    onRest: (finished, direction) => {
      if (finished && direction === "enter") {
        onMessageDisplayed();
      }
    },
  });

  return (
    <>
      <BillsShower show={show && isBalancePositive} />
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div
              key={key}
              className={"absolute top-0 left-0 z-100 w-full h-full"}
            >
              <animated.div
                className="absolute top-0 left-0 w-full h-full bg-gray-400"
                style={{ opacity: props.bgOpacity.interpolate((o) => o * 0.4) }}
              />
              <animated.div
                className="absolute top-0 left-0 flex flex-col items-center justify-center w-full h-full text-3xl font-bold tracking-wide text-gray-900 shadow-sm"
                style={{
                  transform: props.bannerY.interpolate((y) => `scaleY(${y})`),
                  opacity: props.bgOpacity,
                }}
              >
                <animated.div
                  className={classnames(
                    "flex flex-col content-center justify-center w-full py-5 bg-opacity-75 md:scale-125 md:transform md:rounded-md"
                  )}
                  style={{
                    background: props.seasonColor,
                    borderColor: props.seasonColorAccent,
                    borderBottomWidth: props.bannerY.interpolate(
                      (y) => `${y}rem`
                    ),
                  }}
                >
                  <div className="mx-auto font-semibold">
                    {gamestate.cityName}
                  </div>
                  <div className="relative w-full h-12 m-auto">
                    <animated.div
                      className="absolute top-0 w-full"
                      style={{
                        opacity: props.quarterLabel.interpolate((ql) => 1 - ql),
                        transform: props.quarterLabel.interpolate(
                          (ql) => `translateY(${ql * -40}px)`
                        ),
                      }}
                    >
                      <p className="w-full text-xl text-center">
                        {turnToQuarterFull(gamestate.turn - 1)}
                      </p>
                    </animated.div>
                    <animated.div
                      className="absolute top-0 w-full"
                      style={{
                        opacity: props.quarterLabel,
                        transform: props.quarterLabel.interpolate(
                          (ql) => `translateY(${(1 - ql) * 40}px)`
                        ),
                      }}
                    >
                      <p className="w-full text-xl text-center">
                        {turnToQuarterFull(gamestate.turn)}
                      </p>
                    </animated.div>
                  </div>
                  {gamestate.population > 0 &&
                    gamestate.population < goalsConfig.population && (
                      <div className="px-8 mt-2">
                        <div className="relative w-full h-4 mb-2 bg-transparent ">
                          <div className="absolute top-0 left-0 w-full h-4 overflow-hidden bg-gray-100 rounded-lg bg-opacity-25 shadow-xs">
                            <animated.div
                              className="h-full bg-white opacity-50 "
                              style={{
                                width: props.population.interpolate(
                                  (p) => `${populationScale(p)}%`
                                ),
                              }}
                            ></animated.div>
                          </div>

                          <Tick v={0} labelOnly />
                          <Tick v={10} />
                          <Tick v={100} />
                          <Tick v={10000} />
                          <Tick v={100000} />
                          <Tick v={1000000} labelOnly />
                          <TickValue animatedValue={props.population} />
                        </div>
                        <div className="mt-6 text-sm font-normal tracking-wide text-center uppercase">
                          Population
                        </div>
                      </div>
                    )}
                  {gamestate.population >= goalsConfig.population && (
                    <div className="px-8 -mt-4">
                      <animated.div className="relative w-full text-center bg-transparent text-2xl">
                        {props.population.interpolate((p) => abbreviate(p, 0))}
                      </animated.div>
                      <div className="-mt-2 text-sm font-normal tracking-wide text-center uppercase">
                        Population
                      </div>
                    </div>
                  )}
                </animated.div>
              </animated.div>
            </animated.div>
          )
      )}
    </>
  );
};

export default memo(
  NewTurn,
  (prevProps, nextProps) => prevProps.show === nextProps.show
);
