import classnames from "classnames";
import { PrimaryButton } from "components/generic/buttons";
import { useState } from "react";

import { Modal } from "components/generic/modal";
import FeatureFlags from "./flags";
import { ActionsPanel } from "./actions";
import { getVersion } from "config/version";
import { JSONPretty } from "./jsonPretty";

const Tab = ({
  className,
  children,
  setTab,
  value,
  selected,
  ...otherProps
}) => (
  <button
    className={classnames(
      "p-1 focus:outline-none focus:shadow-inner border-b-2 border-citerate-deep-purple w-full",
      {
        "border-opacity-100": value === selected,
        "border-opacity-25": value !== selected,
      },
      className
    )}
    onClick={() => setTab(value)}
    {...otherProps}
  >
    {children}
  </button>
);

const DebugModal = ({
  show,
  toggleDebug,
  gamestate,
  gameUIState,
  tweetQueue,
}) => {
  const [tab, setTab] = useState("state");

  return (
    <Modal
      show={show}
      fullHeight
      paddedContent={false}
      className="text-citerate-deep-purple"
    >
      <div className="flex flex-row justify-between">
        <Tab setTab={setTab} value="state" selected={tab}>
          State
        </Tab>
        <Tab setTab={setTab} value="uistate" selected={tab}>
          UI State
        </Tab>
        <Tab setTab={setTab} value="queue" selected={tab}>
          Queue
        </Tab>
        <Tab setTab={setTab} value="config" selected={tab}>
          Config
        </Tab>
        <Tab setTab={setTab} value="actions" selected={tab}>
          Actions
        </Tab>
      </div>
      <div className="max-h-screen h-full overflow-auto">
        {tab === "state" && <JSONPretty data={gamestate} />}
        {tab === "uistate" && <JSONPretty data={gameUIState} />}
        {tab === "queue" && <JSONPretty data={tweetQueue} />}
        {tab === "config" && <FeatureFlags />}
        {tab === "actions" && <ActionsPanel />}
      </div>
      <div className="text-xs w-full text-left py-1 px-2 font-normal tracking-wide">
        Current version : {getVersion()}
      </div>
      <div className="px-2 pb-2 w-full">
        <PrimaryButton onClick={toggleDebug} className="">
          Close debug screen
        </PrimaryButton>
      </div>
    </Modal>
  );
};

export default DebugModal;
