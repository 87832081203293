import femaleNames from "./data/names/female.json";
import maleNames from "./data/names/male.json";

const publicURL = process.env.PUBLIC_URL;

const getId = (citizenName) => {
  return parseFloat(citizenName.replace(/.*(\d+)/, "$1")) || 0;
};

const avatarDef = {
  "@newCityCorpCEO": {
    name: "You",
    path: `${publicURL}/avatars/Avatar_C.png`,
    description: `The new city official account`,
  },
  "@thelocal": {
    name: "John",
    path: `${publicURL}/avatars/Avatar_LocalNews.png`,
    description: `The local newspaper avatar`,
  },
  "@realEstateDaily": {
    name: "Esther",
    path: `${publicURL}/avatars/Avatar_RealEstate.png`,
    description: `The local newspaper avatar`,
  },
  "@landOwner1": {
    name: "Sarah",
    path: `${publicURL}/avatars/Avatar_LandOwner1.png`,
    description: `A land owner willing to sell`,
  },
  "@landOwner2": {
    name: "Emmanuel",
    path: `${publicURL}/avatars/Avatar_LandOwner2.png`,
    description: `Another land owner`,
  },
  "@angel1": {
    path: `${publicURL}/avatars/Avatar_AngelInvestor.png`,
    description: `An angel investor`,
  },
  "@angel2": {
    path: `${publicURL}/avatars/Avatar_AngelInvestor.png`,
    description: `An angel investor`,
  },
  "@angel3": {
    path: `${publicURL}/avatars/Avatar_AngelInvestor.png`,
    description: `An angel investor`,
  },
};

// the numbers of avatars available for citizens
const nbAvatars = 36;

const isFemale = (avatarId) => {
  if (
    avatarId <= 12 ||
    (avatarId >= 18 && avatarId < 24) ||
    (avatarId >= 30 && avatarId <= 34)
  ) {
    return true;
  }
  return false;
};

export const getAvatar = (author, seed = 0) => {
  if (avatarDef[author]) return avatarDef[author];

  const id = getId(author);
  const avatarId = 1 + ((seed + id * 3931) % nbAvatars);
  const name = isFemale(avatarId)
    ? femaleNames[(seed + id * 7331) % femaleNames.length]
    : maleNames[(seed + id * 7331) % maleNames.length];

  return {
    name,
    path: `${publicURL}/avatars/avatar_${avatarId}.png`,
    description: `Portrait of ${name} @${author}`,
  };
};
