import * as legacy from "./legacy";
import * as tree from "./tree";
import { getCurrentConfig } from "hooks/config";

const getUseLevelTreeFlag = () => {
  const { useLevelTree } = getCurrentConfig();
  return useLevelTree;
};

const getCategoryById = (categoryId) => {
  return getUseLevelTreeFlag()
    ? tree.getCategoryById(categoryId)
    : legacy.getCategoryById(categoryId);
};

const getServiceById = (serviceId) => {
  return getUseLevelTreeFlag()
    ? tree.getServiceById(serviceId)
    : legacy.getServiceById(serviceId);
};

const getAllCategories = () =>
  getUseLevelTreeFlag() ? tree.getAllCategories() : legacy.getAllCategories();

const pickNextService = (gamestate) =>
  getUseLevelTreeFlag()
    ? tree.pickNextService(gamestate)
    : legacy.pickNextService(gamestate);

export { getAllCategories, getCategoryById, getServiceById, pickNextService };
