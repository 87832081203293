import { lazy, Suspense } from "react";
import { importMDX } from "mdx.macro";

import { ReactComponent as SplashLogo } from "assets/art/splashscreen-logo.svg";

const Content = lazy(() => importMDX("./content.mdx"));

const Loading = () => <p className="w-full text-center text-xl">Loading</p>;

const PrivacyPolicy = () => {
  return (
    <div className="bg-citerate-sky-blue min-h-full">
      <div className="flex flex-col py-6 bg-citerate-green ">
        <div className="w-full max-w-3xl mx-auto mb-4 smb:mb-1">
          <SplashLogo className="w-full transform" />
        </div>
      </div>
      <div className="flex-grow px-6 sm:px-12 pb-16">
        <div className="px-3 py-4 pb-6 mt-4 bg-citerate-sand-yellow">
          <div className="flex w-full text-white flex-col content">
            <Suspense fallback={<Loading />}>
              <Content />
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PrivacyPolicy;
