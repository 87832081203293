import worldCities from "./data/worldcities.json";

const state = {
  minPlotPrice: 100,
  initialValues: {
    population: 0, // formerly citizens
    bankBalance: 0,
    happiness: 50,
    plotPrice: 100, // Current price of each plot
    plotNumber: 0,
    stocks: 10000,
  },
};

export default state;

export const getNewCityName = () => {
  const oldCityName =
    worldCities[Math.floor(Math.random() * worldCities.length)];
  return `New ${oldCityName}`;
};
