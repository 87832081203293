import classnames from "classnames";

function SvgComponent({ className, ...props }) {
  return (
    <svg
      viewBox="0 0 150 150"
      className={classnames("h-full", className)}
      {...props}
    >
      <style>{".prefix__st11{fill:#462e53}"}</style>
      <g id="prefix__Layer_2">
        <path
          d="M115.32 119.07H34.68a2.29 2.29 0 01-2.29-2.29V44.69a2.29 2.29 0 012.29-2.29h80.63a2.29 2.29 0 012.29 2.29v72.09a2.281 2.281 0 01-2.28 2.29z"
          fill="none"
          stroke="#462e53"
          strokeWidth={12}
          strokeMiterlimit={10}
        />
        <path
          className="prefix__st11"
          d="M61.64 54.67H48.35c-1.24 0-2.24-1-2.24-2.24V27.18c0-1.24 1-2.24 2.24-2.24h13.29c1.24 0 2.24 1 2.24 2.24v25.25c0 1.24-1.01 2.24-2.24 2.24zM101.65 54.67H88.36c-1.24 0-2.24-1-2.24-2.24V27.18c0-1.24 1-2.24 2.24-2.24h13.29c1.24 0 2.24 1 2.24 2.24v25.25c.01 1.24-1 2.24-2.24 2.24zM115.32 119.07H34.68a2.29 2.29 0 01-2.29-2.29V73.99a2.29 2.29 0 012.29-2.29h80.63a2.29 2.29 0 012.29 2.29v42.78c.01 1.27-1.02 2.3-2.28 2.3z"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
