import { getServiceById } from "config/services";
import { servicesPerLevel } from "config/services/tree";

const getCityServiceLevel = (gamestate = {}) => {
  return getCityServiceLevelFromServices(gamestate.availableServices);
};

const getCityServiceLevelFromServices = (availableServices = []) => {
  if (availableServices.length === 0) return 0;

  const services = availableServices.map(getServiceById);
  const levels = services.map((s) => s.level);
  const maxLevel = Math.max(...levels);
  const maxLevelServicesCount = levels.filter((l) => l === maxLevel).length;

  return servicesPerLevel[maxLevel] === maxLevelServicesCount
    ? maxLevel + 1
    : maxLevel;
};

const getCityOccupation = (gamestate) => {
  const totalSoldPlot = gamestate.population / 2;
  if (totalSoldPlot === 0) return 0;
  return totalSoldPlot / gamestate.plotNumber;
};

const getPreviousState = (gamestate, history) => {
  return history.length === 0 ? gamestate : history[history.length - 1];
};

const isEventHappening = (gamestate, event) =>
  event.turn + event.duration > gamestate.turn;

const getLastEvent = (gamestate) =>
  gamestate.events.length > 0
    ? gamestate.events[gamestate.events.length - 1]
    : undefined;

const getCurrentEvent = (gamestate) => {
  const currentTweetEvent =
    gamestate.tweet &&
    gamestate.tweet.type === "event" &&
    gamestate.tweet.eventType;

  if (currentTweetEvent)
    return {
      eventType: currentTweetEvent,
      turn: gamestate.turn,
      duration: 0,
    };

  const lastEvent = getLastEvent(gamestate);
  return lastEvent && isEventHappening(gamestate, lastEvent)
    ? lastEvent
    : undefined;
};

export {
  getCityServiceLevel,
  getCityServiceLevelFromServices,
  getCityOccupation,
  getPreviousState,
  getCurrentEvent,
  getLastEvent,
};
