import classnames from "classnames";

export const HelpCardContent = ({
  title,
  color = "#FFF8DF",
  accent = "#FBE491",
  children,
  className,
}) => (
  <div
    className={classnames(
      "flex flex-col items-start w-full h-full p-2",
      className
    )}
    style={{ background: color }}
  >
    <div
      className="flex flex-col h-full w-full p-1 text-xs font-light tracking-tight text-left relative"
      style={{ background: accent }}
    >
      <p className="w-full text-center text-base mt-1 px-1 uppercase tracking-wide z-20">
        {title}
      </p>
      <div className="flex flex-col flex-grow mt-2 z-20 text-sm items-center w-full h-full">
        <div className="w-full my-auto overflow-auto text-xs smd:text-sm h-full">
          {children}
        </div>
      </div>
      <div
        className="flex absolute top-0 left-0 h-full w-full opacity-75"
        style={{ color }}
      >
        <QuestionMark />
      </div>
    </div>
  </div>
);

function QuestionMark(props) {
  return (
    <svg
      viewBox="0 0 3334 3334"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      {...props}
    >
      <path fill="none" d="M0 0h3333.33v3333.33H0z" />
      <text
        x={763.333}
        y={2883.33}
        fontFamily="'WorkSans-Regular','Work Sans'"
        fontSize={3333.33}
        fill="currentColor"
      >
        {"?"}
      </text>
    </svg>
  );
}
