import { useConfig } from "hooks/config";

/*
 *
  displayManyLevels: false,
  idleCardBounce: true,
  useEvents: false,
 */

const flagDescriptions = new Map([
  [
    "displayManyLevels",
    "Display the cards as if all levels reached 10. Used for debugging the cards display",
  ],
  ["idleCardBounce", "Activate the card bounce when the player is idle"],
  ["useEvents", "Play the game with events"],
  ["fewInfra", "Use a small number of services (restart required)"],
]);

const Flag = ({ flagKey, value, update }) => (
  <label>
    <input
      type="checkbox"
      checked={value}
      onChange={() => update(flagKey, !value)}
    />
    <span>
      {flagDescriptions.has(flagKey) ? flagDescriptions.get(flagKey) : flagKey}
    </span>
  </label>
);

const FeatureFlags = () => {
  const { config, setConfig } = useConfig();
  const update = (key, value) => {
    setConfig({ ...config, [key]: value });
  };
  const keys = Object.keys(config).filter((k) => k !== "version");

  return (
    <div className="flex flex-col h-full p-4">
      {keys.map((k) => (
        <Flag key={k} flagKey={k} value={config[k]} update={update} />
      ))}
    </div>
  );
};

export default FeatureFlags;
