let lastTweetId = 0;

export const makeTweet = (content, hint = null) => {
  const tweet = {
    ...content,
    id: ++lastTweetId,
  };
  if (hint) {
    tweet.hint = makeAnswerTweet(
      hint.message,
      tweet,
      hint.author || "@city-concierge"
    );
  }
  return tweet;
};

export const makeAnswerTweet = (
  message,
  originalTweet,
  author = "@newCityCorpCEO"
) =>
  makeTweet({
    type: "answer",
    actionable: false,
    author,
    message,
    originalTweet: Boolean(originalTweet) ? originalTweet.id : undefined,
  });
