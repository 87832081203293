import Link from "components/generic/link";

import { ReactComponent as PlayStore } from "assets/google-play.svg";
import { ReactComponent as AppStore } from "assets/Download_on_the_App_Store_Badge.svg";

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
export function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}

const CTAButton = ({ children, subtitle, ...props }) => (
  <Link
    {...props}
    className="text-2xl sm:text-3xl md:text-6xl px-8 py-4 bg-citerate-deep-purple flex flex-col justify-center items-center uppercase rounded-full shadow-xl mt-2"
  >
    <div className="-mb-1 md:-mb-2 text-white font-black tracking-wide md:tracking-widest">
      {children}
    </div>
    <div className="text-xs md:text-sm text-white mb-2 font-semibold tracking-wide">
      {subtitle}
    </div>
  </Link>
);

const AndroidLink = () => (
  <Link
    href="https://play.google.com/store/apps/details?id=llc.rients.citeratebuild"
    className="px-4 mt-4 md:mt-0"
  >
    <PlayStore className="h-8 md:h-10 text-citerate-sand-yellow" />
  </Link>
);
const IOSLink = () => (
  <Link
    href="https://apps.apple.com/us/app/citerate/id1534573293"
    className="px-4 mt-4 md:mt-0"
  >
    <AppStore className="h-8 md:h-10 text-citerate-sand-yellow rounded-md" />
  </Link>
);

const CTA = () => (
  <div className="flex flex-col flex-wrap content-center justify-center w-full uppercase max-w-lg m-auto mb-8">
    <CTAButton to="title" subtitle="in your browser">
      Play now
    </CTAButton>
    <div className="text-white text-sm text-center mt-4 block">
      or get the app from the stores:
    </div>
    <div className=" m-auto flex flex-col text-white md:flex-row content-center underline text-sm md:mt-4">
      <AndroidLink key="androidlink" /> <IOSLink key="ioslink" />
    </div>
  </div>
);

export { CTA };
