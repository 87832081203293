import { animated } from "react-spring";

export const ServiceCard = animated(({ service, category }) => (
  <div
    className="flex flex-col flex-none ml-2 border-b-8 shadow-sm"
    style={{
      height: "9rem",
      borderColor: category.accent,
      backgroundColor: category.color,
    }}
  >
    <p className="flex-grow w-32 h-10 my-1 text-sm font-semibold text-center">
      {service.label}
    </p>
    <img
      src={service.illustrationPath}
      alt={service.label}
      className="w-32 h-18"
      style={{}}
    />
  </div>
));

export const HiddenCard = animated(() => (
  <div
    className="flex flex-col flex-none ml-2 border-b-8"
    style={{
      height: "9rem",
      borderColor: "#aaa",
      backgroundColor: "#aaa",
    }}
  >
    <p className="flex-grow w-32 h-10 my-1 text-sm font-semibold text-center">
      {" "}
    </p>
    <div className="w-32 p-1 h-18" style={{}} />
  </div>
));
