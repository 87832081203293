function Logo(props) {
  return (
    <svg
      id="prefix__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 2768 616"
      xmlSpace="preserve"
      {...props}
    >
      <style>{".prefix__st3{fill:#fff}"}</style>
      <g id="prefix__Layer_1_1_">
        <path
          className="prefix__st3"
          d="M344.63 72.35C164.55 72.35 89 196.66 89 308.88c0 101.02 65.01 234.33 203.8 234.33 141.43 0 196.19-84.98 196.19-84.98l-54.46-130.01s-62.22 38.42-102.63 38.42-68.52-23.28-68.52-57.76c0-41.95 32.06-70.94 81.26-70.94s94 12.3 94 12.3V92.12c-.01 0-37.78-19.77-94.01-19.77zM660.78 526.65H525l.42-198.24H661.2zM2016 526.65h-133l10-310h115.2z"
        />
        <ellipse
          className="prefix__st3"
          cx={593.1}
          cy={235.22}
          rx={77.04}
          ry={65.51}
        />
        <path
          className="prefix__st3"
          d="M868 386.65c0 24 16 36 33 36s45-10 45-10v96s-17 35-99 35c-66 0-101-37-101-94V269.5c0-33.49 10-76.76 10-133.85h112v251z"
        />
        <path className="prefix__st3" d="M706 212.65h208v98H706z" />
        <path
          className="prefix__st3"
          d="M756 135.65c0 40-24 77-50 77l96 51 4-59-50-69zM2228 386.65c0 24 16 36 33 36s45-10 45-10v96s-17 35-99 35c-66 0-101-37-101-94V269.5c0-33.49 10-76.76 10-133.85h112v251z"
        />
        <path className="prefix__st3" d="M2066 212.65h208v98h-208z" />
        <path
          className="prefix__st3"
          d="M2116 135.65c0 40-24 77-50 77l96 51 4-59-50-69zM2521.87 201.82c-132.6 0-178.87 92.82-178.87 190.82 0 86 56.19 149.5 194.41 149.5 112.37 0 141.59-32.5 141.59-32.5v-99s-77.54 18-113.5 18c-52.82 0-83.16-16.15-83.16-58.15 0-49.85 6.66-101.85 39.33-101.85 30.32 0 41.58 38 41.58 76h-125.86l-5.62 38h236.83v-104c.01.01-16.37-76.82-146.73-76.82zM1169.87 201.82c-132.6 0-178.87 92.82-178.87 190.82 0 86 56.19 149.5 194.41 149.5 112.37 0 141.59-32.5 141.59-32.5v-99s-77.54 18-113.5 18c-52.82 0-83.16-16.15-83.16-58.15 0-49.85 6.66-101.85 39.33-101.85 30.32 0 41.58 38 41.58 76h-125.86l-5.62 38h236.83v-104c.01.01-16.37-76.82-146.73-76.82z"
        />
        <g>
          <path
            className="prefix__st3"
            d="M1526.78 526.65H1391l.42-310H1512l-7 66 22.09 16.11z"
          />
          <path
            className="prefix__st3"
            d="M1481 363.65c0-59 40-157 107-157 54 0 68 29 68 29v91s-149-16-149 41l-26-4z"
          />
        </g>
        <path
          className="prefix__st3"
          d="M1990 216.65c-53 0-79.5-11-142-11-104 0-177 68.75-177 166.75s50 169.75 122 169.75 128-125.5 128-262.5l-30-24c0 94-25 159-53 159s-38-25-38-61c0-47 18-81.5 68-81.5 26 0 60 25.5 60 25.5l38.5-44.5 23.5-36.5z"
        />
        <path
          className="prefix__st3"
          d="M1856 452.65h77v13h-77zM1468 260.65h77v13h-77z"
        />
      </g>
    </svg>
  )
}

export default Logo
