import Layout from "components/layout";
import DebugModal from "components/modals/debug";
import GameOver from "components/modals/gameover";
import GoalReachedModal from "components/modals/goal";
import Intro from "components/modals/intro";
import PickACityName from "components/modals/pickname";
import StatsModal from "components/modals/stats";
import Win from "components/modals/win";
import { UpdatePopup } from "components/popups/update";
import { useAutosave } from "hooks/autosave";
import { useConfig } from "hooks/config";
import useGameState from "hooks/gamestate";
import useGameUIState from "hooks/gameUIState";
import { useMultiTapHandler } from "hooks/multitap";
import useBoundingRect from "hooks/useBoundingRect";
import { useEffect, useRef } from "react";
import { canReview, displayReviewPrompt } from "services/review";
import { MainGameScreen } from "./main";

const Game = ({ workerState }) => {
  const { lastGame, saveGame } = useAutosave();
  const { config } = useConfig();
  const containerRef = useRef(undefined);
  const height = useBoundingRect(containerRef);

  const {
    left,
    right,
    quit,
    state: gamestate,
    history,
    reset,
    isWin,
    setCityName,
  } = useGameState(lastGame && lastGame.state);

  const tweet = gamestate.tweet;

  // UI state
  const {
    state: gameUIState,

    setChosenAction,
    toggleDebug,
    toggleStatsModal,
    toggleReachGoalModal,
    hideNewTurn,
    hideIntro,
    hideNewLevel,
    nextTweet,
    quitGame,
    selectCityName,

    tweetQueue,
  } = useGameUIState({
    gamestate,
    history,
    left,
    right,
    quit,
    config,
    setCityName,
    previousUIState: lastGame && lastGame.uiState,
    reset,
  });

  useEffect(() => {
    if (gamestate.tick > 2 && !gamestate.isWin && !gamestate.isGameOver) {
      saveGame(gamestate, gameUIState);
    }

    if (gamestate.turn === 6 && canReview()) {
      displayReviewPrompt();
    }
  });

  useMultiTapHandler({
    ref: containerRef,
    action: toggleDebug,
    tapNumber: 7,
  });

  return (
    <Layout gamestate={gamestate} ref={containerRef}>
      <PickACityName
        show={gameUIState.showPickCityName}
        selectCityName={selectCityName}
        gamestate={gamestate}
      />

      <Intro
        show={gameUIState.showIntroScreen}
        onConfirm={hideIntro}
        gamestate={gamestate}
      />

      {!gameUIState.showIntroScreen && !gameUIState.showPickCityName && (
        <MainGameScreen
          gameUIState={gameUIState}
          gamestate={gamestate}
          history={history}
          hideNewLevel={hideNewLevel}
          hideNewTurn={hideNewTurn}
          setChosenAction={setChosenAction}
          toggleStatsModal={toggleStatsModal}
          tweet={tweet}
          nextTweet={nextTweet}
          height={height}
        />
      )}

      <DebugModal
        show={gameUIState.displayDebug}
        toggleDebug={toggleDebug}
        gamestate={gamestate}
        gameUIState={gameUIState}
        tweetQueue={tweetQueue}
      ></DebugModal>

      <StatsModal
        show={gameUIState.showStatsModal}
        toggleStatsModal={toggleStatsModal}
        gameUIState={gameUIState}
        gamestate={gamestate}
        history={history}
        quitGame={quitGame}
      />

      <GoalReachedModal
        gamestate={gamestate}
        show={gameUIState.showReachGoalModal}
        hideScreen={toggleReachGoalModal}
      />

      <GameOver
        show={gameUIState.showGameOverScreen}
        reset={reset}
        gamestate={gamestate}
      />

      <Win reset={reset} show={isWin} gamestate={gamestate} history={history} />

      <UpdatePopup {...workerState} />
    </Layout>
  );
};

export default Game;
