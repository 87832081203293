export function Dollar1(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300" {...props}>
      <g id="prefix__Layer_2">
        <path
          transform="rotate(-2.431 150.068 150.13)"
          fill="#609762"
          d="M19.8 85.4h260.41v129.21H19.8z"
        />
        <circle cx={150} cy={150} r={42.8} fill="#b8cea7" />
      </g>
    </svg>
  );
}

export function Dollar2(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300" {...props}>
      <g id="prefix__Layer_2">
        <path
          transform="rotate(5.209 149.967 149.964)"
          fill="#95bf65"
          d="M19.8 85.4h260.41v129.21H19.8z"
        />
        <circle cx={150} cy={150} r={42.8} fill="#b8cea7" />
      </g>
    </svg>
  );
}
