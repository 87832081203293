const locale = "en-US";
const currencyFormat = new Intl.NumberFormat(locale, {
  style: "currency",
  currency: "USD",
});

export const formatMoney = (amount) => currencyFormat.format(amount);

const percentageFormat = new Intl.NumberFormat(locale, {
  style: "percent",
  maximumFractionDigits: 1,
});
export const formatPercent = (rating) => percentageFormat.format(rating);

const integerFormat = new Intl.NumberFormat(locale, {
  style: "decimal",
  maximumFractionDigits: 0,
});
export const formatInteger = (nb) => integerFormat.format(nb);

// source of abbreviate: https://gist.github.com/tobyjsullivan/96d37ca0216adee20fa95fe1c3eb56ac
const suffixes = [
  "",
  "K",
  "M",
  "B",
  "t",
  "q",
  "Q",
  "s",
  "S",
  "o",
  "n",
  "d",
  "U",
];

export const abbreviateMoney = (value, precision) => {
  return `$${abbreviate(value, precision)}`;
};

export const abbreviate = (value, precision = 3) => {
  if (!Number.isFinite(value)) return "∞";
  if (value === 0) return "0";

  let newValue = value < 0 ? -value : value;
  let suffixNum = 0;
  while (newValue >= 1000) {
    newValue /= 1000;
    suffixNum++;
  }

  if (suffixNum >= suffixes.length) return " ∞";

  if (precision === 0) {
    return `${value < 0 ? "-" : ""}${Math.ceil(newValue)}${
      suffixes[suffixNum]
    }`;
  }

  return `${value < 0 ? "-" : ""}${newValue.toPrecision(precision)}${
    suffixes[suffixNum]
  }`;
};
