import classnames from "classnames";
import BaseButton from "./base";

const SecondaryButton = ({
  className,
  children,
  isTitleScreen = false,
  ...otherProps
}) => (
  <BaseButton
    className={classnames(
      "focus:outline-none focus:shadow-outline mx-auto text-left px-4 py-2 w-full font-citerate font-semibold text-lg border-b-2",
      {
        "bg-transparent text-white border-white": isTitleScreen,
        "bg-transparent text-citerate-deep-purple border-citerate-deep-purple": !isTitleScreen,
      },
      className
    )}
    {...otherProps}
  >
    {children}
  </BaseButton>
);

export default SecondaryButton;
