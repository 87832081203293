import { getCurrentContractFromPopulation } from "config/contracts";
import { getMessage, getNewPlots } from "config/plots";
import { computePlotPriceWithContract } from "hooks/gamestate/formulas";
import { getCityServiceLevel } from "services/state";
import { makeTweet, makeAnswerTweet } from "./factory";

const publicURL = process.env.PUBLIC_URL;

const isPlotTweetNext = (gamestate) => {
  const occupiedPlots = gamestate.population / 2;
  const getLucky = Math.random() > 0.8;
  return (
    gamestate.tweet &&
    (gamestate.tweet.type === "infra" ||
      gamestate.tweet.type === "intro" ||
      gamestate.tweet.type === "event" ||
      gamestate.tweet.type === "stocks") &&
    (occupiedPlots >= 0.8 * gamestate.plotNumber || getLucky)
  );
};

const getPlotSaleTweet = (gamestate) => {
  const serviceLevel = 1 + getCityServiceLevel(gamestate);
  const cardVersion = Math.random() < 0.5 ? "" : "-B";
  const cardLevel = Math.min(serviceLevel, 5);

  const contract = getCurrentContractFromPopulation(gamestate);

  const newPlots = getNewPlots(gamestate);

  let hint = null;
  if (gamestate.plotNumber === 0)
    hint = {
      message:
        "Let's make sure we have available plots so that people can join the city",
    };
  else if (
    gamestate.tick < 10 &&
    gamestate.population / (2 * gamestate.plotNumber) > 0.8
  )
    hint = {
      message: "The city looks like it could need some more plots",
    };
  else if (
    gamestate.tick < 30 &&
    gamestate.population / (2 * gamestate.plotNumber) <= 0.2
  )
    hint = {
      message:
        "I'm not sure it would be wise to add more empty space to this city",
    };

  return makeTweet(
    {
      type: "plot",
      author: "@realEstateDaily",
      message: getMessage(gamestate),
      impact: {
        newPlots,
        cost: 0,
        contract: {
          ...contract,
          currentMarketPrice: computePlotPriceWithContract(gamestate, contract),
        },
      },
      illustrationPath: `${publicURL}/other-cards/Expand_0${cardLevel}${cardVersion}.png`,
    },
    hint
  );
};

const getPlotAnswer = (action, tweet) => {
  const message =
    action === "right"
      ? "Let's expand the city!"
      : "The city is big enough for the moment.";
  return makeAnswerTweet(message, tweet);
};

export { isPlotTweetNext, getPlotSaleTweet, getPlotAnswer };
