const processJSON = (data) => [JSON.stringify(data, null, 2)];

export const JSONPretty = ({ data }) => (
  <div className="h-full text-sm font-mono bg-gray-800">
    <textarea
      className="w-full h-full bg-transparent outline-none text-gray-100"
      value={processJSON(data)}
    ></textarea>
  </div>
);
