import { makeAnswerTweet, makeTweet } from "./factory";

const publicURL = process.env.PUBLIC_URL;

const introTweets = [
  {
    type: "intro",
    author: "@landOwner1",
    message:
      "We are getting rid of our farm. We have 2 hectares and it's just 2 hours from the airport. Only $2m!",
    actionHints: {
      left: "No deal",
      right: "Deal!",
    },
    cost: {
      money: 2000000,
      stocks: 0,
    },
    isLastIntroTweet: false,
    illustrationPath: `${publicURL}/other-cards/BuildHere_FirstOption.png`,
  },
  {
    type: "intro",
    author: "@landOwner2",
    message:
      "Hey! We are also selling. It's a property that our family has owned for generations. We'd love to partner on something that would benefit all. We're flexible on the terms ;-)",
    actionHints: {
      left: "No deal",
      right: "Deal!",
    },
    cost: {
      money: 0,
      stocks: 2000,
    },
    isLastIntroTweet: true,
    illustrationPath: `${publicURL}/other-cards/BuildHere_SecondOption.png`,
  },
];

const introAnswers = [
  {
    left: [
      "Thanks for the offer. I'm not interested at this price",
      "No problem! Good luck!",
    ],
    right: [
      "Thanks! Looks like a good offer!",
      "Hu kiddo, no cash no land. Bye!",
    ],
  },
  {
    left: [
      "We cannot afford that.",
      "Sure... Come back if you change you're mind.",
    ],
    right: [
      "Thanks! What about 20% stake in the city?",
      "Deal! Happy to do business with you, partner!",
    ],
  },
];

export const getIntroTweet = (gamestate) =>
  gamestate.tick >= introTweets.length
    ? undefined
    : makeTweet(introTweets[gamestate.tick]);

export const getIntroTweetAnswer = (action, originalTweet, gamestate) => {
  if (originalTweet.type !== "intro") return undefined;

  return [
    makeAnswerTweet(introAnswers[gamestate.tick][action][0], originalTweet),
    makeAnswerTweet(
      introAnswers[gamestate.tick][action][1],
      originalTweet,
      originalTweet.author
    ),
  ];
};
