import { useReducer, useRef, useEffect } from "react";
import { getAnswer, getGeneralHint } from "services/tweetGenerator";

import goalsConfig from "config/goals";

import { logEvent } from "services/amplitude";

import { saveScore } from "services/highscores";
import { getCityServiceLevel, getPreviousState } from "services/state";

const dispatchOnlyActionType = (dispatch, actionType) => () =>
  dispatch({ type: actionType });

const reducer = (state, action) => {
  switch (action.type) {
    case "gameDone":
      return {
        ...state,
        isGameDone: true,
      };

    case "showGameOverScreen": {
      return {
        ...state,
        showGameOverScreen: true,
        showStatsModal: false,
        showReachGoalModal: false,
      };
    }

    case "newGame":
      const gamestate = action.gamestate;
      return initUIState({ gamestate });

    case "newLevel":
      return {
        ...state,
        turn: action.turn,
        displayNewLevel: true,
      };

    case "hideNewLevel":
      return {
        ...state,
        displayNewLevel: false,
        displayNewTurn: true,
        displayTweet: true,
      };

    case "newTurn":
      return {
        ...state,
        turn: action.turn,
        displayNewTurn: true,
        displayTweet: true,
        showCard: false,
      };

    case "newTick":
      return {
        ...state,
        tick: action.tick,
        displayTweet: true,
      };

    case "hideNewTurn":
      return {
        ...state,
        displayNewTurn: false,
        showReachGoalModal: action.goalReached,
        showCard: !action.goalReached,
      };

    case "choseAction":
      const { chosenAction } = action;
      return {
        ...state,
        chosenAction,
        displayAnswer: true,
      };

    case "answerDisplayed":
      return {
        ...state,
        displayTweet: false,
        displayAnswer: false,
      };

    case "commitAction":
      return {
        ...state,
        chosenAction: null,
      };

    case "toggleDebug":
      return {
        ...state,
        displayDebug: !state.displayDebug,
      };

    case "toggleReachGoalModal":
      return {
        ...state,
        showReachGoalModal: !state.showReachGoalModal,
        reachGoalShown: true,
        showCard: true,
      };

    case "toggleStatsModal":
      const { screen } = action;
      return {
        ...state,
        showStatsModal: Boolean(screen),
        statsScreen: screen,
      };

    case "hideIntro":
      return {
        ...state,
        displayTweet: false,
        displayNewTurn: false,
        showIntroScreen: false,
        showPickCityName: true,
      };

    case "hidePickName":
      return {
        ...state,
        displayTweet: true,
        displayNewTurn: true,
        showIntroScreen: false,
        showPickCityName: false,
      };

    case "newTweet":
      const { tweet } = action;
      // Answers should be inserted underneath the original tweet
      if (tweet.type === "answer") {
        const tweets = [...state.tweets];
        const originalTweetIndex = state.tweets.findIndex(
          (t) => t.id === tweet.originalTweet
        );
        let otherAnswersCount = 0;
        while (
          state.tweets[originalTweetIndex + otherAnswersCount + 1] &&
          state.tweets[originalTweetIndex + otherAnswersCount + 1].type ===
            "answer"
        ) {
          otherAnswersCount++;
        }
        const answerIndex = originalTweetIndex + otherAnswersCount + 1;
        tweets.splice(answerIndex, 0, tweet);
        return {
          ...state,
          tweets,
        };
      } else {
        return {
          ...state,
          tweets: [tweet, ...state.tweets],
        };
      }

    default:
      return state;
  }
};

const initUIState = ({
  gamestate,
  previousUIState = {},
  firstGame = false,
}) => {
  return {
    // General UI elements (modals)
    // Flag for debug display
    displayDebug: false,

    // action the user has chosen, null if no
    // action yet. Values: null, "left", "right"
    chosenAction: null,

    turn: gamestate.turn,
    tick: gamestate.tick,

    // flags to display the citizen tweet
    displayTweet: false,
    displayAnswer: false,

    // Flag for the new screen
    displayNewTurn: false,

    isGameDone: false,

    showCard: false,

    showIntroScreen: firstGame ? true : false,
    showPickCityName: firstGame ? false : true,
    showGameOverScreen: false,
    showStatsModal: false,
    statsScreen: undefined,
    showReachGoalModal: false,
    reachGoalShown: false,

    tweets: [gamestate.tweet],

    ...previousUIState,
  };
};

function useGameUIState({
  gamestate,
  history,
  left,
  right,
  setCityName,
  quit: quitFromState,
  config,
  previousUIState,
  reset,
}) {
  const tweet = gamestate.tweet;

  const tweetQueue = useRef([]);
  const isDisplayingTweet = useRef(false);

  const [state, dispatch] = useReducer(
    reducer,
    { gamestate, previousUIState, firstGame: true },
    initUIState
  );

  const consumeTweetQueue = () => {
    if (tweetQueue.current.length > 0 && !isDisplayingTweet.current) {
      isDisplayingTweet.current = true;
      const tweetFromQueue = tweetQueue.current.shift();
      if (state.tweets.find((t) => t.id === tweetFromQueue.id)) {
        isDisplayingTweet.current = false;
      } else {
        setTimeout(
          () =>
            dispatch({
              type: "newTweet",
              tweet: tweetFromQueue,
            }),
          60
        );
      }
    } else if (tweetQueue.current.length === 0) {
      isDisplayingTweet.current = false;
    }
  };

  const nextTweet = () => {
    isDisplayingTweet.current = false;
    consumeTweetQueue();
  };

  const addTweet = (tweet) => {
    if (!tweet) return;

    if (!tweetQueue.current.find((t) => t.id === tweet.id)) {
      tweetQueue.current.push(tweet);
    }
  };

  // We wrap this code in the useEffect to prevent double calls (especially important for amplitude)
  useEffect(() => {
    if (state.showIntroScreen) {
      logEvent("NEW_GAME");
    } else {
      // Is the game finished in the UI state?
      if (state.isGameDone) {
        // Is the game state reseted?
        if (!gamestate.isWin && !gamestate.isGameOver) {
          dispatch({
            type: "newGame",
            gamestate,
          });
        }
      } else {
        if (gamestate.isWin || gamestate.isGameOver) {
          logEvent("GAME_DONE", {
            tick: gamestate.tick,
            turn: gamestate.turn,
            isWin: gamestate.isWin,
            isGameOver: gamestate.isGameOver,
            gameOverStatus: gamestate.gameOverStatus,
          });

          saveScore(gamestate);

          dispatch({
            type: "gameDone",
          });
        } else if (gamestate.turn > state.turn) {
          const previousState = getPreviousState(gamestate, history);

          const previousCityServiceLevel = getCityServiceLevel(previousState);
          const currentCityServiceLevel = getCityServiceLevel(gamestate);

          if (previousCityServiceLevel !== currentCityServiceLevel) {
            logEvent("NEW_CITY_LEVEL", {
              cityLevel: currentCityServiceLevel,
            });
            dispatch({
              type: "newLevel",
              turn: gamestate.turn,
            });
          } else {
            // new turn
            logEvent("NEW_TURN", { turn: gamestate.turn });
            dispatch({
              type: "newTurn",
              turn: gamestate.turn,
            });
          }
        }

        if (gamestate.tick > state.tick) {
          dispatch({
            type: "newTick",
            tick: gamestate.tick,
          });

          const generalHintTweet = getGeneralHint(gamestate);
          if (generalHintTweet) addTweet(generalHintTweet);

          addTweet(gamestate.tweet);

          if (gamestate.tweet && gamestate.tweet.hint) {
            addTweet(gamestate.tweet.hint);
          }
        }
      }
    }
  }, [
    gamestate,
    history,
    state.isGameDone,
    state.tick,
    state.turn,
    state.showIntroScreen,
  ]);

  const setChosenAction = (chosenAction) => {
    dispatch({
      type: "choseAction",
      chosenAction,
    });

    const dialogue = getAnswer(tweet, chosenAction, gamestate);
    if (dialogue) dialogue.forEach(addTweet);

    if (!config.displayAdvisor) {
      setTimeout(() => {
        commitAction(chosenAction);
      }, 100);
    }
  };

  const commitAction = (forcedChosenAction) => {
    const chosenAction = forcedChosenAction || state.chosenAction;

    dispatch({
      type: "commitAction",
    });

    const isGameFinished = gamestate.isWin || gamestate.isGameOver;
    if (isGameFinished) {
      logEvent("GAMEOVER_SCREEN");
      // dispatch({
      //   type: "showGameOverScreen",
      // });
      setTimeout(() => void reset(), 0);
    } else {
      // only log meaningful action picked
      logEvent("ACTION_PICKED", { tick: gamestate.tick, tweet, chosenAction });

      setTimeout(() => {
        if (chosenAction === "right") right(tweet);
        if (chosenAction === "left") left(tweet);
      }, 0);
    }
  };

  const answerDisplayed = dispatchOnlyActionType(dispatch, "answerDisplayed");
  const toggleDebug = dispatchOnlyActionType(dispatch, "toggleDebug");
  const toggleReachGoalModal = dispatchOnlyActionType(
    dispatch,
    "toggleReachGoalModal"
  );
  const toggleStatsModal = (screen) => {
    dispatch({ type: "toggleStatsModal", screen });
  }; // dispatchOnlyActionType(dispatch, "toggleStatsModal");
  const hideNewTurn = () => {
    const goalReached =
      !state.reachGoalShown && gamestate.population >= goalsConfig.population;

    if (goalReached) {
      logEvent("GOAL_REACHED");
    }

    dispatch({
      type: "hideNewTurn",
      goalReached,
    });
  };
  const hideIntro = dispatchOnlyActionType(dispatch, "hideIntro");
  const hideNewLevel = dispatchOnlyActionType(dispatch, "hideNewLevel");

  const selectCityName = (cityName) => {
    logEvent("CITY_SELECTED");
    setCityName(cityName);
    dispatch({
      type: "hidePickName",
    });
  };

  useEffect(() => {
    consumeTweetQueue();
  });

  return {
    state,

    setChosenAction,
    commitAction,
    answerDisplayed,

    toggleDebug,
    toggleStatsModal,
    toggleReachGoalModal,

    hideNewTurn,
    hideIntro,
    hideNewLevel,

    selectCityName,
    addTweet,
    nextTweet,
    tweetQueue,
    quitGame: () => {
      quitFromState();
      dispatch({
        type: "showGameOverScreen",
      });
    },
  };
}

export default useGameUIState;
