import classnames from "classnames";

function CashIcon({ className, ...props }) {
  return (
    <svg
      viewBox="0 0 150 150"
      className={classnames("h-full", className)}
      {...props}
    >
      <style>{".prefix__st32{fill:#462e53}"}</style>
      <g id="prefix__Layer_2">
        <path
          className="prefix__st32"
          d="M113.75 35.74s-14.59-8.95-34.09-8.95c-26.79 0-46.88 11.62-46.88 32.06 0 33.82 61.51 21.58 61.51 34.1 0 4.37-6.34 6.58-16.53 6.58-19.54 0-35.06-11.86-35.06-11.86l-14.58 20.71s22.06 17.86 48.44 17.86c30.7 0 45.32-15.65 45.32-31.24 0-39.5-59.07-29.05-59.07-38.66 0-4.98 5.9-6.87 14.77-6.87 15.28 0 24.96 5.08 24.96 5.08l11.21-18.81z"
        />
        <path
          className="prefix__st32"
          d="M88.17 33.99H64.34l-1.2-17.89h26.23zM88.17 116.01H64.34l-1.2 17.89h26.23z"
        />
      </g>
    </svg>
  );
}

export default CashIcon;
