import classnames from "classnames";

export const Line = ({ label, children: value, labelClassName }) => (
  <div className="flex flex-row py-1">
    <div
      className={classnames("flex-grow capitalize font-normal", labelClassName)}
    >
      {label}
    </div>
    <div className="block h-full">{value}</div>
  </div>
);

export default Line;
