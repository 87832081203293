import { useEffect, useRef } from "react";
import { animated, config, useSprings, useTransition } from "react-spring";
import classnames from "classnames";
import Sad from "components/generic/sparkles/sad";

const toPercentString = (v) => `${v * 100}%`;

const minOpacity = -0.5;

const Sadness = ({ show, onRest = () => {} }) => {
  const nbFaces = 10;
  const nbFacesOn = useRef(show ? 0 : nbFaces);
  const [springs, set] = useSprings(nbFaces, (idx) => ({
    from: {
      top: toPercentString(0.05 + (((idx * 3.5) % nbFaces) * 0.9) / nbFaces),
      left: toPercentString(0.05 + (((idx * 4.5) % nbFaces) * 0.9) / nbFaces),
      opacity: 0,
    },
    to: {},
    immediate: false,
    config: { ...config.stiff, velocity: 0 },

    onRest: ({ opacity }) => {
      const currentNbFacesOn = nbFacesOn.current;
      if (opacity === minOpacity) nbFacesOn.current = currentNbFacesOn - 1;
      if (opacity === 1) nbFacesOn.current = currentNbFacesOn + 1;

      if (nbFacesOn.current === nbFaces) {
        onRest();
      }
    },
  }));

  useEffect(() => {
    set((idx) => ({
      opacity: show ? 1 : minOpacity,
      transform: show ? `scale(${1.8 + Math.random()})` : "scale(0.8)",
      delay: show ? idx * (10 + Math.random() * 30) : 0,
    }));
  }, [set, show, nbFaces]);

  const transition = useTransition(show, null, {
    from: {
      transform: `translate3d(0, -50px,0)`,
    },
    enter: {
      transform: `translate3d(0, 0px,0)`,
    },
    leave: {
      transform: `translate3d(0, 50px,0)`,
    },
  });

  return transition.map(
    ({ item, key, props }) =>
      item && (
        <animated.div
          className={classnames(
            "absolute top-0 left-0 w-full h-full pointer-events-none z-100 transition-opacity duration-400"
          )}
          key={key}
          style={props}
        >
          {springs.map((props, i) => (
            <animated.div
              key={`${i}-love`}
              className={classnames("absolute w-5 h-5", {})}
              style={props}
            >
              <Sad className="w-full h-full" />
            </animated.div>
          ))}
        </animated.div>
      )
  );
};

export default Sadness;
