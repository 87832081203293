import { useTransition, animated } from "react-spring";

const GameoverEffect = ({ show }) => {
  const transitions = useTransition(show, null, {
    from: { opacity: 0, display: "none" },
    enter: [{ display: "block" }, { opacity: 1 }],
    leave: [{ opacity: 0 }, { display: "none" }],
    config: {
      tension: 200,
      friction: 30,
      velocity: 10,
    },
  });

  return transitions.map(
    ({ key, item, props }) =>
      item && (
        <animated.div
          key={key}
          className="h-full w-full absolute top-0 left-0 z-40 pointer-events-none"
          style={{
            backgroundColor: props.opacity.interpolate(
              (o) =>
                `hsla(0, 0%, ${Math.abs(Math.sin(4 * o * Math.PI)) * 100}%, ${
                  o * 0.6
                })`
            ),
          }}
        ></animated.div>
      )
  );
};

export { GameoverEffect };
