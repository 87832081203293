export const scheduleCityReminder = (cityName, now = false) => {
  if (window.cordova && window.cordova.plugins.notification) {
    window.cordova.plugins.notification.local.clearAll();

    const notification = {
      title: "Citerate",
      text: `${cityName} is looking for you. Come back to guide the city!`,
      foreground: true,
    };

    if (now) window.cordova.plugins.notification.local.schedule(notification);
    else {
      window.cordova.plugins.notification.local.schedule({
        ...notification,
        trigger: { in: 1, unit: "week" },
      });
    }
  }
};
