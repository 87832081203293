import { useEffect, createContext, useContext, useState } from "react";

const installContextDefaultValue = {
  updateInstallContext: null,
  promptEvent: null,
};
export const InstallContext = createContext(installContextDefaultValue);

export const useInstallPrompt = () => {
  const ctx = useContext(InstallContext);
  if (ctx === null)
    return {
      isInstallable: false,
      promptInstall: () => {},
    };

  const { promptEvent, updateInstallContext } = ctx;
  const isInstallable = !!promptEvent && promptEvent.prompt;

  const promptInstall = () => {
    if (isInstallable) {
      promptEvent.prompt();
      updateInstallContext(null);
      return promptEvent.userChoice;
    } else {
      console.log("not ready");
      return Promise.reject();
    }
  };

  return { isInstallable, promptInstall };
};

export const InstallPromptProvider = (props) => {
  const [promptEvent, updateInstallContext] = useState();

  useEffect(() => {
    window &&
      window.addEventListener("beforeinstallprompt", (e) => {
        e.preventDefault();
        updateInstallContext(e);
      });
  }, []);

  return (
    <InstallContext.Provider
      {...props}
      value={{ promptEvent, updateInstallContext }}
    />
  );
};
