import plotsConfig from "./data/plots_level.json";
import plotMessages from "./data/plots_tweets.json";

import { getCityServiceLevel } from "services/state";

export const getNewPlots = (gamestate) => {
  const serviceLevel = 1 + getCityServiceLevel(gamestate);
  const config = plotsConfig.find((pConfig) => pConfig.level === serviceLevel);
  return config && config.new_plots ? config.new_plots : 50000;
};

const getCityCondition = (population, plotNumber) => {
  if (population === 0) return "new";
  const emptiness = (plotNumber - population / 2) / plotNumber;
  if (emptiness < 0.2) return "crowded";
  else if (emptiness > 0.8) return "empty";
  return "normal";
};

/**
 * @param {Array<T>} elements
 * @returns {T}
 */
const pickOne = (elements) =>
  elements[Math.floor(Math.random() * elements.length)];

export const getMessage = ({ population, cityName, plotNumber } = {}) => {
  const condition = getCityCondition(population, plotNumber);
  const messages = plotMessages.filter((pm) => pm.condition === condition);
  return pickOne(messages).message.replace("[cityName]", cityName);
};
