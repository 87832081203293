function SvgComponent(props) {
  return (
    <svg
      viewBox="0 0 300 300"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="bevel"
      strokeMiterlimit={1.5}
      {...props}
    >
      <path
        d="M150 42.33C90.54 42.33 42.33 90.54 42.33 150S90.53 257.67 150 257.67 257.67 209.46 257.67 150 209.46 42.33 150 42.33z"
        fill="#edc86e"
        fillRule="nonzero"
      />
      <path
        d="M98.5 205c37.107-17.356 71.324-16.279 103 1"
        fill="none"
        stroke="#462e53"
        strokeWidth={9}
      />
      <path
        d="M229.668 124.757a25.772 25.772 0 01-4.573 10.566c-8.503 11.59-24.8 14.094-36.391 5.59-11.302-8.29-13.933-23.981-6.172-35.513l47.136 19.357zM70.814 121.425l49.002-13.974c6.425 12.325 2.06 27.625-10.097 34.603-12.468 7.156-28.383 2.848-35.54-9.62-2.025-3.475-3.09-7.237-3.365-11.009z"
        fill="#462e53"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default SvgComponent;
