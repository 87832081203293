import Link from "components/generic/link";
import { ReactComponent as PlayNow } from "assets/PlayNow.svg";
import { ReactComponent as AppStore } from "assets/Download_on_the_App_Store_Badge.svg";
import { ReactComponent as PlayStore } from "assets/google-play.svg";
import classnames from "classnames";
import { animated, useTransition } from "react-spring";
import { getMobileOperatingSystem } from "./cta";

export const Banner = ({ show }) => {
  const transitions = useTransition(show, null, {
    from: { position: 1 },
    enter: { position: 0 },
    leave: { position: 1 },
  });
  const os = getMobileOperatingSystem();
  return transitions.map(
    ({ item, key, props }) =>
      item && (
        <animated.div
          className={classnames(
            "fixed block bottom-0 left-0 z-50 w-full h-12 my-auto bg-citerate-sand-yellow"
          )}
          key={key}
          style={{
            transform: props.position.interpolate(
              (p) => `translate3D(0, ${p * 100}%, 0)`
            ),
          }}
        >
          <div className="flex flex-row items-center h-full pl-4 text-sm font-bold sm:text-lg max-w-2xl m-auto">
            <div className="flex-grow font-semibold underline">
              <Link to="title" className="">
                Start your first city
              </Link>
            </div>

            <div className="flex flex-row mr-2 justify-end">
              <Link to="title" className="mr-2">
                <PlayNow className="h-8" />
              </Link>
              {os === "iOS" && (
                <a
                  href="https://apps.apple.com/us/app/citerate/id1534573293"
                  rel="noopener noreferrer"
                  target="_blank"
                  className=""
                >
                  <AppStore className="h-8" />
                </a>
              )}
              {os === "Android" && (
                <a
                  href="https://play.google.com/store/apps/details?id=llc.rients.citeratebuild"
                  rel="noopener noreferrer"
                  target="_blank"
                  className=""
                >
                  <PlayStore className="h-8" />
                </a>
              )}
            </div>
          </div>
        </animated.div>
      )
  );
};
