import goalsConfig from "config/goals";
import { areAllServicesImplemented } from "config/services/tree";
import { getCityServiceLevelFromServices } from "services/state";

export const computeNextState = (state, actionImpact) => {
  const { plotPrice, soldPlot, contractLevel } = actionImpact;

  // Total number of plots in the city – grows as an action of the player
  const plotNumber = state.plotNumber + actionImpact.newPlots;

  // For each plot we have a family of two people
  const population = state.population + soldPlot * 2; // a plot has two people in it
  const growthRate =
    population === state.population
      ? 0
      : (population - state.population) / state.population;
  const plotSales = soldPlot * plotPrice; // Maybe sell at the previous price instead?

  // Stock selling
  const soldStock = actionImpact.soldStock || 0;
  const stocks = Math.max(state.stocks - soldStock, 0);
  const soldStocksIncome = actionImpact.price;

  // Fair market value ish
  const unitStockPrice =
    state.population === 0
      ? state.unitStockPrice
      : (plotNumber * plotPrice * Math.pow(1 + growthRate, 5)) / 10000;

  // 10% yearly tax
  // divided by four because a turn is a quarter
  const taxIncome = actionImpact.taxIncome;

  // const vesting = state.vesting + 1 / 25;
  // const netWorth = 0.1 * plotPrice * plotNumber * vesting; // TODO hardcoded 10% ownership

  const { investment, maintenance } = actionImpact;
  const revenue = taxIncome + plotSales + soldStocksIncome;

  const profit = revenue - investment - maintenance;

  const bankBalance = state.bankBalance + profit;

  const turn = state.turn + actionImpact.turnDelta;

  // Store current event
  const events = actionImpact.event
    ? [...state.events, actionImpact.event]
    : state.events;

  // Store new service
  const availableServices = actionImpact.newService
    ? [...state.availableServices, actionImpact.newService]
    : state.availableServices;

  // If the city grows to a new level --> happiness bonus
  const hasCityReachedNewLevel =
    getCityServiceLevelFromServices(availableServices) !==
    getCityServiceLevelFromServices(state.availableServices);
  const happinessBonusFromLevel = hasCityReachedNewLevel ? 10 : 0;

  const happiness = Math.max(
    goalsConfig.minHappiness,
    Math.min(
      state.happiness + actionImpact.deltaHappiness + happinessBonusFromLevel,
      100
    )
  );

  const gameOverStatus = {
    money: bankBalance < goalsConfig.minBankBalance,
    notOwner: stocks === 0,
    time: turn === goalsConfig.turns && population < goalsConfig.population,
    happiness: happiness <= goalsConfig.minHappiness,
    noPlot: actionImpact.isLastIntroTweet && !actionImpact.land,
    noGrowth: actionImpact.isEndOfQuarter && growthRate <= 0,
    quit: false,
  };

  const isGameOver = Object.keys(gameOverStatus).some((k) => gameOverStatus[k]);

  const winStatus = {
    populationReached:
      population > goalsConfig.population && turn === goalsConfig.turns,
    allServices: areAllServicesImplemented(availableServices),
  };

  const isWin = Object.keys(winStatus).some((k) => winStatus[k]);

  return {
    ...state,
    contractLevel,
    bankBalance,
    plotNumber,

    decisionsStreaks: actionImpact.decisionsStreaks,
    stocks,
    unitStockPrice,
    soldStock,

    happiness,
    population,
    plotPrice,
    plotSales,
    taxIncome,
    maintenance,
    investment,
    netWorth: state.netWorth,

    services: actionImpact.services,
    availableServices,
    // Game does not advance if it's any end condition
    turn: isGameOver || isWin ? state.turn : turn,
    gameOverStatus,
    isGameOver,
    isWin,
    events,
  };
};
