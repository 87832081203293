import { useState, useRef, useMemo } from "react";
import { useSwipeable } from "react-swipeable";
import classnames from "classnames";
import { getAnswer } from "services/tweetGenerator";

import {
  useSpring,
  animated,
  config as springConfig,
  interpolate,
} from "react-spring";
import TweetCard from "components/tweet-card";
import { DesktopButtons } from "./desktop-buttons";

const rotate = (swipeAngle, inverse = false) =>
  `rotate(${((inverse ? -1 : 1) * swipeAngle) / 15}deg)`;

const thresholds = [-60, 60];

const Card = ({
  left,
  right,
  gamestate,
  actionHints,
  children,
  height,
  helpContent,
}) => {
  const tweet = gamestate.tweet;

  const [uiState, setUistate] = useState({
    isTapped: false,
    isSwiping: false,
    angle: 0,
  });

  const action = useRef(undefined);

  const cardProps = useSpring({
    from: {
      a: 0,
      swipe: 0,
      flipFront: 0,
    },
    config: springConfig.stiff,
    to: {
      a: uiState.isSwiping || action.current ? uiState.angle : 0,
      swipe: uiState.isSwiping || action.current ? 1 : 0,
      flipFront: uiState.isTapped ? 1 : 0,
    },
  });

  const interpolatedTransform = interpolate(
    [cardProps.a, cardProps.swipe, cardProps.flipFront],
    (angle, swipe, flipFront) => {
      return `
         rotateY(${flipFront * 180}deg)
         rotateZ(${angle / 15}deg)
         translateY(${swipe}px)
         scale(${swipe * 0.04 + 1.0})`;
    }
  );

  const interpolatedShadow = cardProps.swipe.interpolate(
    (swipe) =>
      `0px 0px ${4 + swipe * 15}px 0px rgba(0,0,0,${0.2 + swipe * 0.3})`
  );

  const interpolatedZIndex = cardProps.swipe.interpolate(
    (swipe) => `${swipe > 0 ? 100 : 0}`
  );

  const pick = (actionKey) => {
    action.current = actionKey;
    setUistate({
      isTapped: false,
      isSwiping: false,
      angle: actionKey === "left" ? -300 : 300,
    });
  };
  const handlers = useSwipeable({
    trackMouse: true,
    delta: 0,
    onSwiping: ({ deltaX, first }) => {
      if (action.current || first || uiState.isTapped) return;
      setUistate({
        isTapped: false,
        isSwiping: true,
        angle: deltaX,
      });
    },
    onSwiped: ({ deltaX }) => {
      if (action.current || uiState.isTapped) return;
      if (deltaX < thresholds[0]) {
        pick("left");
      } else if (deltaX > thresholds[1]) {
        pick("right");
      } else {
        setUistate({
          isTapped: false,
          isSwiping: false,
          angle: 0,
        });
      }
    },
  });

  const isPastRight = uiState.angle > thresholds[1];
  const isPastLeft = uiState.angle < thresholds[0];
  const [actionLabel, previewTweet] = useMemo(() => {
    const currentActionPreview = isPastRight
      ? "right"
      : isPastLeft
      ? "left"
      : undefined;
    if (currentActionPreview) {
      const answers = getAnswer(tweet, currentActionPreview, gamestate);
      return [
        actionHints[currentActionPreview],
        answers ? answers[0] : undefined,
      ];
    }

    if (action.current && action.current !== "DONE")
      return ["", getAnswer(tweet, action.current, gamestate)[0]];
    else return [];
  }, [isPastRight, isPastLeft, actionHints, tweet, gamestate]);

  const tweetPreviewProps = useSpring({
    x: previewTweet ? 1 + (action.current ? 2 : 0) : 0,
  });

  const interpolatedHintPosition = cardProps.a.interpolate(
    (a) =>
      `${rotate(a, true)} translateY(${
        actionLabel === undefined ? 0 : Math.min(80, Math.abs(a))
      }px)`
  );

  const cardAppearance = useSpring({
    from: !action.current
      ? { opacity: 0, position: "-100%" }
      : { opacity: 1, position: "0%" },
    to: !action.current
      ? { opacity: 1, position: "0%" }
      : { opacity: 0, position: "0%" },
    onRest: () => {
      if (action.current) {
        if (action.current === "left") left(tweet);
        if (action.current === "right") right(tweet);
        action.current = "DONE";
      }
    },
  });

  const isFirstCard = gamestate.tick === 0;

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        helpContent &&
          setUistate({ ...uiState, angle: 0, isTapped: !uiState.isTapped });
      }}
      {...handlers}
      className={classnames("flex items-end h-full", {})}
    >
      {isFirstCard && (
        <div
          className="absolute z-50 w-full h-64"
          style={{ bottom: "100%" }}
        ></div>
      )}

      <DesktopButtons
        actionHints={actionHints}
        left={() => pick("left")}
        right={() => pick("right")}
        currentAction={action.current}
      />

      <div
        className="relative z-50 mx-auto mt-auto text-xl font-extrabold tracking-wide pointer-events-none"
        style={{
          width: `${(height * 0.95) / 1.395}px`,
          height: `${height * 0.95}px`,
        }}
      >
        <animated.div
          className="w-full h-full"
          style={{
            opacity: cardAppearance.opacity,
            transform: cardAppearance.position.interpolate(
              (v) => `translateY(${v})`
            ),
          }}
        >
          {/* Card back */}
          <animated.div
            style={{
              WebkitBackfaceVisibility: "hidden",
              backfaceVisibility: "hidden",
              boxShadow: interpolatedShadow,
              transform: cardProps.flipFront.interpolate(
                (f) => `rotateY(${(1 - f) * 180}deg)`
              ),
            }}
            className="absolute top-0 left-0 h-full w-full bg-white"
          >
            {helpContent}
          </animated.div>
          {/* Card front */}
          <animated.div
            style={{
              WebkitBackfaceVisibility: "hidden",
              backfaceVisibility: "hidden",
              transform: interpolatedTransform,
              boxShadow: interpolatedShadow,
              zIndex: interpolatedZIndex,
              transformOrigin: "50% 400%",
              willChange: "transform box-shadow",
            }}
            className={classnames(
              "rounded-sm mx-auto h-full w-full flex justify-center items-center uppercase tracking-wide xs:text-xl text-lg tracking-tighter font-extrabold relative overflow-hidden "
            )}
          >
            <div className="w-full h-full">
              {/* Hint */}
              <animated.div
                className="absolute left-0 w-full font-mono align-bottom"
                style={{
                  top: "-100px",
                  height: "70px",
                  willChange: "transform",
                  transform: interpolatedHintPosition,
                }}
              >
                <div
                  className="absolute bottom-0 h-full m-auto bg-gray-400"
                  style={{
                    height: "300px",
                    left: "-300px",
                    width: "1000px",
                  }}
                ></div>
                <p
                  className={classnames(
                    "absolute bottom-0 w-full text-gray-800 tracking-tighter",
                    {
                      "text-right": uiState.angle < 0,
                    }
                  )}
                >
                  {actionLabel}
                </p>
              </animated.div>

              {/* Hidden top layer to prevent long click / "menu clicking"*/}
              <div className="absolute top-0 left-0 w-full h-full bg-transparent"></div>

              <div
                className="flex flex-col items-center justify-center w-full h-full "
                id="card-content"
              >
                {children}
              </div>
            </div>
          </animated.div>
        </animated.div>
      </div>

      {/* Tweet preview at the bottom of the screen */}
      <animated.div
        className="absolute bottom-0 w-full p-4 select-none"
        style={{
          transform: tweetPreviewProps.x.interpolate(
            (x) => `translateY(${(1 - x) * 40 + 60}%)`
          ),
          opacity: tweetPreviewProps.x.interpolate((x) =>
            x < 1 ? x : 1 - (x - 1) / 2
          ),
          zIndex: interpolatedZIndex,
        }}
      >
        {previewTweet && (
          <TweetCard tweet={{ ...previewTweet, type: "answerPreview" }} />
        )}
      </animated.div>
    </div>
  );
};

export default Card;
