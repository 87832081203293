// Array of contracts

// Contracts takes the gamestate and returns an impact
const contracts = [
  {
    level: 0,
    appeal: 0,
    ownership: 0,
    price: 0,
    minPopThreshold: 0,
  },
  {
    level: 1,
    appeal: 0.7,
    ownership: 0.01,
    price: 0,
    minPopThreshold: 50,
  },
  {
    level: 2,
    appeal: 0.4,
    ownership: 0,
    price: 0,
    minPopThreshold: 200,
  },
  {
    level: 3,
    appeal: 0.05,
    ownership: 0,
    price: "market",
    minPopThreshold: Infinity,
  },
];

const maxLevel = contracts.length - 1;

const getCurrentContractFromPopulation = (state) => {
  const { population } = state;

  // Find current contract
  return contracts.find((c) => {
    return population < c.minPopThreshold;
  });
};

const getCurrentContract = (state) => {
  const { contractLevel } = state;
  return getContractAtLevel(contractLevel);
};

const getContractAtLevel = (contractLevel) => {
  return contracts[Math.min(contractLevel, maxLevel)];
};

/**
 * Returns a
 * @returns {number}
 */
const getCurrentThreshold = (gamestate) => {
  const { contractLevel, population } = gamestate;

  const contractId = contracts.findIndex((c, i) => {
    return population >= c.minPopThreshold && contractLevel === i;
  });

  return contractId === -1 ? undefined : contractId;
};

export {
  getCurrentContractFromPopulation,
  getCurrentContract,
  getCurrentThreshold,
  getContractAtLevel,
};
