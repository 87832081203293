import { StrictMode } from "react";
import ReactDOM from "react-dom";

import "./assets/main.css";

import App from "./App";

import { UIConfigProvider } from "hooks/config";
import { initAmplitude } from "services/amplitude";
import { InstallPromptProvider } from "hooks/pwaInstall";
import { ErrorHandling } from "ErrorHandling";

function isPhoneGap() {
  return (
    (window.cordova || window.PhoneGap || window.phonegap) &&
    /^file:\/{3}[^/]/i.test(window.location.href) &&
    /ios|iphone|ipod|ipad|android/i.test(navigator.userAgent)
  );
}

function isPWAContext() {
  // replace standalone with fullscreen or minimal-ui according to your manifest
  if (matchMedia("(display-mode: standalone)").matches) {
    // Android and iOS 11.3+
    return true;
  } else if ("standalone" in navigator) {
    // useful for iOS < 11.3
    return Boolean(navigator.standalone);
  }
  return false;
}

if (isPhoneGap()) {
  console.log("Running in a Cordova context");
  document.addEventListener(
    "deviceready",
    () => {
      window.open = window.cordova.InAppBrowser.open;
      initApp(true);
    },
    false
  );
  initAmplitude("app");
} else {
  console.log("Running in a web context");
  initAmplitude(isPWAContext() ? "pwa" : "web");
  initApp(isPWAContext());
}

window.addEventListener("error", (errorEvent) => {
  console.log("An error occured, restarting the app");
  initApp(isPhoneGap() || isPWAContext(), errorEvent);
});

function initApp(isAppContext, errorEvent) {
  ReactDOM.render(
    <StrictMode>
      {errorEvent ? (
        <ErrorHandling isAppContext={isAppContext} errorEvent={errorEvent} />
      ) : (
        <UIConfigProvider>
          <InstallPromptProvider>
            <App isAppContext={isAppContext} />
          </InstallPromptProvider>
        </UIConfigProvider>
      )}
    </StrictMode>,
    document.getElementById("root")
  );
}
