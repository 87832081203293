import GenericCard from "./generic-card";
import CardContent from "./generic-card/content";
import { HelpCardContent } from "./generic-card/help-content";

import { formatPercent, abbreviateMoney } from "services/format";

const IntroCard = ({ left, right, gamestate, height }) => {
  const tweet = gamestate.tweet;
  const { actionHints } = tweet;

  const helpContent = (
    <HelpCardContent title="Buying negocation">
      <p>With this card, you can buy the land for your city.</p>
      <p>What do you think of the price?</p>
    </HelpCardContent>
  );

  return (
    <GenericCard
      left={left}
      right={right}
      gamestate={gamestate}
      actionHints={actionHints}
      height={height}
      helpContent={helpContent}
    >
      <CardContent
        title="Build here?"
        illustrationPath={tweet.illustrationPath}
      >
        Payment: {abbreviateMoney(tweet.cost.money)}
        {Boolean(tweet.cost.stocks) && (
          <>
            <br />
            Stocks: {formatPercent(tweet.cost.stocks / 10000)}
          </>
        )}
      </CardContent>
    </GenericCard>
  );
};

export default IntroCard;
