import { rainbowAccent, rainbowColor } from "components/generic/colors";
import { FoldableLine } from "components/generic/foldable-line";
import { Trophy } from "components/generic/trophies";
import { getCategoryById, getServiceById } from "config/services";
import { servicesPerLevel } from "config/services/tree";
import { HiddenCard, ServiceCard } from "./service-card";

const ServiceLevelCards = ({ services }) => {
  return services.map((service) => (
    <ServiceCard
      key={`service-${service.label}`}
      category={getCategoryById(service.category)}
      service={service}
    />
  ));
};

const ServiceLevelLine = ({ services, level }) => {
  const servicesInCurrentLevel = servicesPerLevel[level];
  const isComplete = services.length === servicesInCurrentLevel;
  const hiddenServices = [];
  if (!isComplete) {
    for (let i = servicesInCurrentLevel - services.length; i > 0; i--) {
      hiddenServices.push(<HiddenCard key={`hiddenServices${i}`} />);
    }
  }

  const label = (
    <div className="flex flex-row items-center w-full">
      <div className="flex-grow">Level {level + 1}</div>
      {isComplete && (
        <div className="flex flex-row items-center h-full ml-2 text-xs rounded-full bg-citerate-deep-purple">
          <p className="mx-2 text-white uppercase">complete</p>
          <Trophy level={level + 1} className="w-4 mr-2" />
        </div>
      )}
    </div>
  );

  return (
    <FoldableLine
      label={label}
      level={services.length}
      backgroundColor={rainbowAccent[level]}
      foldColor={rainbowColor[level]}
    >
      {(show) => {
        return (
          <>
            <ServiceLevelCards show={show} services={services} />
            {hiddenServices}
            <div className="block h-full pl-2"> </div>
          </>
        );
      }}
    </FoldableLine>
  );
};

export const ServiceLevels = ({ gamestate }) => {
  const services = gamestate.availableServices;
  const servicesByLevel = services.reduce((sbl, serviceId) => {
    const { level, ...details } = getServiceById(serviceId);
    if (sbl[level]) sbl[level].push(details);
    else sbl[level] = [details];
    return sbl;
  }, []);

  return servicesByLevel.map((services, level) => (
    <ServiceLevelLine
      key={`levelLine-${level}`}
      services={services}
      level={level}
    />
  ));
};
