import Layout from "components/layout";
import { ErrorPopup } from "components/popups/error";

export const ErrorHandling = ({ errorEvent, isAppContext }) => {
  return (
    <Layout className="bg-citerate-deep-green">
      <ErrorPopup errorEvent={errorEvent} isAppContext={isAppContext} />
    </Layout>
  );
};
