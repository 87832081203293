import { useState, useEffect } from "react";

import Modal from "components/generic/modal";
import { PrimaryButton } from "components/generic/buttons";

import Layout from "components/layout";
import Link from "components/generic/link";
import ModalHeader from "components/generic/modal-header";
import ModalContent from "components/generic/modal-content";

const CreditsScreen = () => {
  const [display, setDisplay] = useState(false);
  useEffect(() => {
    setTimeout(() => setDisplay(true), 0);
  });
  return (
    <Layout>
      <Modal show={display} fullHeight>
        <ModalHeader className="">About</ModalHeader>
        <ModalContent>
          <p className="mt-2 font-normal">
            This game is about building cities in the 21st century.
          </p>

          <p className="mt-2">
            Some economists think that if we were to allow anyone to move freely
            in the world we could{" "}
            <span className="font-normal">double the GDP of the planet</span>.
            With the latest trends, it's not going to happen soon.
          </p>

          <p className="mt-2">
            If the big cities are not accessible,{" "}
            <span className="font-normal">
              we need to create new big cities
            </span>
            . This is the goal of charter cities.
          </p>

          <p className="mt-2">
            In citerate,{" "}
            <span className="font-normal">
              you are an entrepreneur building one of those cities
            </span>
            . You use direct feedback from citizens and you iterate based on
            their needs. You do not follow a pre-made masterplan to achieve your
            goal of building the next big city.
          </p>

          <p className="mt-2 font-normal">
            To learn more about charter cities follow{" "}
            <Link href="https://twitter.com/2xgdp">@2xgdp</Link> and the{" "}
            <Link href="https://twitter.com/CCIdotCity">CCI</Link>.
          </p>
        </ModalContent>
        <div className="flex flex-col justify-around">
          <PrimaryButton to="../title" className="mt-4">
            Go to title screen
          </PrimaryButton>
        </div>
      </Modal>
    </Layout>
  );
};

export default CreditsScreen;
