import stateConfig from "config/state";
import { getCityOccupation, getCityServiceLevel } from "services/state";

import {
  getCurrentContract,
  getCurrentContractFromPopulation,
} from "config/contracts";
import { getServiceById } from "config/services";

export const computeMaintenance = (state) => {
  let costPerCitizen = state.availableServices
    .map((serviceId) => {
      const service = getServiceById(serviceId);
      return service.upkeep;
    })
    .reduce((sum, price) => sum + price, 0);
  return costPerCitizen * state.population;
};

export const computeRentPerPlot = () => 1000 * 0.25; // per quarter
export const computeTaxIncome = (state) =>
  state.population * 0.5 * computeRentPerPlot();

export const computeSoldPlot = (state, impact) => {
  const newPlots = impact && impact.newPlots ? impact.newPlots : 0;
  const availablePlots = state.plotNumber + newPlots - state.population / 2;
  if (availablePlots > 0) {
    const currentContract = getCurrentContractFromPopulation(state);
    const serviceLevel = getCityServiceLevel(state);

    const happyCitizens =
      state.population === 0
        ? 1
        : Math.round((state.population * state.happiness) / 100);
    const contacts = happyCitizens * 2;
    const convincedNewCitizens = Math.round(
      contacts * (currentContract.appeal * (serviceLevel + 1))
    );
    return Math.min(convincedNewCitizens, availablePlots);
  }

  return 0;
};

const plotPart = 0.1;
// Plot price evolves based on the happiness
export const computePlotPrice = (state) => {
  const contract = getCurrentContractFromPopulation(state);
  return computePlotPriceWithContract(state, contract);
};

export const computePlotPriceWithContract = (state, contract) =>
  contract.price === "market"
    ? Math.max(
        // we don't give money for plots, even when people are unhappy.And we have a hard limit for price
        stateConfig.minPlotPrice,
        // TODO 4 here feels bad. Square of citizens * happiness / plot sales percentage over a year
        ((Math.sqrt(state.population) * state.happiness) / (plotPart * 4)) * 0.3
      )
    : contract.price;

export const computeGivenStock = (state, soldPlot) => {
  const currentContract = getCurrentContract(state);
  const givenStock = Math.floor(
    soldPlot * currentContract.ownership * stateConfig.initialValues.stocks
  );
  return givenStock;
};

// Hapiness decrease when the city is expanding
export const computeHapinessImpactFromExpansion = (state) => {
  if (state.population === 0) return 0;
  const cityLevel = 1 + getCityServiceLevel(state);
  const percentOccupation = 0.5 + Math.abs(0.5 - getCityOccupation(state));
  return -2 * Math.pow(cityLevel, 1.5) * percentOccupation;
};
