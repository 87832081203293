import Line from "./Line";
import { formatInteger } from "services/format";
import Title from "./Title";

const ServicesView = ({ gamestate }) => {
  return (
    <>
      <Title>Housing</Title>
      <Line label="Population">{formatInteger(gamestate.population)}</Line>
      <Line label="Occupied plots">
        {formatInteger(gamestate.population / 2)}
      </Line>
      <Line label="Available plots">
        {formatInteger(gamestate.plotNumber - gamestate.population / 2)}
      </Line>
      <Line label="Total plots">{formatInteger(gamestate.plotNumber)}</Line>
      <Title>Plot buying conditions</Title>
      <Line label="Ownership">
        {gamestate.contractLevel === 1 ? "1% per plot" : "none"}
      </Line>
      <Line label="Price">
        {gamestate.contractLevel < 3 ? "No charge" : "Market price"}
      </Line>
    </>
  );
};

export default ServicesView;
