import { getAvailableEvents, isEventAvailable } from "config/events";
import { getCurrentEvent, getLastEvent } from "services/state";

const { makeTweet } = require("./factory");

export const isEventTweetNext = (gamestate) => {
  // events available at this level?
  if (!isEventAvailable(gamestate)) return false;

  const currentEvent = getCurrentEvent(gamestate);
  if (currentEvent) return false;

  const lastEvent = getLastEvent(gamestate);
  if (lastEvent) {
    const lastEventTurn = lastEvent.turn;
    const thresholdEvent =
      Math.max(0, gamestate.turn - lastEventTurn - 5) * 0.1;

    // roll the dice to see if we get a new event?
    return Math.random() < thresholdEvent;
  } else {
    //first event is triggered with 10% chance
    return true; // Math.random() < 0.1;
  }
};

export const getNextEventTweet = (gamestate) => {
  const availableEvents = getAvailableEvents(gamestate);

  if (availableEvents.length === 0) {
    console.log("error: there should be an event but there is none...");
    return null;
  }

  const event =
    availableEvents[Math.floor(availableEvents.length * Math.random())];

  return makeTweet({
    type: "event",
    actionable: true,
    ...event,
  });
};
