import classnames from "classnames";

const ModalHeader = ({ className, children, ...otherProps }) => (
  <h1
    className={classnames("mt-4 text-2xl font-black text-left ", className)}
    style={{ fontVariantCaps: "small-caps" }}
    {...otherProps}
  >
    {children}
  </h1>
);

export default ModalHeader;
