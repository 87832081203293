import { Popup } from "components/generic/popup";

export const UpdatePopup = ({
  /* serviceWorkerInitialized = false, */
  serviceWorkerUpdated = false,
  reload = null,
}) => (
  <Popup accept={reload} show={serviceWorkerUpdated} ctaLabel="Update now">
    <div className="flex-grow text-xl">A new version is here!</div>
  </Popup>
);
