import { useTransition, animated, config } from "react-spring";
import { PrimaryButton } from "./generic/buttons";
import { Trophy } from "./generic/trophies";

const Button = animated(PrimaryButton);

const LevelReached = ({ show, level, cityName, onValidated }) => {
  const transitions = useTransition(show, null, {
    from: { badge: 0, newCard: 0, message: 0, trophyNext: 0 },
    enter: [{ badge: 1 }, { trophyNext: 1 }, { message: 1, newCard: 1 }],
    leave: { badge: 0, newCard: 0, message: 0 },
    config: { ...config.default, velocity: 20 },
  });

  return transitions.map(
    ({ item, key, props }) =>
      item && (
        <animated.div
          key={key}
          className="absolute top-0 left-0 z-100 w-full h-full"
        >
          <animated.div
            className="absolute top-0 left-0 w-full h-full bg-citerate-deep-green"
            style={{
              opacity: props.badge.interpolate([0, 1], [0, 0.8]),
              transform: props.badge.interpolate(
                (v) => `scaleY(${v * 0.9}) scaleX(0.9)`
              ),
            }}
          />
          <animated.div
            className="absolute top-0 left-0 w-full h-full"
            style={{
              transform: props.badge.interpolate(
                (badgeValue) => `scale(${badgeValue})`
              ),
            }}
          >
            {level > 1 && (
              <animated.div
                className="absolute top-0 left-0 flex flex-col items-center justify-center w-full h-full"
                style={{
                  filter: props.trophyNext.interpolate(
                    (tn) => `brightness(${tn === 1 ? 1 : 1 + 2 * tn})`
                  ),
                }}
              >
                <Trophy level={level - 1} />
              </animated.div>
            )}
            <animated.div
              className="absolute top-0 left-0 flex flex-col items-center justify-center w-full h-full"
              style={{ opacity: props.trophyNext }}
            >
              <Trophy level={level} />
            </animated.div>
            <div className="absolute top-0 left-0 flex flex-col items-center justify-center w-full h-full opacity-50">
              <div className="w-48 h-48 text-2xl uppercase rounded-full bg-citerate-sand-yellow" />
            </div>
            <div className="absolute top-0 left-0 flex flex-col items-center justify-center w-full h-full text-2xl uppercase">
              <div className="text-3xl font-black text-center">{cityName}</div>
              <div className="text-lg font-normal">just reached</div>
              <div className="font-bold">level {level}</div>
            </div>
          </animated.div>
          <div className="absolute top-0 left-0 flex flex-col items-center justify-start w-full h-full px-8 opacity-75">
            <animated.div
              className="px-2 py-3 mt-16 text-xl font-semibold text-center uppercase text-citerate-subhover rounded-lg bg-citerate-deep-green"
              style={{
                opacity: props.newCard,
                transform: props.newCard.interpolate(
                  (v) => `translateY(${(1 - v) * 50}%) scale(${v})`
                ),
              }}
            >
              New services available!
            </animated.div>
          </div>
          <div className="absolute top-0 left-0 flex flex-col items-center justify-end w-full h-full px-8">
            <Button
              className="max-w-sm mb-20 text-xl font-semibold uppercase"
              style={{
                opacity: props.message,
                transform: props.message.interpolate(
                  (v) => `translateY(${v * 50}%)`
                ),
              }}
              onClick={onValidated}
            >
              Continue
            </Button>
          </div>
        </animated.div>
      )
  );
};

export default LevelReached;
