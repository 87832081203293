const REVIEW_STORE = "citerate_last_review_prompt";

export function canReview() {
  // Api available?
  const isAPIAvailable =
    window.cordova &&
    window.cordova.plugins &&
    window.cordova.plugins.AppReview;

  if (!isAPIAvailable) return false;

  // Read last date for review prompt
  const lastPrompt = parseFloat(window.localStorage.getItem(REVIEW_STORE) || 0);
  const now = Date.now();
  const lastMonth = now - 1000 * 60 * 60 * 24 * 31; /* A month in ms */

  // Is last prompt over 1 month old?
  return lastPrompt < lastMonth;
}

export function displayReviewPrompt() {
  if (
    window.cordova &&
    window.cordova.plugins &&
    window.cordova.plugins.AppReview
  ) {
    window.localStorage.setItem(REVIEW_STORE, Date.now());

    window.cordova.plugins.AppReview.requestReview().catch(function () {
      return window.cordova.plugins.AppReview.openStoreScreen();
    });
  }
}
