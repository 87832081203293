import { turnToQuarter } from "services/turns";
import { abbreviate, abbreviateMoney, formatPercent } from "services/format";

import classnames from "classnames";

import CalendarIcon from "components/generic/tinyIcons/calendar";
import HomeIcon from "components/generic/tinyIcons/plots";
import HappyIcon from "components/generic/tinyIcons/happiness";
import CurrencyIcon from "components/generic/tinyIcons/cash";

import goalsConfig from "config/goals";
import { getCityOccupation } from "services/state";
import { animated, useSpring } from "react-spring";
import { AnimatedLabel, Label } from "./label";

const MenuButton = ({ first, last, ...otherProps }) => (
  <div
    className={classnames("flex flex-grow flex-col items-center h-full py-1", {
      "px-1": last,
      "pl-1": !last,
    })}
    {...otherProps}
  />
);

const MenuIndicator = ({ value = 0.4, children }) => {
  const props = useSpring({ height: `${value * 100}%` });
  return (
    <div className="relative w-full h-full bg-footer-400 hover:bg-opacity-75">
      <div className="absolute top-0 bottom-0 left-0 right-0 flex items-end">
        <animated.div
          className="w-full bg-footer-100"
          style={{ height: props.height }}
        ></animated.div>
      </div>
      <div className="absolute flex flex-col justify-center w-full h-full">
        {children}
      </div>
    </div>
  );
};

const Footer = ({ id, toggleStatsModal, gamestate, height }) => {
  /*
    Home icon | available space available
    People icon | percent to goal
    Bank balance (dynamic) | percent of shares
    Emoji icon (dynamic) | approval rating
    Date icon (dynamic based on date) | percent to time limit
   */
  const occupiedPlots = getCityOccupation(gamestate);
  const percentOfShares = gamestate.stocks / 10000;
  const happiness = gamestate.happiness / 100;
  const progress = gamestate.turn / goalsConfig.turns;

  const onClickHandler = (screen) => (e) => {
    e.stopPropagation();
    toggleStatsModal(screen);
  };

  return (
    <div
      className="z-20 flex flex-row items-center w-full mt-3 text-white justify-items-stretch bg-footer-900"
      style={{ height: `${height}px` }}
      id={id}
    >
      <MenuButton onClick={onClickHandler("people")}>
        <MenuIndicator value={happiness}>
          <HappyIcon
            className="w-full h-full transform scale-110"
            happiness={happiness}
          />
          <AnimatedLabel value={happiness} format={formatPercent} />
        </MenuIndicator>
      </MenuButton>

      <MenuButton onClick={onClickHandler("housing")} first>
        <MenuIndicator value={occupiedPlots}>
          <HomeIcon className="w-full h-full transform scale-110" />
          <AnimatedLabel
            value={gamestate.population}
            format={(p) => `POP.${abbreviate(p, 0)}`}
          ></AnimatedLabel>
        </MenuIndicator>
      </MenuButton>

      <MenuButton onClick={onClickHandler("finances")}>
        <MenuIndicator value={percentOfShares}>
          <CurrencyIcon className="w-full h-full transform scale-110" />
          <AnimatedLabel
            value={gamestate.bankBalance}
            format={abbreviateMoney}
          />
        </MenuIndicator>
      </MenuButton>

      <MenuButton onClick={onClickHandler("time")} last>
        <MenuIndicator value={progress}>
          <CalendarIcon className="w-full h-full transform scale-110" />
          <Label>{turnToQuarter(gamestate.turn)}</Label>
        </MenuIndicator>
      </MenuButton>
    </div>
  );
};

export default Footer;
