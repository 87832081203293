import { useRef, forwardRef, useEffect } from "react";
import { Repeat, FavoriteBorder } from "@material-ui/icons";
import { animated } from "react-spring";
import { getAvatar } from "config/avatars";
import { abbreviate } from "services/format";

const Avatar = ({ avatar }) => {
  return (
    <div className="relative flex items-center justify-center w-12 h-12 rounded-full">
      <img
        className="rounded-full transform scale-125"
        src={avatar.path}
        alt={avatar.description}
      />
    </div>
  );
};

const TweetStats = ({ nbLikes, isLiked, fontSize }) => {
  const props = {
    likes: nbLikes + (isLiked ? 1 : 0),
    retweet: Math.floor(nbLikes * 0.7),
  };

  return (
    <div className="flex flex-row text-xs">
      <div>
        <Repeat style={{ fontSize }} />{" "}
        <animated.span>{abbreviate(props.retweet, 0)}</animated.span>
      </div>
      <div className="ml-4">
        <FavoriteBorder style={{ fontSize }} />{" "}
        <animated.span>{abbreviate(props.likes, 0)}</animated.span>
      </div>
    </div>
  );
};

const TweetCard = forwardRef(
  ({ tweet, isLiked = false, style, population = 0, startTime = 0 }, ref) => {
    const avatar = getAvatar(tweet.author, startTime);

    const nbLikes = useRef(1);

    useEffect(() => {
      nbLikes.current =
        Math.floor((Math.random() * 0.3 + 0.65) * population) + 1;
    }, [tweet.id, population]);

    return (
      <animated.div style={style}>
        <div
          className="flex items-center justify-center w-full px-1 pb-1 border border-orange-100 bg-citerate-sand-yellow xs:px-2 xs:py-1 shadow-xs"
          ref={ref}
        >
          {tweet.type === "answer" && <div className="w-8"></div>}
          <div className="flex h-full mt-1 mb-auto">
            <Avatar avatar={avatar} />
          </div>
          <div className="flex flex-col items-start flex-grow h-full ml-2">
            <div className="flex flex-row">
              {avatar.name && (
                <div className="text-xs font-normal mr-1">{avatar.name}</div>
              )}
              <div className="text-xs font-light xs:font-semibold">
                {tweet.author}
              </div>
            </div>
            <div className="text-xs leading-tight xs:text-sm leading-4">
              {tweet.message}
            </div>
            <div className="hidden xs:block">
              <TweetStats
                fontSize={14}
                nbLikes={nbLikes.current}
                isLiked={isLiked}
              />
            </div>
            <div className="block xs:hidden">
              <TweetStats
                fontSize={12}
                nbLikes={nbLikes.current}
                isLiked={isLiked}
              />
            </div>
          </div>
        </div>
      </animated.div>
    );
  }
);

export default TweetCard;
