import {
  abbreviate,
  abbreviateMoney,
  formatInteger,
  formatPercent,
} from "services/format";

import CalendarIcon from "components/generic/tinyIcons/calendar";
import HomeIcon from "components/generic/tinyIcons/plots";
import HappyIcon from "components/generic/tinyIcons/happiness";
import CurrencyIcon from "components/generic/tinyIcons/cash";
import StarIcon from "components/generic/tinyIcons/star";

const Grid = (props) => (
  <div className="flex-grow my-4 grid grid-cols-3 gap-x-2 gap-y-0" {...props} />
);
const Separator = (props) => <hr className="col-span-3" {...props} />;
const Icon = (props) => <div className="w-10 h-10 mx-auto" {...props} />;
const Value = (props) => (
  <div
    className="my-auto text-lg font-bold text-right md:text-2xl"
    {...props}
  />
);
const Label = (props) => (
  <div className="my-auto text-sm sm:text-base" {...props} />
);

export const Stats = ({
  averageHappiness,
  population,
  totalIncome,
  duration,
  cityLevel,
}) => (
  <Grid>
    <Icon>
      <HappyIcon happiness="0.7" />
    </Icon>
    <Value>{formatPercent(averageHappiness * 0.01)}</Value>
    <Label>Average happiness</Label>

    <Separator />

    <Icon>
      <HomeIcon />
    </Icon>
    <Value>{abbreviate(population)}</Value>
    <Label>Population</Label>

    <Separator />

    <Icon>
      <CurrencyIcon />
    </Icon>
    <Value>{abbreviateMoney(totalIncome)}</Value>
    <Label>Total income</Label>

    <Separator />

    <Icon>
      <CalendarIcon />
    </Icon>
    <Value>{formatInteger(duration)}</Value>
    <Label>Years</Label>

    <Separator />

    <Icon>
      <StarIcon className="w-10" />
    </Icon>
    <Value>{formatInteger(cityLevel)}</Value>
    <Label>City level</Label>
  </Grid>
);
