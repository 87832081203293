import classnames from "classnames";

function HappyIsh({ className, ...props }) {
  return (
    <svg
      viewBox="0 0 150 150"
      className={classnames("h-full", className)}
      {...props}
    >
      <path
        d="M75 17.98c-31.49 0-57.02 25.53-57.02 57.02 0 31.49 25.53 57.02 57.02 57.02s57.02-25.53 57.02-57.02c0-31.49-25.53-57.02-57.02-57.02zm19.37 23.75c5.61 0 10.16 4.55 10.16 10.16s-4.55 10.16-10.16 10.16-10.16-4.55-10.16-10.16 4.55-10.16 10.16-10.16zm-38.74 0c5.61 0 10.16 4.55 10.16 10.16s-4.55 10.16-10.16 10.16-10.16-4.55-10.16-10.16c-.01-5.61 4.54-10.16 10.16-10.16zM75 105.06c-16.92 0-30.64-9.74-30.64-18.27h61.27c.01 9.09-13.71 18.27-30.63 18.27z"
        fill="#462e53"
      />
    </svg>
  );
}

function Happy({ className, ...props }) {
  return (
    <svg
      viewBox="0 0 150 150"
      className={classnames("h-full ", className)}
      {...props}
    >
      <path
        d="M75 17.98c-31.49 0-57.02 25.53-57.02 57.02 0 31.49 25.53 57.02 57.02 57.02s57.02-25.53 57.02-57.02c0-31.49-25.53-57.02-57.02-57.02zm19.37 22.73c6.17 0 11.18 5 11.18 11.18 0 6.17-5 11.18-11.18 11.18-6.17 0-11.18-5-11.18-11.18.01-6.18 5.01-11.18 11.18-11.18zm-38.74 0c6.17 0 11.18 5 11.18 11.18 0 6.17-5 11.18-11.18 11.18-6.17 0-11.18-5-11.18-11.18s5-11.18 11.18-11.18zm19.37 72c-20.66 0-37.4-16.75-37.4-37.4 0-5.68 74.81-5.14 74.81 0-.01 20.65-16.75 37.4-37.41 37.4z"
        fill="#462e53"
      />
    </svg>
  );
}

function Neutral({ className, ...props }) {
  return (
    <svg
      viewBox="0 0 150 150"
      className={classnames("h-full ", className)}
      {...props}
    >
      <path
        d="M75 17.98c-31.49 0-57.02 25.53-57.02 57.02 0 31.49 25.53 57.02 57.02 57.02s57.02-25.53 57.02-57.02c0-31.49-25.53-57.02-57.02-57.02zm19.37 23.75c5.61 0 10.16 4.55 10.16 10.16s-4.55 10.16-10.16 10.16-10.16-4.55-10.16-10.16 4.55-10.16 10.16-10.16zm-38.74 0c5.61 0 10.16 4.55 10.16 10.16s-4.55 10.16-10.16 10.16-10.16-4.55-10.16-10.16c-.01-5.61 4.54-10.16 10.16-10.16zm48.91 55.25H45.46v-10h59.07v10z"
        fill="#462e53"
      />
    </svg>
  );
}

function MadIsh({ className, ...props }) {
  return (
    <svg
      viewBox="0 0 150 150"
      className={classnames("h-full ", className)}
      {...props}
    >
      <path
        d="M75 17.98c-31.49 0-57.02 25.53-57.02 57.02 0 31.49 25.53 57.02 57.02 57.02s57.02-25.53 57.02-57.02c0-31.49-25.53-57.02-57.02-57.02zm10.22 29.53h18.32c.64 1.33 1 2.81 1 4.38 0 5.61-4.55 10.16-10.16 10.16S84.21 57.5 84.21 51.89c0-1.57.37-3.05 1.01-4.38zm-38.75 0h18.32c.64 1.33 1 2.81 1 4.38 0 5.61-4.55 10.16-10.16 10.16S45.47 57.5 45.47 51.89c-.01-1.57.36-3.05 1-4.38zm54.5 53.02c-.33-.32-8.94-8.6-26.47-8.6-17.33 0-25 8.1-25.32 8.44l-7.46-6.66c.42-.48 10.67-11.78 32.78-11.78 21.95 0 33.14 11.11 33.6 11.59l-7.13 7.01z"
        fill="#462e53"
      />
    </svg>
  );
}

function Mad({ className, ...props }) {
  return (
    <svg
      viewBox="0 0 150 150"
      className={classnames("h-full ", className)}
      {...props}
    >
      <path
        d="M75 17.98c-31.49 0-57.02 25.53-57.02 57.02 0 31.49 25.53 57.02 57.02 57.02s57.02-25.53 57.02-57.02c0-31.49-25.53-57.02-57.02-57.02zm28.53 29.53c.64 1.33 1 2.81 1 4.38 0 5.61-4.55 10.16-10.16 10.16-5.47 0-9.92-4.33-10.14-9.75l19.3-4.79zm-57.06 0l19.3 4.79c-.22 5.42-4.67 9.75-10.14 9.75-5.61 0-10.16-4.55-10.16-10.16-.01-1.57.36-3.05 1-4.38zm53.58 56.77c-.08-.16-8.89-17.4-25.55-17.4-16.52 0-24.15 16.52-24.47 17.23l-9.14-4.06c.42-.95 10.51-23.17 33.6-23.17 22.95 0 34.06 22.04 34.53 22.98l-8.97 4.42z"
        fill="#462e53"
      />
    </svg>
  );
}

const HappyIcon = ({ happiness, ...otherProps }) => {
  if (happiness > 0.8) return <Happy {...otherProps} />;
  if (happiness > 0.6) return <HappyIsh {...otherProps} />;
  if (happiness > 0.4) return <Neutral {...otherProps} />;
  if (happiness > 0.2) return <MadIsh {...otherProps} />;
  return <Mad {...otherProps} />;
};

export default HappyIcon;
