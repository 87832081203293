import { memo } from "react";

import classnames from "classnames";
import { useTransition, animated } from "react-spring";

export const Modal = ({
  show,
  children,
  className,
  paddedContent = true,
  fullHeight = false,
}) => {
  const transitions = useTransition(show, null, {
    from: { opacity: 0, position: -1 },
    enter: { opacity: 1, position: 0 },
    leave: { opacity: 0, position: -1 },
  });

  return transitions.map(
    ({ item, key, props }) =>
      item && (
        <animated.div
          className="absolute top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center w-full h-full max-h-screen"
          key={key}
        >
          <animated.div
            style={{
              opacity: props.opacity,
              transform: props.position.interpolate(
                (p) => `translateY(${p * 100}%)`
              ),
            }}
            className={classnames(
              " z-40 max-h-full w-full p-6",
              {
                "h-full": fullHeight,
              },
              className
            )}
          >
            <div
              className={classnames(
                "bg-citerate-sand-yellow shadow-lg flex flex-col h-full overflow-hidden",
                {
                  "py-4 px-6": paddedContent,
                  "p-0": !paddedContent,
                }
              )}
            >
              {children}
            </div>
          </animated.div>
          <animated.div
            style={{
              opacity: props.opacity,
            }}
            className="absolute top-0 left-0 z-20 w-full h-full bg-white bg-opacity-25"
          />
        </animated.div>
      )
  );
};

export default memo(
  Modal,
  (prevProps, nextProps) => prevProps.show === nextProps.show
);
