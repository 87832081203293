import { useState } from "react";

import InfraCard from "./infra";
import GameOverCard from "./gameover";
import PlotCard from "./plot";
import IntroCard from "./intro";
import DebugCard from "./debug";
import EventCard from "./event";

import LoveWall from "components/effects/lovewall2";
import Sadness from "components/effects/sadness";
import { GameoverEffect } from "components/effects/gameover";

const cardTypeMapping = new Map([
  ["infra", InfraCard],
  ["gameover", GameOverCard],
  ["plot", PlotCard],
  ["intro", IntroCard],
  ["event", EventCard],
]);

const CardContainer = ({
  id,
  tweet,
  showCard,
  height,
  setChosenAction,
  gamestate,
}) => {
  const [currentAnimation, setCurrentAnimation] = useState("");

  const left = () => setChosenAction("left");
  const right = () => {
    if (
      tweet.type === "infra" ||
      (tweet.type === "intro" && gamestate.tick === 1) ||
      tweet.type === "plot"
    ) {
      const isInfraSelectedCorrect = tweet.category === tweet.messageCategory;
      if (isInfraSelectedCorrect) setCurrentAnimation("love");

      const expansion = tweet.type === "plot";
      if (expansion) setCurrentAnimation("sadness");

      setTimeout(() => {
        setChosenAction("right");
        setCurrentAnimation(null);
      }, 1300);
    } else {
      setChosenAction("right");
    }
  };

  const CardType = cardTypeMapping.has(tweet.type)
    ? cardTypeMapping.get(tweet.type)
    : DebugCard;

  return (
    <>
      <div className="relative top-0 left-0 flex-grow w-full mt-4" id={id}>
        <div className="absolute bottom-0 left-0 w-full h-full">
          <div className="h-full mx-auto">
            {showCard && (
              <CardType
                left={left}
                right={right}
                gamestate={gamestate}
                key={gamestate.tick}
                height={height}
              />
            )}
          </div>
        </div>
      </div>

      <LoveWall show={currentAnimation === "love"} />
      <Sadness show={currentAnimation === "sadness"} />
      <GameoverEffect show={tweet.type === "gameover"} />
    </>
  );
};

export default CardContainer;
