import GenericCard from "./generic-card";
import CardContent from "./generic-card/content";

const DebugCard = ({ left, right, gamestate, height }) => {
  const tweet = gamestate.tweet;

  return (
    <GenericCard
      left={left}
      right={right}
      gamestate={gamestate}
      actionHints={tweet.actionHints}
      height={height}
    >
      <CardContent
        title="Debug"
        color="#eee"
        accent="#ddd"
        illustrationPath={tweet.illustrationPath}
      >
        <pre>{JSON.stringify(tweet, undefined, 2)}</pre>
      </CardContent>
    </GenericCard>
  );
};

export default DebugCard;
