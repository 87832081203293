import classnames from "classnames";

function SvgComponent({ className, ...props }) {
  return (
    <svg
      viewBox="0 0 150 150"
      className={classnames("h-full", className)}
      {...props}
    >
      <path
        d="M110.82 62.32V27.16H92.69v15.56L76.07 24.76c-.58-.62-1.56-.62-2.13 0L34.38 67.28c-.48.52-.75 1.2-.75 1.91v56.52h82.75V69.22c0-.73-.28-1.42-.78-1.95l-4.78-4.95zM65.05 47.29h19.9V64.1h-19.9V47.29zm34.05 61.62H50.9V81.32h48.2v27.59z"
        fill="#462e53"
      />
    </svg>
  );
}

export default SvgComponent;
