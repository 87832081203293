import Modal from "components/generic/modal";

import goalsConfig from "config/goals";

import { PrimaryButton, SecondaryButton } from "components/generic/buttons";
import ModalHeader from "components/generic/modal-header";
import { formatInteger } from "services/format";

const Intro = ({ show, onConfirm }) => {
  return (
    <Modal show={show} className="text-citerate-deep-purple">
      <ModalHeader>Your journey begins</ModalHeader>
      <p className="mt-4 text-sm font-semibold tracking-wide">
        Your goal is to get{" "}
        <strong className="font-bold tracking-tight">
          {formatInteger(goalsConfig.population)}
        </strong>{" "}
        people within{" "}
        <strong className="font-bold tracking-tight">
          {goalsConfig.turns / 4}
        </strong>{" "}
        years. Swipe left to reject and right to accept proposals by your citizens.
      </p>
      <p className="text-sm mt-4">
        You are about to start building your new city. Unlike other city
        entrepreneurs, you believe that you will be able to apply iterative
        improvements to this city and thrive.
      </p>

      <p className="mt-2 text-sm">
        You are looking for the place of this new city.
      </p>

      <PrimaryButton onClick={onConfirm} className="mt-8">
        Let's build!
      </PrimaryButton>
      <SecondaryButton to="/title" className="mt-2">
        Go back
      </SecondaryButton>
    </Modal>
  );
};

export default Intro;
