import classnames from "classnames";

const Title = ({ className, children, ...otherProps }) => (
  <div
    className={classnames("text-2xl border-b mt-2", className)}
    {...otherProps}
  >
    {children}
  </div>
);

export default Title;
