import { forwardRef, useLayoutEffect, useRef } from "react";
import classnames from "classnames";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { getCurrentEvent } from "services/state";
import { getEventDefinition } from "config/events";

const currentFilter = (gamestate) => {
  if (!gamestate) return "";

  const event = getCurrentEvent(gamestate);
  if (!event) return "";

  const eventDefinition = getEventDefinition(event);
  return eventDefinition.cssFilter || "";
};

const Layout = (
  {
    children,
    className = "bg-citerate-sky-blue",
    gamestate = null,
    ...otherProps
  },
  ref
) => {
  const layoutRootRef = useRef(undefined);
  useLayoutEffect(() => {
    const layoutRef = layoutRootRef.current;
    disableBodyScroll(layoutRef);
    return () => {
      enableBodyScroll(layoutRef);
    };
  });

  return (
    <div
      className="w-full h-full min-h-full pattern flex justify-center"
      style={{
        filter: currentFilter(gamestate),
      }}
      ref={layoutRootRef}
    >
      <div
        className={classnames(
          "w-full sm:max-w-sm mx-auto sm:max-h-sm md:shadow-lg h-full flex flex-col items-center justify-start relative m-auto overflow-hidden md:overflow-visible",
          className
        )}
        id="layout-root"
        ref={ref}
        {...otherProps}
      >
        {children}
      </div>
    </div>
  );
};

export default forwardRef(Layout);
