import { FoldableLine } from "components/generic/foldable-line";
import { getServiceById } from "config/services";
import { useConfig } from "hooks/config";
import { ServiceCard } from "./service-card";

const NoServiceYet = () => (
  <div className="flex items-center justify-center w-full h-full">
    <p>No services for this category.</p>
  </div>
);

const ServiceCards = ({ category, services }) => {
  const servicesOfCategory = services.filter((s) => s.category === category.id);
  const cards =
    servicesOfCategory.length > 0 ? (
      servicesOfCategory.map((s) => (
        <ServiceCard key={s.label + "_card"} service={s} category={category} />
      ))
    ) : (
      <NoServiceYet />
    );

  return <>{cards}</>;
};

const Service = ({ category, gamestate }) => {
  const { config } = useConfig();
  const level = config.displayManyLevels ? 10 : gamestate.services[category.id];

  const services = gamestate.availableServices.map(getServiceById);

  return (
    <FoldableLine
      label={category.label}
      level={level}
      backgroundColor={category.accent}
    >
      <ServiceCards category={category} services={services} />
    </FoldableLine>
  );
};

export const Services = ({ categories, gamestate }) => {
  return categories.map((c) => (
    <Service category={c} key={c.id} gamestate={gamestate} />
  ));
};
