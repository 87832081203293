import classnames from "classnames";

const publicURL = process.env.PUBLIC_URL;

export const Trophy = ({ level, className = "w-64", ...otherProps }) => (
  <img
    src={`${publicURL}/levels/Lvl${Math.min(level, 8)}.png`}
    alt="Level reward"
    className={classnames(className)}
    {...otherProps}
  />
);
