import services from "config/data/infra-card-definitions.json";
import illustrations from "config/data/infra-card-illustrations.json";
import categories from "config/data/infra-categories.json";

const publicURL = process.env.PUBLIC_URL;

// Quick fix to add the categories in each service
Object.keys(services).forEach((category) => {
  const servicesOfCategory = services[category];
  servicesOfCategory.forEach((s, i) => {
    s.category = category;
    s.level = i;
  });
});
const allServices = Object.values(services).flat();

const getCategoryLabel = (category) => {
  const categoryData = getCategoryById(category);
  return categoryData && categoryData.label;
};

const getCategoryById = (categoryId) =>
  categories.find((c) => c.id === categoryId);

const getService = (serviceType, level) => {
  let service = services[serviceType][level - 1];

  if (!service) {
    service = {
      id: `${serviceType}-${level}`,
      label: `${getCategoryById(serviceType).label} lvl. ${level}`,
      price: Math.pow(4, Math.sqrt(level) * 4) * 5,
      upkeep: 3 * Math.sqrt(level),
    };
  }

  let illustration = illustrations.find(
    (i) => i.type === serviceType && i.building === service.id
  );

  if (!illustration) {
    illustration = illustrations.find((i) => i.type === serviceType);
  }

  return {
    ...service,
    illustrationPath: `${publicURL}/cards/${illustration.file}`,
  };
};

const getServiceById = (serviceId) => {
  const service = allServices.find((s) => s.id === serviceId);

  let illustration = illustrations.find((i) => i.building === service.id);

  return {
    ...service,
    illustrationPath: `${publicURL}/cards/${illustration.file}`,
  };
};

const getAllCategories = () => {
  return [...categories].sort((a, b) => a.label.localeCompare(b.label));
};

/**
 * This function returns a service picked from the currently available level
 * @param Object gamestate the current gamestate
 * @returns {{category: Object, service: Object}} an object with the category and the service objects
 */
const pickNextService = (gamestate) => {
  const serviceLevelDistribution = new Map();
  Object.keys(gamestate.services).forEach((serviceId) => {
    const level = gamestate.services[serviceId];
    const servicesOfLevel = serviceLevelDistribution.has(level)
      ? serviceLevelDistribution.get(level)
      : [];
    serviceLevelDistribution.set(level, [...servicesOfLevel, serviceId]);
  });
  const levels = serviceLevelDistribution.keys();
  const min = Math.min(...levels);

  const minLevels = serviceLevelDistribution.get(min);

  const categoryId = minLevels[Math.floor(Math.random() * minLevels.length)];

  return {
    category: getCategoryById(categoryId),
    service: getService(categoryId, min + 1),
  };
};

export {
  getAllCategories,
  getCategoryById,
  getCategoryLabel,
  getService,
  getServiceById,
  pickNextService,
};
