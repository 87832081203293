import classnames from "classnames";
import BaseButton from "./base";

const PrimaryButton = ({
  className,
  children,
  isTitleScreen = false,
  ...otherProps
}) => (
  <BaseButton
    className={classnames(
      "rounded focus:outline-none focus:shadow-outline mx-auto text-center px-4 py-2 w-full font-citerate font-semibold text-xl",
      {
        "bg-white hover:bg-gray-100 text-citerate-deep-green": isTitleScreen,
        "bg-citerate-grass-green hover:bg-green-200 text-citerate-deep-purple": !isTitleScreen,
      },
      className
    )}
    {...otherProps}
  >
    {children}
  </BaseButton>
);

export default PrimaryButton;
