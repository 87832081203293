import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

export const Social = ({ message, sharedURL }) => (
  <div className="flex flex-col w-full text-white">
    <p className="m-auto font-light uppercase">Share your results</p>
    <div className="flex flex-row h-8 mx-auto to-citerate-deep-purple">
      <TwitterShareButton
        url={sharedURL}
        title={`${message}

@citerateGame #citerate #buildingcities #citysim`}
      >
        <TwitterIcon />
      </TwitterShareButton>
      <FacebookShareButton
        url={sharedURL}
        quote={`${message} citerategame`}
        hashtag="#citerate"
        className="mx-4"
      >
        <FacebookIcon />
      </FacebookShareButton>
      <LinkedinShareButton
        url={sharedURL}
        title="A new city is born!"
        summary={message}
        source="Citerate"
      >
        <LinkedInIcon />
      </LinkedinShareButton>
    </div>
  </div>
);
