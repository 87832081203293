import Button from "components/generic/button";
import Title from "./Title";
import Line from "./Line";

import goals from "config/goals";

import { turnToQuarterFull } from "services/turns";

const formatTimeLeft = (turn) => {
  const turnLeft = goals.turns - turn;
  const yearsLeft = Math.ceil(turnLeft / 4);
  // const quartersLeft = turnLeft % 4;
  return `${yearsLeft} years`;
};

const MenuView = ({ gamestate, quitGame }) => (
  <div className="flex flex-col h-full">
    <Title>Time</Title>
    <Line label="Current quarter">{turnToQuarterFull(gamestate.turn)}</Line>
    <Line label="Time left">{formatTimeLeft(gamestate.turn)}</Line>

    <Button danger onClick={quitGame} className="my-6">
      Give up
    </Button>
  </div>
);

export default MenuView;
