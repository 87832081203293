import { getAllCategories } from "config/services";
import { useConfig } from "hooks/config";
import { formatInteger, formatPercent } from "services/format";

import Line from "../Line";
import Title from "../Title";
import { ServiceLevels } from "./service-levels";
import { Services } from "./services";

const PeopleView = ({ gamestate }) => {
  const {
    config: { useLevelTree },
  } = useConfig();

  return (
    <>
      <Title>People</Title>
      <Line label="CEO approval">
        {formatPercent(gamestate.happiness / 100)}
      </Line>
      <Line label="Population">{formatInteger(gamestate.population)}</Line>
      <p>Services: </p>
      {useLevelTree ? (
        <ServiceLevels gamestate={gamestate} />
      ) : (
        <Services categories={getAllCategories()} gamestate={gamestate} />
      )}
      <div className="p-1" />
    </>
  );
};

export default PeopleView;
