import GenericCard from "./generic-card";
import CardContent from "./generic-card/content";

import {
  abbreviateMoney,
  formatPercent,
  abbreviate,
  formatMoney,
} from "services/format";
import { computeRentPerPlot } from "hooks/gamestate/formulas";
import { HelpCardContent } from "./generic-card/help-content";

const Card = ({ left, right, gamestate, height }) => {
  const tweet = gamestate.tweet;
  const actionHints = {
    left: "Keep it",
    right: "Expand",
  };

  const helpContent = (
    <HelpCardContent title="City expansion" accent="#CDE1BC" color="#ECF4E5">
      <p>This card expands the city with new available space.</p>
      <p>
        If the city does not have available space, the city will stop growing
        and it will not be attractive anymore.
      </p>
      <p>
        Expanding means the price of the plots will decrease which will make
        citizens unhappy.
      </p>
    </HelpCardContent>
  );

  return (
    <GenericCard
      left={left}
      right={right}
      gamestate={gamestate}
      actionHints={actionHints}
      height={height}
      helpContent={helpContent}
    >
      <CardContent
        title={`Create ${abbreviate(tweet.impact.newPlots, 0)} plots?`}
        illustrationPath={tweet.illustrationPath}
        accent="#CDE1BC"
        color="#ECF4E5"
      >
        <div className="block w-full">Terms per plot</div>
        Selling price:{" "}
        <span className="font-normal normal-case">
          {tweet.impact.contract.price === "market"
            ? formatMoney(tweet.impact.contract.currentMarketPrice)
            : abbreviateMoney(tweet.impact.contract.price, 0)}{" "}
        </span>
        {tweet.impact.contract.ownership > 0 && (
          <>
            <br />
            City ownership:{" "}
            <span className="font-normal normal-case">
              {formatPercent(tweet.impact.contract.ownership)} given to each
              household
            </span>
          </>
        )}
        <br />
        Rent price:{" "}
        <span className="font-normal normal-case">
          {abbreviateMoney(computeRentPerPlot())} per quarter
        </span>
      </CardContent>
    </GenericCard>
  );
};

export default Card;
