function Arrow(props) {
  return (
    <svg viewBox="0 0 100 100" {...props}>
      <style />
      <path
        d="M30.66 9.28h4c1.91 0 3.75.76 5.1 2.11l33.5 33.5a7.206 7.206 0 010 10.2l-33.5 33.5a7.222 7.222 0 01-5.1 2.11h-4c-3.98 0-7.21-3.23-7.21-7.21V16.5c0-3.99 3.23-7.22 7.21-7.22z"
        fill="#462e53"
        id="prefix__Layer_2"
      />
    </svg>
  );
}

export default Arrow;
