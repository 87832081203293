import { useState, useEffect } from "react";

import Modal from "components/generic/modal";

import Layout from "components/layout";

import { readHighScores } from "services/highscores";

import { abbreviate, abbreviateMoney } from "services/format";

import { PrimaryButton, SecondaryButton } from "components/generic/buttons";

const formatTurns = (turn) => `${Math.ceil(turn / 4)} years`;

const ScoreLine = ({ score }) => (
  <tr className="">
    <td className="">{formatTurns(score.turn)}</td>
    <td className="">{abbreviate(score.population, 3)}</td>
    <td className="">{abbreviateMoney(score.bankBalance)}</td>
  </tr>
);

const Scores = ({ scores }) => {
  const sortedScores = [...scores]
    .sort((a, b) => b.population - a.population)
    .slice(0, 10);
  return (
    <table className="table-auto w-full">
      <thead>
        <tr>
          <th class="py-2 text-left">Duration</th>
          <th class="py-2 text-left">Population</th>
          <th class="py-2 text-left">Money</th>
        </tr>
      </thead>
      <tbody>
        {sortedScores.map((s, i) => (
          <ScoreLine score={s} key={i} />
        ))}
      </tbody>
    </table>
  );
};

const HighscoreScreen = () => {
  const [display, setDisplay] = useState(false);
  useEffect(() => {
    setTimeout(() => setDisplay(true), 0);
  });

  const highscores = readHighScores();

  return (
    <Layout>
      <Modal show={display}>
        <div className="text-sm">
          <p className="mt-4 mb-4 text-2xl font-black text-center uppercase">
            High Scores
          </p>
          <p className="-mt-4 text-xs text-gray-600 text-center">
            Highest population first
          </p>
          {!highscores || highscores.length === 0 ? (
            <p>No high scores...</p>
          ) : (
            <Scores scores={highscores} />
          )}
        </div>
        <div className="flex flex-col">
          <PrimaryButton to="../game" className="mt-8" primary>
            Start a new city
          </PrimaryButton>
          <SecondaryButton to="../title" className="mt-2">
            Go to title screen
          </SecondaryButton>
        </div>
      </Modal>
    </Layout>
  );
};

export default HighscoreScreen;
