import classnames from "classnames";

export const Badge = ({ className, ...props }) => (
  <div
    {...props}
    className={classnames(
      "bg-citerate-grass-green uppercase flex items-center font-semibold h-20 w-20 md:h-24 md:w-24 xl:h-32 xl:w-32 rounded-full flex items-center justify-center text-center border-dashed border-citerate-green border-2 text-xs md:text-base",
      className
    )}
  />
);
