import { useEffect, useState } from "react";
import {
  createHistory,
  createMemorySource,
  LocationProvider,
  Router,
} from "@reach/router";

import HomeScreen from "content/home";
import PrivacyPolicy from "content/privacy";

import Game from "screens/Game";
import TitleScreen from "screens/title";
import CreditsScreen from "screens/credits";
import AboutScreen from "screens/about";
import HighscoreScreen from "screens/highscores";

import * as serviceWorker from "serviceWorkerRegistration";

const reloadSW = (serviceWorkerRegistration) => () => {
  const registrationWaiting = serviceWorkerRegistration.waiting;
  if (registrationWaiting) {
    registrationWaiting.postMessage({ type: "SKIP_WAITING" });
    registrationWaiting.addEventListener("statechange", (e) => {
      if (e.target.state === "activated") {
        console.log("Reloading the whole page");
        window.location.reload();
      }
    });
  }
};

const App = ({ isAppContext = false }) => {
  const [workerState, setWorkerState] = useState({
    serviceWorkerInitialized: false,
    serviceWorkerUpdated: false,
    reload: null,
  });

  useEffect(() => {
    serviceWorker.register({
      onSuccess: (reg) => {
        const reload = reloadSW(reg);
        setWorkerState({
          serviceWorkerInitialized: true,
          serviceWorkerUpdated: false,
          reload,
        });
      },
      onUpdate: (reg) => {
        const reload = reloadSW(reg);
        setWorkerState({
          serviceWorkerUpdated: true,
          serviceWorkerInitialized: true,
          reload,
        });
      },
    });
  }, []);

  return isAppContext ? (
    <AppInAppContext workerState={workerState} />
  ) : (
    <AppInWebContext workerState={workerState} />
  );
};

const AppInAppContext = ({ workerState }) => {
  const source = createMemorySource("/");
  const history = createHistory(source);

  return (
    <div className="h-full">
      <LocationProvider history={history}>
        <Router className="h-full">
          <TitleScreen
            path="/"
            default
            workerState={workerState}
            isAppContext={true}
          />
          <Game path="/game" workerState={workerState} isAppContext={true} />
          <CreditsScreen path="/credits" />
          <AboutScreen path="/about" />
          <HighscoreScreen path="/score" />
        </Router>
      </LocationProvider>
    </div>
  );
};

const AppInWebContext = ({ workerState }) => {
  return (
    <div className="h-full">
      <Router className="h-full">
        <HomeScreen path="/" />
        <PrivacyPolicy path="/privacy-policy" />
        <TitleScreen
          path="/title"
          workerState={workerState}
          isAppContext={false}
        />
        <Game path="/game" workerState={workerState} isAppContext={false} />
        <CreditsScreen path="/credits" />
        <AboutScreen path="/about" />
        <HighscoreScreen path="/score" />
      </Router>
    </div>
  );
};

export default App;
