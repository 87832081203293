export const turnToQuarter = (turn) => {
  const quarter = (turn % 4) + 1;
  const year = 2020 + Math.floor(turn / 4);
  return `${year}Q${quarter}`;
};

export const turnToQuarterFull = (turn) => {
  const quarter = ((turn + 4) % 4) + 1;
  const year = 2020 + Math.floor(turn / 4);
  return `${year} - Q${quarter}`;
};
