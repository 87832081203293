import { useRef, useState } from "react";
import { PrimaryButton } from "components/generic/buttons";
import CancelIcon from "@material-ui/icons/Cancel";
import { useSpring, animated, config } from "react-spring";

export const Popup = ({ children, accept, show, ctaLabel = "OK" }) => {
  const firstLoad = useRef(true);
  const [isPopupDiscarded, setPopupDiscarded] = useState(false);
  const popupProps = useSpring({
    from: {
      position: 0,
      opacity: 0,
    },
    to: { position: !show || isPopupDiscarded ? 0 : 1, opacity: show ? 1 : 0 },
    immediate: firstLoad.current,
    config: config.wobbly,
  });

  firstLoad.current = false;

  return (
    <animated.div
      className="absolute bottom-0 left-0 w-full z-100"
      style={{
        opacity: popupProps.opacity,
        transform: popupProps.position.interpolate(
          (p) => `translateY(${(0.9 - p) * 120}%)`
        ),
      }}
    >
      <div className="max-w-xs mx-auto bg-citerate-sand-yellow p-4 ">
        <div className="flex flex-row mb-4 ">
          {children}
          <button>
            <CancelIcon
              onClick={() => {
                setPopupDiscarded(true);
              }}
            />
          </button>
        </div>
        <PrimaryButton onClick={accept}>{ctaLabel}</PrimaryButton>
      </div>
    </animated.div>
  );
};
