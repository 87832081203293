import CardContainer from "components/cards";

import Footer from "components/footer";
import NewTurn from "components/newTurn";
import Timeline from "components/timeline";
import LevelReached from "components/newLevel";
import { getCityServiceLevel } from "services/state";
import { TourTutorial } from "./tour";

export const tourSteps = [
  {
    target: "#layout-root",
    title: "Welcome!",
    content: "This your new office! Let me walk you through.",
    disableBeacon: true,
    placement: "center",
    style: { width: "100vw", transform: "" },
  },
  {
    target: "#social-timeline",
    title: "Social network",
    placement: "bottom",
    content:
      "This is the 21st century! You get requests from citizens and news from collaborators directly in your social feed. Listen wisely to make good decisions.",
  },
  {
    target: "#footer-bar",
    title: "Gather information",
    placement: "top",
    content:
      "This bar contains all the Key Performance Indicators of your city. If you click on the icons, you'll get more detailed information.",
  },
  {
    target: "#card-content",
    title: "Make a decision",
    placement: "top",
    content:
      "Opportunities will appear here. Swipe left to refuse. Swipe right to accept.",
  },
];

const MainGameScreen = ({
  gameUIState,
  gamestate,
  history,
  hideNewLevel,
  hideNewTurn,
  setChosenAction,
  toggleStatsModal,
  tweet,
  nextTweet,
  height,
}) => {
  return (
    <>
      <TourTutorial
        steps={tourSteps}
        displayNewTurn={gameUIState.displayNewTurn}
      />

      <LevelReached
        show={gameUIState.displayNewLevel}
        cityName={gamestate.cityName}
        level={getCityServiceLevel(gamestate)}
        onValidated={hideNewLevel}
      />

      <NewTurn
        show={gameUIState.displayNewTurn}
        gamestate={gamestate}
        history={history}
        onMessageDisplayed={hideNewTurn}
      />

      <Timeline
        id="social-timeline"
        tweets={gameUIState.tweets}
        onRest={nextTweet}
        isFirstCard={!gameUIState.showCard || gameUIState.tweets.length > 1}
        population={gamestate.population}
      />

      <CardContainer
        id="decision-cards"
        tweet={tweet}
        setChosenAction={setChosenAction}
        gamestate={gamestate}
        showCard={gameUIState.showCard}
        height={Math.ceil(height * 0.43)}
      />

      <Footer
        id="footer-bar"
        toggleStatsModal={toggleStatsModal}
        gamestate={gamestate}
        height={Math.ceil(height * 0.07)}
      />
    </>
  );
};

export { MainGameScreen };
