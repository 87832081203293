import classnames from "classnames";
import { PrimaryButton } from "components/generic/buttons";
import HomeIcon from "components/generic/icons/home";
import CalendarIcon from "components/generic/tinyIcons/calendar";
import HappyIcon from "components/generic/tinyIcons/happiness";
import CashIcon from "components/generic/tinyIcons/cash";
import { Modal } from "components/generic/modal";
import { useLayoutEffect, useRef } from "react";
import PeopleView from "./people";
import TimeView from "./time";
import FinanceView from "./finances";
import HousingView from "./housing";
import { turnToQuarter } from "services/turns";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

const Tab = ({
  className,
  children,
  setTab = () => {},
  value,
  selected,
  ...otherProps
}) => (
  <button
    className={classnames(
      "px-2 py-1 focus:outline-none focus:bg-orange-200 flex-1 border-b-2 h-full",
      {
        "border-citerate-deep-purple bg-citerate-subhover ": value === selected,
        "border-transparent": value !== selected,
      },
      className
    )}
    onClick={() => setTab(value)}
    {...otherProps}
  >
    {children}
  </button>
);

const StatsScreen = ({
  show,
  toggleStatsModal,
  gamestate,
  history,
  gameUIState,
  quitGame,
}) => {
  const happiness = gamestate.happiness / 100;
  const tab = gameUIState.statsScreen;
  const contentRef = useRef();
  useLayoutEffect(() => {
    if (contentRef.current) {
      const content = contentRef.current;
      disableBodyScroll(content);
      return () => {
        enableBodyScroll(content);
      };
    }
  });

  return (
    <Modal show={show} fullHeight paddedContent={false}>
      <div className="w-full py-1 text-sm uppercase">
        <p className="w-full text-center">{gamestate.cityName}</p>
      </div>
      <div className="flex flex-row flex-none w-full h-10 px-4 border-b border-gray-200">
        <Tab setTab={toggleStatsModal} value="people" selected={tab}>
          <HappyIcon className="w-full h-full" happiness={happiness} />
        </Tab>
        <Tab setTab={toggleStatsModal} value="housing" selected={tab}>
          <HomeIcon className="w-full h-full" />
        </Tab>
        <Tab setTab={toggleStatsModal} value="finances" selected={tab}>
          <CashIcon className="w-full h-full" />
        </Tab>
        <Tab setTab={toggleStatsModal} value="time" selected={tab}>
          <CalendarIcon
            className="w-full h-full"
            date={turnToQuarter(gamestate.turn)}
          />
        </Tab>
      </div>

      <div
        className="flex-grow min-h-0 px-4 overflow-y-auto"
        style={{ WebkitOverflowScrolling: "touch" }}
        ref={contentRef}
      >
        {tab === "people" && <PeopleView gamestate={gamestate} />}
        {tab === "housing" && <HousingView gamestate={gamestate} />}
        {tab === "finances" && (
          <FinanceView gamestate={gamestate} history={history} />
        )}
        {tab === "time" && (
          <TimeView gamestate={gamestate} quitGame={quitGame} />
        )}
      </div>

      <div className="flex-none px-4 pb-4">
        <PrimaryButton onClick={() => toggleStatsModal()}>Close</PrimaryButton>
      </div>
    </Modal>
  );
};

export default StatsScreen;
