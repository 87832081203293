import { makeTweet } from "./factory";

const publicURL = process.env.PUBLIC_URL;
const isGameOverTweetNext = (gamestate) => gamestate.isGameOver;

const getBankruptTweet = (gamestate) =>
  makeTweet({
    type: "gameover",
    author: "@thelocal",
    message: `BREAKING! ${gamestate.cityName} is bankrupted, thanks CEO!`,
    illustrationPath: `${publicURL}/other-cards/YoureFired.png`,
  });

const getFiredTweet = (gamestate) =>
  makeTweet({
    type: "gameover",
    author: "@thelocal",
    message: `BREAKING! ${gamestate.cityName} CEO has been fired this morning.`,
    illustrationPath: `${publicURL}/other-cards/YoureFired.png`,
  });

const getAbortTweet = (gamestate) =>
  makeTweet({
    type: "gameover",
    author: "@thelocal",
    message: `BREAKING! ${gamestate.cityName} project is aborted! You cannot make a city without land...`,
    illustrationPath: `${publicURL}/other-cards/YoureFired.png`,
  });

const getGrowthTweet = (gamestate) =>
  makeTweet({
    type: "gameover",
    author: "@thelocal",
    message: `BREAKING! The ${gamestate.cityName} has failed to attract anyone...`,
    illustrationPath: `${publicURL}/other-cards/YoureFired.png`,
  });

const getChaosTweet = (gamestate) =>
  makeTweet({
    type: "gameover",
    author: "@thelocal",
    message: `BREAKING! After months of unhappiness, the citizens of ${gamestate.cityName} are in the street. They are asking for the CEO to be fired!`,
    illustrationPath: `${publicURL}/other-cards/YoureFired.png`,
  });

const getNotOwnerTweet = (gamestate) =>
  makeTweet({
    type: "gameover",
    author: "@thelocal",
    message: `BREAKING! The CEO has been fired for completely giving away ${gamestate.cityName} to the citizens, making the board unhappy.`,
    illustrationPath: `${publicURL}/other-cards/YoureFired.png`,
  });

const getGameOverTweet = (gamestate) => {
  if (gamestate.gameOverStatus.money) return getBankruptTweet(gamestate);
  if (gamestate.gameOverStatus.time) return getFiredTweet(gamestate);
  if (gamestate.gameOverStatus.happiness) return getChaosTweet(gamestate);
  if (gamestate.gameOverStatus.noPlot) return getAbortTweet(gamestate);
  if (gamestate.gameOverStatus.noGrowth) return getGrowthTweet(gamestate);
  if (gamestate.gameOverStatus.notOwner) return getNotOwnerTweet(gamestate);
};

export { getGameOverTweet, isGameOverTweetNext };
